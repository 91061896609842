import { createContext } from 'react';

export default createContext({
  fields: {},
  handleSelect: () => undefined,
  handleInput: () => undefined,
  handleCheckbox: () => undefined,
  handleDatepicker: () => undefined,
  handleFulltime: () => undefined,
  handleActiveTab: () => undefined,
  setMapVisible: () => undefined,
});