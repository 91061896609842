import React, { useEffect, useMemo, useState } from "react";
import {
  Collapse,
  Checkbox,
  Select,
  Button,
  DatePicker,
  Radio,
  TimePicker,
} from "antd";
import moment from "moment";
import { BiCheckCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import S from "../styles/statistics";
import { getOptionsByFieldNameId, t } from "../utils";
import * as cardOptions from "../constants/cardOptions";
import actions from "../store/statisticsFilters/actions";
import dot_icon from "../assets/dot_icon.png";
import och_qiz from "../assets/ochiq_qizil.png";
import yashil from "../assets/yashil.png";
import "../containers/Statistics/main.scss";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
const hourFormat = "HH:mm";
const initalValue = {
  accident_types: [],
  health_condition: [],
  region_id: null,
  district_id: null,
  accident_hour: null,
  date_accident: [
    moment(moment().startOf("year")),
    moment(moment().endOf("date")),
  ],
  date_accident__gte: moment(moment().startOf("year")).utc(true).format(),
  date_accident__lte: moment(moment().endOf("date")).utc(true).format(),
  accident_type_id__in: [],
  date_accident__time__lte: null,
  date_accident__time__gte: null,
};

export default function Filters({ fetchData, loading, statistics = false }) {
  const { accident_types, regions, districts } = useSelector(
    (state) => state.handbooks || {}
  );
  const {
    lang = "uz",
    region,
    district,
  } = useSelector((state) => state.auth.user || {});
  const filters = useSelector((state) => state.statisticsFilter || initalValue);
  const dispatch = useDispatch();

  const handleCollapse = (key) => {
    console.log(key);
  };

  const handleSelect = (name, value) => {
    if (name === "region_id") {
      dispatch(actions.setFilters({ district_id: null, [name]: value }));
    } else {
      dispatch(actions.setFilters({ [name]: value }));
    }
  };

  const handleCheckbox = (name, value) => {
    dispatch(actions.setFilters({ [name]: value }));
  };

  const handleDate = (name, value, str) => {
    if (name === "date") {
      if (value) {
        dispatch(
          actions.setFilters({
            date_accident: [value[0], value[1]],
            date_accident__gte: value[0].startOf("day").utc(true).format(),
            date_accident__lte: value[1].endOf("day").utc(true).format(),
          })
        );
      } else {
        dispatch(
          actions.setFilters({
            date_accident: [null, null],
            date_accident__gte: null,
            date_accident__lte: null,
          })
        );
      }
    } else {
      if (value) {
        dispatch(
          actions.setFilters({
            accident_hour: [
              value[0].format(hourFormat),
              value[0].format(hourFormat),
            ],
            date_accident__time__gte: value[0].format("HH:mm:ss"),
            date_accident__time__lte: value[1].format("HH:mm:ss"),
          })
        );
      } else {
        dispatch(
          actions.setFilters({
            accident_hour: [null, null],
            date_accident__time__gte: null,
            date_accident__time__lte: null,
          })
        );
      }
    }
  };

  const options = useMemo(
    () => ({
      accident_types: accident_types.map((i) => ({
        label: i[`name_${lang}`],
        value: i.id,
      })),
      healthConditions: cardOptions.typeOfHealthConditions.map((i) => ({
        label: i[`name_${lang}`],
        value: i.id,
        // color: "red",
      })),
      regions: regions,
      districts: getOptionsByFieldNameId(
        districts,
        "region",
        filters.region_id
      ),
    }),
    [filters, accident_types, regions, districts, lang]
  );

  const handleReset = () => {
    dispatch(actions.resetFilters());
    fetchData(true);
  };

  return (
    <S.Filters className="">
      <Collapse
        onChange={handleCollapse}
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={["health_conditions"]}
        // defaultActiveKey={['accident_types', 'health_conditions', 'area', 'date_accident']}
      >
        {statistics ? (
          <Panel
            key="health_conditions"
            header={<h4>{t("Ishtirokchi holati bo'yicha")}</h4>}
          >
            <div className="my_radio_group">
              <Radio.Group
                value={filters.health_condition}
                options={options.healthConditions}
                // buttonStyle={}
                onChange={(e) =>
                  handleCheckbox("health_condition", e.target.value)
                }
              />
            </div>
          </Panel>
        ) : null}
        <Panel key="accident_types" header={<h4>{t("YTH turi bo'yicha")}</h4>}>
          <Checkbox.Group
            value={filters.accident_type_id__in}
            onChange={(val) => handleCheckbox("accident_type_id__in", val)}
            options={options.accident_types}
          />
        </Panel>

        <Panel
          key="area"
          className="px-2"
          header={<h3>{t("Hudud bo'yicha")}</h3>}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={t("Viloyat")}
            optionFilterProp="children"
            value={filters.region_id || region}
            onChange={(val) => handleSelect("region_id", val)}
            size="large"
            disabled={region}
            allowClear
            filterOption={(input, option) => {
              return (
                option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            {options.regions.map((item) => {
              const title = item?.[`name_${lang}`];
              return (
                <Option value={item.id} label={title} key={item.id}>
                  {title}
                </Option>
              );
            })}
          </Select>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={t("Tuman")}
            optionFilterProp="children"
            className="mt-3"
            value={filters.district_id || district}
            onChange={(val) => handleSelect("district_id", val)}
            size="large"
            allowClear
            disabled={district}
            filterOption={(input, option) => {
              return (
                option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            {options.districts.map((item) => {
              const title = item?.[`name_${lang}`];
              return (
                <Option value={item.id} label={title} key={item.id}>
                  {title}
                </Option>
              );
            })}
          </Select>
        </Panel>
        <Panel key="date_accident" header={<h3>{t("Sana bo'yicha")}</h3>}>
          <RangePicker
            placeholder={["Sanadan", "Sanagacha"]}
            onChange={(val, str) => handleDate("date", val, str)}
            format={dateFormatList}
          />
          <TimePicker.RangePicker
            format={"hh:mm"}
            className="mt-3"
            onChange={(val) => handleDate("hour", val)}
            placeholder={["Soatdan", "Soatgacha"]}
          />
        </Panel>
      </Collapse>
      <div className="filter-wrapper">
        <Button type="dashed" onClick={handleReset} htmlType="reset">
          {t("Tozalash")}
        </Button>
        <Button loading={loading} onClick={() => fetchData()} type="primary">
          {t("Qo'llash")}
          <BiCheckCircle className="has-icon" style={{ fontSize: 20 }} />
        </Button>
      </div>
    </S.Filters>
  );
}
