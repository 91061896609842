import * as types from '../actionTypes';

const initialState = {
  token: null,
  isLogged: false,
  user: { lang: 'uz' }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_SIGN_IN: {
      return { ...state, token: action.payload };
    }
    case types.AUTH_CHANGE_LANG: {
      return { ...state, user: { ...state.user, lang: action.payload } }
    }
    case types.UPDATE_USER: {
      return { ...state, user: { ...state.user, ...action.payload } }
    }
    case types.AUTH_SIGN_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;