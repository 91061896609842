import * as types from "../actionTypes";

const actions = {};

actions.setFilters = (payload) => ({
  type: types.SET_STATISTICS_FILTER,
  payload,
});

actions.resetFilters = () => ({ type: types.RESET_STATISTICS_FILTER });

export default actions;
