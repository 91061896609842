import React, { useMemo, useState, useEffect, useCallback, } from 'react';
import { t, getHandbookName } from '../../utils';
import { Form, Input, Button, Select, Row, Col, Spin, message, Divider, Radio, } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';
import { updateUser } from '../../store/auth/actions';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { BiGroup } from 'react-icons/bi';
import { Spinner } from '../../components';

const { Option } = Select;

export default function PersonalInfo({ handlePrivateInfo }) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const myAccount = useSelector(state => state.auth.user || {});
  const isSuperUser = myAccount.is_superuser;
  const { id, lang, first_name, last_name, email, phone, rank, region, district } = myAccount;
  const { regions, districts } = useSelector(state => state.handbooks || {});
  const dispatch = useDispatch();

  const [user, setUser] = useState({ first_name, last_name, email, phone, rank, region, district, groups: myAccount.groups });

  const handleInput = event => {
    const { value, name } = event.target;
    const defaultValue = value ? value : null;
    setUser(user => ({ ...user, [name]: defaultValue }));
  }

  const handleSelect = (name, value) => {
    const defaultValue = value ? value : null;
    if (name === 'region') {
      setUser(user => ({ ...user, [name]: defaultValue, district: null }));
    } else {
      setUser(user => ({ ...user, [name]: defaultValue }));
    }
  }

  const handleChange = useCallback((event) => {
    let { value, checked } = event.target;
    value = parseInt(value);
    if (checked) {
      setUser(state => ({ ...state, groups: [...new Set([...state.groups, value])] }));
    } else {
      const groups = user.groups.filter(i => i !== value)
      setUser(state => ({ ...state, groups }));
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        setGroupLoading(true);
        const { data } = await axios.get('/auth/groups/?page_size=100');
        setGroups(data.results);
        setGroupLoading(false);
      }
      catch (err) {
        console.error(err);
        setGroupLoading(false);
      }
    })()
  }, []);

  const handleSave = async (event) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {

        try {
          let request = {}
          for (let x in user) {
            if (user[x] && user[x] !== myAccount[x]) {
              request = { ...request, [x]: user[x] }
            } else if (!user[x] && user[x] !== myAccount[x]) {
              request = { ...request, [x]: null }
            }
          }
          if (Object.keys(request).length) {
            const { data } = await axios.patch(`/auth/users/${id}/`, request);
            dispatch(updateUser(data));
            message.success(t("Ma'lumot muvaffaqiyatli yangilandi"))
          } else {
            message.warning(t("Yangilanadigan ma'lumot yo'q."))
          }

        }
        catch (err) {
          const { data = {} } = err.response;
          let messages = [];
          for (let x in data) {
            messages.push(<li key={x}>{getHandbookName(x) ?? x}: {data[x].join(', ')}</li>)
          }
          return message.error(messages, 7);
        }
      }

    })
  }

  const filteredDistricts = useMemo(() => user.region ? districts.filter(item => item.region === user.region) : districts, [user.region]);

  return (
    <div>
      <h2>{t("Shaxsiy ma'lumotlar")}</h2>
      <Form
        name="control-ref"
        onFinish={handleSave}
        className="my-4"
        initialValues={user}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  // required: true,
                  message: "To'ldiring"
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Ism")}</label>
              <Input
                placeholder={t("Ism")}
                name="first_name"
                onChange={handleInput}
                value={user.first_name}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Familiya")}</label>
              <Input
                placeholder={t("Ism")}
                name="last_name"
                onChange={handleInput}
                value={user.last_name}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Email")}</label>
              <Input
                placeholder={t("Email")}
                name="email"
                onChange={handleInput}
                value={user.email}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Telefon")}</label>
              <Input
                placeholder={t("Telefon")}
                name="phone"
                onChange={handleInput}
                value={user.phone}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="rank"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="rank"> {t("Unvoni")}</label>
              <Input
                placeholder={t("Unvoni")}
                name="rank"
                onChange={handleInput}
                value={user.rank}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          {
            user.region ?
              (
                <Col span={12}>
                  <Form.Item
                    name="region"
                    rules={[
                      {
                        required: false,
                        message: 'something'
                      },
                    ]}
                  >
                    <label htmlFor="first_name"> {t("Viloyat")}</label>
                    <Select
                      showSearch
                      placeholder={t('Viloyat')}
                      onChange={value => handleSelect('region', value)}
                      name={'region'}
                      showAction={["focus", "click"]}
                      value={user.region}
                      allowClear
                      className="custom-select-content focusable mt-2"
                      dropdownClassName="custom-select-dropdown focusable"
                      size="large"
                      listItemHeight={42}
                      disabled={!isSuperUser}
                      filterOption={(input, option) => {
                        return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {
                        regions?.map(item => {
                          const translationBasedName = item[`name_${lang}`] || item['name'];
                          return <Option
                            value={item.id}
                            label={translationBasedName}
                            key={item.id}
                          >
                            {translationBasedName}
                          </Option>
                        })
                      }
                    </Select >
                  </Form.Item>
                </Col>
              )
              : (
                <Col span={12}>
                  <Form.Item
                    name="region"
                    rules={[
                      {
                        required: false,
                        message: 'something'
                      },
                    ]}
                  >
                    <label htmlFor="first_name"> {t("Viloyat")}</label>
                    <Select
                      showSearch
                      placeholder={t('Viloyat')}
                      onChange={value => handleSelect('region', value)}
                      name={'region'}
                      showAction={["focus", "click"]}
                      value={districts?.find(item => item.id === user.district)?.region}
                      allowClear
                      className="custom-select-content focusable mt-2"
                      dropdownClassName="custom-select-dropdown focusable"
                      size="large"
                      listItemHeight={42}
                      disabled={!isSuperUser}
                      filterOption={(input, option) => {
                        return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {
                        regions?.map(item => {
                          const translationBasedName = item[`name_${lang}`] || item['name'];
                          return <Option
                            value={item.id}
                            label={translationBasedName}
                            key={item.id}
                          >
                            {translationBasedName}
                          </Option>
                        })
                      }
                    </Select >
                  </Form.Item>
                </Col>
              )
          }
          {
            user.district ?
              <Col span={12}>
                <Form.Item
                  name="district"
                  rules={[
                    {
                      required: false,
                      message: 'something'
                    },
                  ]}
                >
                  <label htmlFor="first_name"> {t("Tuman")}</label>
                  <Select
                    showSearch
                    placeholder={t('Tuman')}
                    onChange={value => handleSelect('district', value)}
                    name={'district'}
                    showAction={["focus", "click"]}
                    value={user.district}
                    allowClear
                    className="custom-select-content focusable mt-2"
                    dropdownClassName="custom-select-dropdown focusable"
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    size="large"
                    disabled={!isSuperUser}
                    listItemHeight={42}
                    filterOption={(input, option) => {
                      return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                  >
                    {
                      filteredDistricts?.map(item => {
                        const translationBasedName = item[`name_${lang}`] || item['name'];
                        return <Option
                          value={item.id}
                          label={translationBasedName}
                          key={item.id}
                        >
                          {translationBasedName}
                        </Option>
                      })
                    }
                  </Select >
                </Form.Item>
              </Col>
              :
              null
          }
        </Row>
        <Row>
          {
            !isSuperUser ?

              <Col span={24}>
                <Divider />
                <h2 style={{ color: colors.success }}><BiGroup /> {t('Guruhlar')}</h2>
                <Row>
                  {
                    !groupLoading ?
                      <Radio.Group size="large" value={user.groups[0]} onChange={handleChange}>
                        {
                          groups?.map(item => {
                            return <Radio key={item.id} disabled value={item.id}>{item.name}</Radio>
                          })
                        }
                      </Radio.Group>
                      :
                      <Spinner loading={groupLoading} />
                  }
                </Row>
                <Divider />
              </Col>
              :
              null
          }

        </Row>
        <Button
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          size="large"
          loading={loading}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </div>
  )
}
