import { t } from "../utils/locals";

const statusColors = {
  filling: {
    title: t("To'ldirilmoqda"),
    uz: "To'ldirilmoqda",
    oz: "Тўлдирилмоқда",
    ru: "Заполняется",
    bg: "#42C293",
    color: "#fff",
  },
  filling_late: {
    title: t("Kechikib to'ldirilmoqda"),
    bg: "#DE3974",
    color: "#fff",
    uz: "Kech to'ldirilmoqda",
    oz: "Кеч тўлдирилмоқда",
    ru: "Заполняется поздно",
  },
  closed: {
    title: t("Yopilgan"),
    bg: "#D9D9D9",
    color: "#333",
    uz: "Yopilgan",
    oz: "Ёпилган",
    ru: "Закрыт",
  },
  changed: {
    title: t("O'zgartirilgan"),
    bg: "#DE7439",
    color: "#fff",
    uz: "O'zgartirilgan",
    oz: "Ўзгартирилган",
    ru: "Изменен",
  },
  excluded: {
    title: t("Hisobdan chiqarilgan"),
    bg: "#333333",
    color: "#fff",
    uz: "Hisobdan chiqarilgan",
    oz: "Ҳисобдан чиқарилган",
    ru: "Исключен",
  },
};

const gaiServer =
  process.env.NODE_ENV === "development"
    ? "https://apidtp.kash.uz"
    : "https://api-dtp.yhxbb.uz";
const fallBackServer = "http://172.250.4.99:8000";

const configs = {
  UPDATE_DURATION: 1000 * 60 * 5,
  CREATE_DURATION: 1000,
  defaultPageSize: 10,
  AXIOS_TIMEOUT: 20000,
  GOOGLE_MAP_API: "AIzaSyCDG4wQYY-oVPC-mRxJn9j1-GuWPHIgijk",
  URL: {
    REACT_APP_BACKEND_API_URL: `${gaiServer}/api/admin/v1`,
    REACT_APP_BACKEND_ADMIN_HANDBOOK_URL: `${gaiServer}/api/handbook/v1`,
    REACT_APP_TECH_MAP_URL: "http://172.250.4.99:7000",
  },
  URL_FALLBACK: {
    REACT_APP_BACKEND_API_URL: `${fallBackServer}/api/admin/v1`,
    REACT_APP_BACKEND_ADMIN_HANDBOOK_URL: `${fallBackServer}/api/handbook/v1`,
    REACT_APP_TECH_MAP_URL: "https://osm-dtp.yhxbb.uz",
  },
};

export { statusColors, configs as default };
