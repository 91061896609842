import axios from '../utils/axios';

export const getIframeUrl = async () => {
  try {
    const { data } = await axios.get('/auth/users/metabase_url/');
    return data.url;
  }
  catch (err) {
    console.error(err?.response)
    return { error: err, success: false }
  }
};