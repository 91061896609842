/* eslint-disable import/no-anonymous-default-export */
export default {
  accident_number: {
    name: "accident_number",
    uz: "YTH registratsiya raqami",
    ru: "Локальный номер регистрации ДТП",
    oz: "ЙТҲ регистрациядан ўткан рақами"
  },

  accident_date: {
    name: "accident_date",
    uz: "YTH sodir bo'lgan sana. Hafta kuni avtomatik hisoblanadi",
    ru: "Дата происшествия ДТП. День недели считается автоматически",
    oz: "ЙТҲ содир бўлган сана. Ҳафта куни автоматик ҳисобланади"
  },

  accident_time: {
    name: "accident_time",
    uz: "YTH sodir bo'lgan vaqt. Kunning vaqti (kun/tun) avtomatik hisoblanadi.",
    ru: "Время происшествия ДТП. Время суток (день/ночь) рассчитывается автоматически.",
    oz: "ЙТҲ содир бўлган вақт. Куннинг вақти (кун/тун) автоматик ҳисобланади."
  },

  part_of_day: {
    name: "part_of_day",
    uz: "Kunning vaqti (kun/tun) soatga qarab avtomatik hisoblanadi. Agar noto'g'ri hisoblab ko'rsatilsa, iltimos o'zgartirib qo'ying",
    ru: "Время суток (день/ночь) рассчитывается автоматически по часам. Если расчет неверен, пожалуйста, измените его",
    oz: "Куннинг вақти (кун/тун) соатга қараб автоматик ҳисобланади. Агар нотўғри ҳисоблаб кўрсатилса, илтимос ўзгартириб қўйинг"
  },

  accident_type: {
    name: "accident_type",
    uz: "YTH turlari",
    ru: "Виды ДТП",
    oz: "ЙТҲ турлари"
  },

  accident_region: {
    name: "accident_region",
    uz: "YTH sodir bo'lgan viloyat",
    ru: "Регион, где произошло ДТП",
    oz: "ЙТҲ содир бўлган вилоят"
  },

  accident_district: {
    name: "accident_district",
    uz: "YTH sodir bo'lgan tuman/shahar",
    ru: "Район/город, где произошло ДТП",
    oz: "ЙТҲ содир бўлган туман/шаҳар"
  },

  accident_geolocation: {
    name: "accident_geolocation",
    uz: "Haritadan YTH sodir bo'lgan joyni geomanzilini tanlash kerak",
    ru: "На карте необходимо выбрать геолокацию места, где произошло ДТП",
    oz: "Ҳаритадан ЙТҲ содир бўлган жойни геоманзилини танлаш керак"
  },

  accident_residence_type: {
    name: "accident_residence_type",
    uz: "Aholi yashash joyi turi",
    ru: "Тип населенного пункта",
    oz: "Аҳоли яшаш жойи тури"
  },

  accident_street_name: {
    name: "accident_street_name",
    uz: "YTH sodir bo'lgan ko'chaning nomi",
    ru: "Улица, где произошло ДТП",
    oz: "ЙТҲ содир бўлган кўчанинг номи"
  },

  accident_street_significance: {
    name: "accident_street_significance",
    uz: "Yo'l/ko'chaning ahamiyati",
    ru: "Значение улицы/дороги",
    oz: "Йўл/кўчанинг аҳамияти"
  },

  accident_distance_from: {
    name: "accident_distance_from",
    uz: "Yo'lning qaysi kilometrida YTH sodir bo'lgani",
    ru: "На каком км дороги произошло ДТП",
    oz: "Йўлнинг қайси километрида ЙТҲ содир бўлгани"
  },

  road_surface: {
    name: "road_surface",
    uz: "Yo'l qoplamasi turi",
    ru: "Вид дорожного покрытия",
    oz: "Йўл қопламаси тури"
  },

  road_condition: {
    name: "road_condition",
    uz: "YTH sodir bo'lgan vaqtdagi yo'lning qatnov qismi holati",
    ru: "Состояние проезжей части дороги в момент ДТП",
    oz: "YTH sodir bo'lgan vaqtdagi yo'lning qatnov qismi holati"
  },

  road_weather_condition: {
    name: "road_weather_condition",
    uz: "YTH sodir bo'lgan vaqtdagi ob-havo sharoitlari",
    ru: "Погодные условия в момент ДТП",
    oz: "YTH sodir bo'lgan vaqtdagi об-ҳаво шароитлари"
  },

  road_illumination: {
    name: "road_illumination",
    uz: "Yo'lning yotirilganlik darajasi. Kunning faqat qorong'u vaqtida sodir bo'lgan YTHlar uchun amal qiladi",
    ru: "Степень освещенности дороги. Применимо только когда ДТП произошло в темное время суток",
    oz: "Йўлнинг ётирилганлик даражаси. Куннинг фақат қоронғу вақтида бўлган ЙТҲлар учун амал қилади"
  },

  road_part: {
    name: "road_part",
    uz: "Yo'lning qismi",
    ru: "Часть дороги",
    oz: "Йўлнинг қисми"
  },

  road_accident_causals: {
    name: "road_part",
    uz: "YTH keltirib chiqaruvchi holatlar",
    ru: "Обстоятельства сопутствующие к ДТП",
    oz: "ЙТҲ келтириб чиқарувчи ҳолатлар"
  },


  accident_description: {
    name: "accident_description",
    uz: "YTH tavsifi/bayoni",
    ru: "Краткое описание ДТП",
    oz: "ЙТҲ тавсифи/баёни"
  },
  

  accident_photos: {
    name: "accident_photos",
    uz: "YTH joyidagi avtoulovlarni shu yerdagi ko'rsatmalariga asosan tushurilgan rasm va video fayllari yuklansin",
    ru: "Загрузите фото и видео файлы автомобилей с места ДТП согласно инструкциям здесь",
    oz: "ЙТҲ жойидаги автоуловларни шу ердаги кўрсатмаларига асосан тушурилган расм ва видео файллари юклансин"
  },


  participant_no_data: {
    name: "participant_no_data",
    uz: "Ishtirokchilar qo'shish uchun, 'Umumiy malumotlar' qismida YTH turini, sanasi va vaqtini kiritgan bo'lishingiz kerak",
    ru: "Чтобы добавить участников, вы должны сначала ввести тип, дату и время ДТП в разделе 'Общая информация'",
    oz: "Иштирокчилар қўшиш учун, 'Умумий малумотлар' қисмида ЙТҲ турини, санаси ва вақтини киритган бўлишингиз керак"
  },


  participant_driver_violations: {
    name: "participant_driver_violations",
    uz: "Haydovchi tomonidan YHQ buzilishlari. Bir nechta variant tanlash imkoni mavjud",
    ru: "Нарушения ПДД водителем. Можно выбирать несколько вариантов",
    oz: "Ҳайдовчи томонидан ЙҲҚ бузилишлари. Бир нечта вариант танлаш имкони мавжуд"
  },


  participant_pedestrian_violations: {
    name: "participant_pedestrian_violations",
    uz: "Piyoda tomonidan YHQ buzilishlari. Bir nechta variant tanlash imkoni mavjud",
    ru: "Нарушения ПДД пешеходом. Можно выбирать несколько вариантов",
    oz: "Пиёда томонидан ЙҲҚ бузилишлари. Бир нечта вариант танлаш имкони мавжуд"
  },

  participant_bicycle_violations: {
    name: "participant_bicycle_violations",
    uz: "Velosipedchi tomonidan YHQ buzilishlari. Bir nechta variant tanlash imkoni mavjud",
    ru: "Нарушения ПДД велосипедистом. Можно выбирать несколько вариантов",
    oz: "Велосипедчи томонидан ЙҲҚ бузилишлари. Бир нечта вариант танлаш имкони мавжуд"
  },


  participant_carriage_violations: {
    name: "participant_bicycle_violations",
    uz: "Aravakash tomonidan YHQ buzilishlari. Bir nechta variant tanlash imkoni mavjud",
    ru: "Нарушения ПДД конной повозкой. Можно выбирать несколько вариантов",
    oz: "Аравакаш томонидан ЙҲҚ бузилишлари. Бир нечта вариант танлаш имкони мавжуд"
  },


  participant_scooter_violations: {
    name: "participant_bicycle_violations",
    uz: "Skuterchi tomonidan YHQ buzilishlari. Bir nechta variant tanlash imkoni mavjud",
    ru: "Нарушения ПДД скутером. Можно выбирать несколько вариантов",
    oz: "Скутерчи томонидан ЙҲҚ бузилишлари. Бир нечта вариант танлаш имкони мавжуд"
  },


  participant_driver_licence_number: {
    name: "participant_driver_licence_number",
    uz: "Haydovchi guvohnomasi seriyasi va raqami",
    ru: "Серия и номер водительского удостоверения",
    oz: "Ҳайдовчи гувоҳномаси серияси ва рақами"
  },



  participant_driver_full_name: {
    name: "participant_driver_full_name",
    uz: "Haydovchining to'liq ismi sharifi. Shaxsi aniqlanmagan bo'lsa to'ldirilmaydi",
    ru: "ФИО водителя. Не заполняется если личность участника не установлена",
    oz: "Ҳайдовчининг тўлиқ исми шарифи. Шахси аниқланмаган бўлса тўлдирилмайди"
  },

  participant_full_name: {
    name: "participant_full_name",
    uz: "YTH ishtirokchisining to'liq ismi sharifi. Shaxsi aniqlanmagan bo'lsa to'ldirilmaydi",
    ru: "ФИО участника ДТП. Не заполняется если личность участника не установлена",
    oz: "ЙТҲ иштирокчисининг тўлиқ исми шарифи. Шахси аниқланмаган бўлса тўлдирилмайди"
  },

  participant_is_hidden: {
    name: "participant_is_hidden",
    uz: "Ishtirokchi YTH johidan yashiringani",
    ru: "Участник скрылся с места ДТП",
    oz: "Иштирокчи ЙТҲ жоҳидан яширингани"
  },

  participant_is_identified: {
    name: "participant_is_identified",
    uz: "YTH ishtirokchisining shaxshi aniqlanganmi",
    ru: "Личность участника ДТП не устатовлена",
    oz: "ЙТҲ иштирокчисининг шахси аниқланганми"
  },


  participant_driver_birthday: {
    name: "participant_birthday",
    uz: "Haydovchining yoshi tug'ilgan yiliga qarab avtomatik hisoblanadi.",
    ru: "Возраст водителя автоматически считается исходя из года рождения",
    oz: "Ҳайдовчининг ёши туғилган йилига қараб автоматик ҳисобланади"
  },

  participant_birthday: {
    name: "participant_birthday",
    uz: "YTH ishtirokchisining yoshi tug'ilgan yiliga qarab avtomatik hisoblanadi. Agar 1 yoshga to'lmagan bo'lsa ham yoshi 1 deb hisoblab ko'rsatiladi",
    ru: "Возраст участника ДТП автоматически считается исходя из года рождения. Даже если неполный 1 год, возраст берется как 1",
    oz: "ЙТҲ иштирокчисининг ёши туғилган йилига қараб автоматик ҳисобланади. Агар 1 ёшга тўлмаган бўлса ҳам ёши 1 деб ҳисоблаб кўрсатилади"
  },

  participant_gender: {
    name: "participant_gender",
    uz: "YTH ishtirokchisining jinsi",
    ru: "Пол участника ДТП",
    oz: "ЙТҲ иштирокчисининг жинси"
  },

  participant_address: {
    name: "participant_address",
    uz: "Yashash manzili",
    ru: "Адрес проживания",
    oz: "Яшаш манзили"
  },

  participant_driver_experience: {
    name: "participant_driver_experience",
    uz: "Haydovchining tajribasi",
    ru: "Опыт вождения водителя",
    oz: "Ҳайдовчининг малакаси"
  },

  participant_driving_time: {
    name: "participant_driving_time",
    uz: "Haydovchining ruldagi vaqti (YTHga qadar)",
    ru: "Время водителя за рулем (до ДТП)",
    oz: "Ҳайдовчининг рулдаги вақти (ЙТҲга қадар)"
  },


  vehicle_technical_issues: {
    name: "vehicle_technical_issues",
    uz: "Transport vositasining texnik nosozliklari",
    ru: "Технические неисправности транспортного средства",
    oz: "Транспорт воситасининг техник носозликлари"
  },

  vehicle_plate_number: {
    name: "vehicle_plate_number",
    uz: "Transport vositasining davlat raqat belgisi",
    ru: "Государственный номерной знак транспортного средства",
    oz: "Транспорт воситасининг давлат рақам белгиси"
  },


  vehicle_other_identificator: {
    name: "vehicle_other_identificator",
    uz: "Transport vositasining davlat raqat belgisi yo'q bo'lsa. Identifikator, misol uchun, kuzov(VIN) raqami yoki tranzit raqamlar bo'lishi mumkin",
    ru: "Если нет ГНЗ транспортного средства. Идентификатор может быть, например, номер кузова(VIN) или транзитный номер",
    oz: "Транспорт воситасининг давлат рақат белгиси йўқ бўлса. Идентификатор, мисол учун, кузов(ВИН) рақами ёки транзит рақамлар бўлиши мумкин"
  },

  vehicle_tech_passport: {
    name: "vehicle_tech_passport",
    uz: "Transport vositasining texnik pasporti",
    ru: "Технический паспорт транспортного средства",
    oz: "Транспорт воситасининг техник паспорти"
  },

  vehicle_type: {
    name: "vehicle_type",
    uz: "Transport vositasining turi",
    ru: "Тип транспортного средства",
    oz: "Транспорт воситасининг тури"
  },


  vehicle_make: {
    name: "vehicle_make",
    uz: "Transport vositasining markasi",
    ru: "Марка транспортного средства",
    oz: "Транспорт воситасининг маркаси"
  },

  vehicle_model: {
    name: "vehicle_model",
    uz: "Transport vositasining rusumi",
    ru: "Модель транспортного средства",
    oz: "Транспорт воситасининг русуми"
  },


  vehicle_color: {
    name: "vehicle_color",
    uz: "Transport vositasining rangi",
    ru: "Цвет транспортного средства",
    oz: "Транспорт воситасининг ранги"
  },

  vehicle_region: {
    name: "vehicle_region",
    uz: "Transport vositasi ro'yxatga olingan joy",
    ru: "Место регистрации транспортного средства",
    oz: "Транспорт воситаси рўйхатга олинган жой"
  },

  vehicle_is_passenger_transportation: {
    name: "vehicle_is_passenger_transportation",
    uz: "Yo'lovchi tashuvchi transport bositasi",
    ru: "Пассажироперевозка",
    oz: "Йўловчи ташувчи транспорт воситаси"
  },

  vehicle_has_trailer: {
    name: "vehicle_has_trailer",
    uz: "Transport vositasida tirkama mavjud",
    ru: "Имеется прицеп/полуприцеп",
    oz: "Транспорт воситасида тиркама мавжуд"
  },

  vehicle_year_production: {
    name: "vehicle_year_production",
    uz: "Transport vositasi qaysi yili ishlab chiqarilganligi",
    ru: "Год выпуска транспортного выпуска",
    oz: "Транспорт воситаси қайси йили ишлаб чиқарилганлиги"
  },


  vehicle_belong_type: {
    name: "vehicle_belong_type",
    uz: "Transport vositasi jismoniy yoki yuridik shaxsga tegishliligi",
    ru: "Транспортное средство может принадлежать физическому или юридическому лицу",
    oz: "Транспорт воситаси жисмоний ёки юридик шахсга тегишлилиги"
  },


  vehicle_owner: {
    name: "vehicle_owner",
    uz: "Transport vositasining mulkdori",
    ru: "Владелец транспортного средства",
    oz: "Транспорт воситасининг мулкдори"
  },


  participant_health_condition: {
    name: "participant_health_condition",
    uz: "YTH ishtirokchisining holati",
    ru: "Состояние здоровья участника ДТП",
    oz: "ЙТҲ иштирокчисининг холати"
  },


  participant_safety_belt: {
    name: "participant_safety_belt",
    uz: "Havfsizlik kamari yoki motoshlem taqilganligi",
    ru: "Был ли ремен безопасти застегнут или надет мотошлем",
    oz: "Ҳавфсизлик камари ёки мотошлем тақилганлиги"
  },


  participant_hospital: {
    name: "participant_hospital",
    uz: "Qaysi kasalxonaga olib ketilgan",
    ru: "В какую больницу участник ДТП был отправлен",
    oz: "Қайси касалхонага олиб кетилган"
  },

  participant_health_additional: {
    name: "participant_health_additional",
    uz: "YTH ishtirokchisining sog'ligi",
    ru: "Состояние участника ДТП",
    oz: "ЙТҲ иштирокчисининг соғлиги"
  },

  taken_measures: {
    name: "taken_measures",
    uz: "YTH ishi bo'yicha qanday chora ko'rilganligi",
    ru: "Какие меры были приняты по данному делу ДТП",
    oz: "ЙТҲ иши бўйича қайдай чора кўринганлиги"
  },

  taken_measures_codex: {
    name: "taken_measures_codex",
    uz: "Qaysi modda bo'yicha choralar ko'rilganligi, ma'muriy yoki jinoiy kodeks",
    ru: "По какой статье были приняты меры, административный или уголовный кодекс",
    oz: "Қайси модда бўйича чоралар кўринганлиги, маъмурий ёки жиноий кодекс"
  },

  taken_measures_officer: {
    name: "taken_measures_officer",
    uz: "YTH ishini olib borgan xodim FISH, unvoni (YTH kartochkasini to'ldirgan odam boshqa bo'lishi mumkin)",
    ru: "ФИО сотрудника по делу ДТП (Сотрудник заполнявший ДТП карточку может не совпадать)",
    oz: "ЙТҲ ишини олиб борган ходим ФИШ, унвони (ЙТҲ карточкасини тўлдирган одам бошқа бўлиши мумкин)"
  },

  is_foreigner: {
    name: "is_foreigner",
    uz: "Chet el fuqarosi",
    ru: "Иностранный гражданин",
    oz: "Чет эл фуқароси"
  },
  participant_country_name: {
    name: "participant_country_name",
    uz: "Davlatni tanlang",
    ru: "Название государства",
    oz: "Давлат номи"
  },
  vehicle_inspection_date: {
    name: "vehicle_inspection_date",
    uz: "Texnik ko'rikdan o'tkan sanasi",
    ru: "Дата технического осмотра",
    oz: "Техник кўрикдан ўткан санаси"
  },
  vehicle_is_foreigner: {
    name: "vehicle_is_foreigner",
    uz: "Chet elda ro'yhatga olingan transport vositasi",
    ru: "Транспортное средство зарегестрировано в другой стране",
    oz: "Чет эдда рўйҳатга олинган траспорт воситаси"
  },
  vehicle_registred_region: {
    name: "vehicle_registred_region",
    uz: "Transport vositasi ro'yhatga olingan hudud",
    ru: "Место регистрации транспортного средства",
    oz: "Транспорт воситаси рўйҳатга олинган ҳудуд"
  },
  order_number: {
    name: "order_number",
    uz: "Tartib raqami(jinoiy/mamutiy ish raqami yoki rad etish raqami)",
    ru: "Порядковый номер(административного/уголовного дела или номер отказа)",
    oz: "Тартиб рақами(жиноий/маъмурий иш рақами ёки рад этиш рақами) "
  },
  read_only: {
    name: "read_only",
    uz: "Faqat barcha kartalarni ko'rish mumkin.",
    ru: "Может просматривать только все карточки.",
    oz: "Фақат барча карталарни кўриш мумкин."
  }
}