import React, { useEffect, useMemo, useState } from "react";
import {
  YMaps,
  Map,
  TypeSelector,
  Placemark,
  Panorama,
  ObjectManager,
  Clusterer,
} from "@pbe/react-yandex-maps";
import Filters from "../../components/Filters";
import Axios, { cancelToken } from "../../utils/axios";
import dot_icon from "../../assets/dot_icon.png";
import och_qiz from "../../assets/ochiq_qizil.png";
import yashil from "../../assets/yashil.png";
import { Spin } from "antd";

import "./main.scss";

const Yandexmap = () => {
  const [data, setData] = useState([]);
  const [diedData, setDiedData] = useState(false);
  const [injuredData, setInjuredData] = useState(false);
  const [heathyData, setHealthyData] = useState(false);
  const [loading, setLoading] = useState(false);

  const source = cancelToken();

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.get(`/reports/heat_map/`, {
        cancelToken: source.token,
      });
      setData(data.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const died = useMemo(
    () =>
      data.reduce((acc, i) => {
        if (i.died > 0) {
          acc.push(i);
        }
        return acc;
      }, []),
    [data]
  );

  const injured = useMemo(
    () =>
      data.reduce((acc, i) => {
        if ((i.died === 0, i.injured > 0)) {
          acc.push(i);
        }
        return acc;
      }, []),
    [data]
  );

  const healthy = useMemo(
    () =>
      data.reduce((acc, i) => {
        if ((i.died === 0, i.injured === 0)) {
          acc.push(i);
        }
        return acc;
      }, []),
    [data]
  );

  useEffect(() => {
    fetchData();
    return () => source.cancel("Canceling fetch reports");
  }, []);

  return (
    <>
      {loading ? (
        <>
          {" "}
          <div className="map-spinner">
            <Spin size="large" />
          </div>
        </>
      ) : (
        <>
          <div className="my_map_block">
            <YMaps>
              <Map
                style={{ width: "100%", height: "800px" }}
                defaultState={{
                  center: [41.2995, 69.2401],
                  zoom: 14,
                  controls: [],
                }}
              >
                <TypeSelector options={{ float: "right" }} />
                <Clusterer
                  options={{
                    preset: "islands#invertedVioletClusterIcons",
                    // clusterDisableClickZoom: true,
                    groupByCoordinates: false,
                  }}
                >
                  {died.map((item) => (
                    <Placemark
                      key={item.id}
                      geometry={item.location.split(",")}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: dot_icon,
                        iconImageSize: [12, 12],
                      }}
                      properties={{
                        hintContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                        balloonContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                      }}
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                    />
                  ))}
                  {injured.map((item) => (
                    <Placemark
                      key={item.id}
                      geometry={item.location.split(",")}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: och_qiz,
                        iconImageSize: [12, 12],
                      }}
                      properties={{
                        hintContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                        balloonContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                      }}
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                    />
                  ))}

                  {healthy.map((item) => (
                    <Placemark
                      key={item.id}
                      geometry={item.location.split(",")}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: yashil,
                        iconImageSize: [12, 12],
                      }}
                      properties={{
                        hintContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                        balloonContent: `Jarohatlanganlar: ${item.injured} ta<br/>Vafot etganlar: ${item.died} ta`,
                      }}
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                    />
                  ))}
                </Clusterer>
              </Map>
            </YMaps>
            <div className="my_map_modal">
              <Filters />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Yandexmap;
