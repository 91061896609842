import React, {useEffect, useState} from 'react';
import StyledDashboard from '../../styles/dashboard';
import {getIframeUrl} from '../../api/dashboard';
import {t} from '../../utils';
import {message} from 'antd';
// import jwt from 'jsonwebtoken';

export default function Dashboard() {
    const [data, setData] = useState('');
    useEffect(() => {
        (async () => {
            try {

                const data = await getIframeUrl();
                if (data.error) {
                    return message.error(t('Something went wrong'));
                }
                setData(data);
            } catch (err) {
                console.error(err);
            }
        })()
    }, []);

    return (
        <StyledDashboard>
            <iframe
                src={data}
                frameBorder="0"
                allowTransparency
                title="dashboard statistics"
            ></iframe>
        </StyledDashboard>
    )
    // const METABASE_SITE_URL = "https://metabase.yhxbb.uz";
    // const METABASE_SECRET_KEY = "349ce3072276dfb7a8de56538ec35401cd257c079cc63b061fdbcaf4ca871428";
    // const payload = {
    //   resource: { dashboard: 1 },
    //   params: {},
    //   exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    // };
    // const token = jwt.sign(payload, METABASE_SECRET_KEY);
    // const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
}