/* eslint-disable import/no-anonymous-default-export */
export default {
  accident_causals: {
    name: "accident_causals",
    uz: "YTH keltirib chiqaruvchi holatlar",
    ru: "Обстоятельства сопутствующие к ДТП",
    oz: "ЙТҲ келтириб чиқарувчи ҳолатлар",
    url: "accident_causals",
    extraFields: []
  },

  accident_types: {
    name: "accident_types",
    uz: "YTH turlari",
    ru: "Виды ДТП",
    oz: "ЙТҲ турлари",
    url: "accident_types",
    extraFields: []
  },

  belong_types: {
    name: "belong_types",
    uz: "Transport vositasi tegishliligi",
    ru: "Принадлежность транспортного средства",
    oz: "Транспорт воситаси тегишлилиги",
    url: "belong_types",
    extraFields: []
  },

  codex: {
    name: "codex",
    uz: "Kodeks",
    ru: "Кодекс",
    oz: "Кодекс",
    url: "codex",
    extraFields: [
      {
        name: "asbt_id",
        type: "text",
        title: {
          name: "ASBT raqami",
          name_uz: "ASBT raqami",
          name_ru: "ASBT номер",
          name_oz: "ASBT рақами",
        }
      },
      {
        name: "article",
        type: "text",
        title: {
          name: "Modda",
          name_uz: "Modda",
          name_ru: "Статья",
          name_oz: "Модда",
        }
      },
      {
        name: "section",
        type: "text",
        title: {
          name: "Bandi",
          name_uz: "Bandi",
          name_ru: "Банди",
          name_oz: "Kod",
        }
      },
    ],
  },
  countrys: {
    name: "countrys",
    uz: "Davlatlar",
    ru: "Страны",
    oz: "Давлатлар",
    url: "geo/countries",
    extraFields: [],
    static: true,
  },

  countries: {
    name: "countries",
    uz: "Davlatlar",
    ru: "Страны",
    oz: "Давлатлар",
    url: "geo/countries",
    extraFields: []
  },

  districts: {
    name: "districts",
    uz: "Tumanlar",
    ru: "Районы",
    oz: "Туманлар",
    url: "geo/districts",
    nested: true,
    nestedParentUrl: 'geo/regions',
    nestedParentName: 'region',
    nestedParentKey: 'regions',
    extraFields: [{
      name: "region",
      url: "geo/regions",
      title: {
        name: "Viloyatlar",
        name_uz: "Viloyatlar",
        name_ru: "Области",
        name_oz: "Вилоятлар",
      }
    }]
  },

  // health_conditions: {
  //   name: "health_conditions",
  //   uz: "Ishtirokchining holati",
  //   ru: "Состояние здоровья",
  //   oz: "Иштирокчининг ҳолати",
  //   url: "health_conditions",
  //   extraFields: [],
  //   static: true,
  // },

  hospitals: {
    name: "hospitals",
    uz: "Kasalxonalar",
    ru: "Больницы",
    oz: "Касалхоналар",
    url: "hospitals",
    nested: true,
    nestedParentUrl: 'geo/regions',
    nestedParentName: 'region',
    nestedParentKey: 'regions',
    extraFields: [{
      name: "region",
      url: "geo/regions",
      title: {
        name: "Viloyatlar",
        name_uz: "Viloyatlar",
        name_ru: "Области",
        name_oz: "Вилоятлар",
      }
    }]
  },

  illuminations: {
    name: "illuminations",
    uz: "Yoritilganlik",
    ru: "Освещенность",
    oz: "Ёритилганлик",
    url: "illuminations",
    extraFields: []
  },

  licence_categories: {
    name: "licence_categories",
    uz: "Xaydovchilik guvohnomasi kategoriyalari",
    ru: "Категория водительских прав",
    oz: "Ҳайдовчилик гувоҳномаси категорияси",
    url: "licence_categories",
    extraFields: []
  },
  organizations: {
    name: "organizations",
    uz: "Tashkilotlar",
    ru: "Организации",
    oz: "Ташкилотлар",
    url: "organizations",
    extraFields: []
  },

  regions: {
    name: "regions",
    uz: "Viloyatlar",
    ru: "Области",
    oz: "Вилоятлар",
    url: "geo/regions",
    extraFields: [
      {
        url: "geo/countries",
        name: "country",
        title: {
          name: "countries",
          name_uz: "Davlatlar",
          name_ru: "Страны",
          name_oz: "Давлатлар",
        }
      }
    ]
  },

  residence_types: {
    name: "residence_types",
    uz: "Aholi yashash joyi turi",
    ru: "Тип населенного пункта",
    oz: "Аҳоли яшаш жойи тури",
    url: "geo/residence_types",
    extraFields: []
  },

  road_conditions: {
    name: "road_conditions",
    uz: "Yo'lning qatnov qismi holati",
    ru: "Состояние проезжей части дороги",
    oz: "Йўлнинг қатнов қисми ҳолати",
    url: "road_conditions",
    extraFields: []
  },

  road_list: {
    name: "road_list",
    uz: "Yo'l",
    ru: "Yo'l",
    oz: "Yo'l",
    url: "road_list",
    nested: true,
    nestedParentUrl: 'geo/regions',
    nestedParentName: 'region',
    nestedParentKey: 'regions',
    extraFields: [

      {
        name: "street_significance",
        url: "geo/street_significances",
        title: {
          name: "Yo'l/ko'chaning ahamiyati",
          name_uz: "Yo'l/ko'chaning ahamiyati",
          name_ru: "Значение улицы/дороги",
          name_oz: "Йўл/кўчанинг аҳамияти",
        }
      },
      {
        name: "region",
        url: "geo/regions",
        title: {
          name: "Viloyatlar",
          name_uz: "Viloyatlar",
          name_ru: "Области",
          name_oz: "Вилоятлар",
        }
      },
      {
        name: "code",
        type: "text",
        title: {
          name: "Kod",
          name_uz: "Kod",
          name_ru: "Kod",
          name_oz: "Kod",
        }
      },
    ]
  },

  road_parts: {
    name: "road_parts",
    uz: "Yo'lning qismi",
    ru: "Часть дороги",
    oz: "Йўлнинг қисми",
    url: "road_parts",
    extraFields: []
  },

  road_surfaces: {
    name: "road_surfaces",
    uz: "Yo'l qoplamasi turi",
    ru: "Вид дорожного покрытия",
    oz: "Йўл қопламаси тури",
    url: "road_surfaces",
    extraFields: []
  },

  street_significances: {  //accident_type?
    name: "street_significances",
    uz: "Yo'l/ko'chaning ahamiyati",
    ru: "Значение улицы/дороги",
    oz: "Йўл/кўчанинг аҳамияти",
    url: "geo/street_significances",
    extraFields: [],
    hasNestedChild: true,
    nestedChildUrl: '/nested-handbooks/road_list/street_significance'
  },

  taken_measures: {
    name: "taken_measures",
    uz: "Ko'rilgan choralar",
    ru: "Принятые меры",
    oz: "Кўрилган чоралар",
    url: "taken_measures",
    extraFields: []
  },

  technical_issues: {
    name: "technical_issues",
    uz: "Texnik nosozliklar",
    ru: "Технические неисправности",
    oz: "Техник носозликлар",
    url: "technical_issues",
    extraFields: []
  },

  vehicle_colors: {  // colors ?
    name: "vehicle_colors",
    uz: "Transport vositasining rangi",
    ru: "Цвет транспортного средства",
    oz: "Транспорт воситасининг ранги",
    url: "vehicle_colors",
    extraFields: [{
      name: "code",
      type: 'color',
      title: {
        name: "Rang",
        name_uz: "Rang",
        name_ru: "Цвет",
        name_oz: "Ранг",
      }
    },]
  },

  vehicle_brands: {
    name: "vehicle_brands",
    uz: "Transport vositasining markasi",
    ru: "Марка транспортного средства",
    oz: "Транспорт воситасининг маркаси",
    url: "vehicle_brands",
    extraFields: [],
    hasNestedChild: true,
    nestedChildUrl: '/nested-handbooks/vehicle_models/brand'
  },

  vehicle_models: {
    name: "vehicle_models",
    uz: "Transport vositasining rusumi",
    ru: "Модель транспортного средства",
    oz: "Транспорт воситасининг русуми",
    url: "vehicle_models",
    nested: true,
    nestedParentUrl: 'vehicle_brands',
    nestedParentName: 'brand',
    nestedParentKey: 'vehicle_brands',
    extraFields: [
      {
        name: "brand",
        url: "vehicle_brands",
        title: {
          name: "Transport vositasining markasi",
          name_uz: "Transport vositasining markasi",
          name_ru: "Марка транспортного средства",
          name_oz: "Транспорт воситасининг маркаси",
        }
      },
      {
        name: "type",
        url: "vehicle_types",
        title: {
          name: "Transport vositasining turi",
          name_uz: "Transport vositasining turi",
          name_ru: "Тип транспортного средства",
          name_oz: "Транспорт воситасининг тури",
        }
      },

    ]
  },

  vehicle_types: {
    name: "vehicle_types",
    uz: "Transport vositasining turi",
    ru: "Тип транспортного средства",
    oz: "Транспорт воситасининг тури",
    url: "vehicle_types",
    extraFields: [],
    hasNestedChild: true,
    nestedChildUrl: '/nested-handbooks/vehicle_models/type',
  },

  violations: {
    name: "violations",
    uz: "YHQ buzilishlari/qoidabuzarliklar",
    ru: "Нарушения ПДД",
    oz: "ЙҲҚ бузилишлари/қоидабузарликлар",
    url: "violations",
    nested: true,
    nestedParentUrl: 'violations',
    nestedParentName: 'type',
    nestedParentKey: 'type',
    isNestedStatic: true,
    extraFields: [
      {
        name: "type",
        static: true,
        title: {
          name: "Ishtirokchi turi",
          name_uz: "Ishtirokchi turi",
          name_ru: "Тип участника",
          name_oz: "Иштирокчи тури",
        },
        values: [
          { id: 'driver', name: 'Haydovchi', name_uz: 'Haydovchi', name_ru: 'Водитель', name_oz: 'Ҳайдовчи' },
          { id: 'other', name: 'Boshqalar', name_uz: 'Boshqalar', name_ru: 'Другой', name_oz: 'Бошқалар' }]
      }
    ]
  },
  // This is related to violations and do not display it.
  type: {
    static: true,
    name: "type",
    uz: "Ishtirokchi turi",
    ru: "Тип участника",
    oz: "Иштирокчи тури",
  },
  brand: {
    static: true,
    name: "brand",
    uz: "Transport vositasining markasi",
    ru: "Марка транспортного средства",
    oz: "Транспорт воситасининг маркаси",
    url: "vehicle_brands",
    extraFields: []
  },
  weather_conditions: {
    name: "weather_conditions",
    uz: "Ob-havo sharoitlari",
    ru: "Погодные условия",
    oz: "Об-ҳаво шароитлари",
    url: "weather_conditions",
    extraFields: []
  }

}