/* eslint-disable import/no-anonymous-default-export */
export default {
  "1. Umumiy ma'lumotlar": "1. Общие сведения",
  "2. YTH ishtirokchilari": "2. Участники ДТП", //changed
  "YTH ishtirokchilari": "Участники ДТП", //changed
  "3. Jarohatlanganlar": "3. Пострадавшие",
  "4. Ko'rilgan choralar": "4. Принятые меры",
  "24 soatlik format": "24-х часовой формат",
  "Aholi yashash punktini tanlang": "Выберите населенный пункт", //changed
  "Uzbekiston xududidan tashqarida": "Выбранные координаты не могут находиться за пределами Узбекистана",
  Aniqlash: "Определить",
  Asos: "Основание",
  Asosiy: "Основной",
  "Faqat lotin harflarini kiriting!": "Просто введите латинские буквы!",
  "Asosni kiriting": "Добавьте основание",
  "Axoli yashash punkti": "Населённый пункт",
  "Bekor qilish": "Отменить",
  "Chet el fuqarosi": "Иностранный гражданин",
  "Davlat raqami": "Государственный номерной знак (ГНЗ)",
  "Davlat raqami ko'rinadigan qilib old (chap-o'ng) va orqa (chap-o'ng) tomonlaridan suratga oling":
    "Cделайте снимки спереди (слева направо) и сзади (слева направо), чтобы был виден номер страны",
  "Davlat raqamini kiriting": "Введите ГНЗ",
  "Diqqat YTH sanasi 3 kundan avval":
    "Внимание! Дата ДТП отличается более чем на 3 дня от даты создания карточки",
  "Diqqat YTH sanaga kelajak sana kiritildi":
    "Внимание, будущая дата была введена в дату YTH",
  "Diqqat YTH oyga kelajak oy kiritildi":
    "Обратите внимание, что следующий месяц был добавлен к месяцу YTH.",
  "Diqqat YTH yilga kelajak yili kiritildi":
    "Обратите внимание, что год YTH был включен в будущий год.",
  "F.I.SH": "ФИО",
  "F.I.SH kiriting": "Введите ФИО",
  Familyasi: "Фамилия",
  "Fayl yuklash": "Загрузить файл",
  "Tex pasport raqami": "Номер технического паспорта",
  "Tex pasport seriyasi": "Серия технического паспорта",
  "Tex pasport seriyasi va raqami": "Серия и номер технического паспорта",
  "Foydalanuvchi nomi yoki parol noto'g'ri":
    "Неправильное имя пользователя или пароль",
  "Geo manzil": "Геолокация",
  "Guvohnoma seriyasi va toifasi": "Серия и номер водительского удостоверения",
  "Guvohnoma seriyasini kiriting": "Введите серию удостоверения",
  "Hafta kuni": "День недели",
  "Hatolik yuz berdi": "Произошла ошибка",
  "Haydovchi tomonidan YXQ buzulishi": "Нарушения ПДД водителем",
  Haydovchi: "Водитель",
  "Ishlab chiqarilgan yil": "Год производства",
  "Ishtirokchi qo'shish": "Добавить участника",
  "Ishtirokchi turi": "Тип участника",
  "Ishtirokchi turini tanlang": "Выберите тип участника",
  Ismi: "Имя",
  Jarohatlanganlar: "Пострадавшие",
  "Jarohatlanganlar umumiy soni": "Общее количество пострадавших",
  Jinsi: "Пол",
  "Jinsini tanlang": "Выберите пол",
  Keyingisi: "Следующее",
  "Ko'cha nomi": "Название улицы",
  "Ko'cha nomini kiriting": "Введите название улицы",
  "Ko'rilgan choralar": "Принятые меры",
  Kun: "День",
  "Kunning vaqti": "Время суток",
  "Kunning vaqtini": "Kunning vaqtini",
  "Kunning vaqtini tanlang": "Выберите время суток",
  "Ma'lumot muvaffaqiyatli yangilandi": "Информация успешно обновлена",
  "Ma'lumot muvaffaqiyatli yaratildi": "Информация успешно добавлена",
  "Ma'lumot yo'q": "Нет информации",
  "Manzilni kiriting": "Введите адрес",
  Marka: "Марка",
  Model: "Модель",
  "Muvaffaqiyatli tizimga kirish!": "Успешная авторизация!", //changed
  "O'chirish": "Удалить",
  "O'zbekcha": "O'zbekcha",
  "O'zgarishlar tarixi": "История изменений",
  "Ob-havo sharoiti": "Погодные условия",

  "Qatnov qismining xolati": "Состояние проезжей части",
  "Qaysi modda bo'yicha": "По какой статье",
  "Qayta ochish": "Открыть заново",
  Qidiruv: "Поиск",
  Rangi: "Цвет",
  "Ro'yhatga olingan(xududiy tegishligi)":
    "Место регистрации (территориальная принадлежность)",
  "Ruldagi vaqti": "Время за рулем",
  Ruscha: "Русский",
  Sana: "Дата",
  "Shahar/Tuman": "Район/Город",
  "Shahar/tumanni tanlang": "Выберите район/город",
  Sharifi: "Отчество",
  "Shaxsi aniqlanmagan": "Личность не установлена",
  Soat: "Час",
  "Suratga 4 burchakdan oling": "Сделайте снимок с 4 сторон",
  "Suratga olish burchagi 45 darajada bo'lishi kerak":
    "Угол съемки должен составлять 45 градусов.",
  Tajribasi: "Опыт вождения",
  "Talab qilingan ma'lumotlarni kiriting": "Введите необходимую информацию",
  Tanlash: "Выбрать",
  "Tartib raqami(jinoiy/mamutiy ish nomeri yoki rad etish raqami)":
    "Порядковый номер (номер уголовного/административного дела или номер отказа)",
  Tasdiqlash: "Подтвердить",
  Tegishliligi: "Тип принадлежности",
  "Tex pasport seriyasi va raqamini kiriting":
    "Введите серию и номер техпаспорта", //changed
  "Texnik ko'rikdan o'tgan sana": "Дата технического осмотра",
  "Tirkama mavjud": "Есть прицеп",
  "To'ldirilmoqda": "Заполняется",
  "Transport turi": "Вид транспорта",
  "TRANSPORT VOSITASI haqida ma'lumotlar":
    "Информация о транспортном стредстве",
  "Transportni aniqlash": "Определить ТС",
  "Tug'ilgan yili": "Год рождения",
  "Kunning qorong'u vaqti": "Темное время суток", //changed  (tun)
  "Kunning yorug' vaqti": "Светлое время суток", //changed (kun)

  "TV(transport vositasi)": "ТС(транспортное средство", //changed
  "TV kimga tegishli": "Владелец транспортного средства",
  "TV texnik nosozlik turlarini tanlang":
    "Выберите технические неисправности ТС",
  "TV texnik nosozliklari": "Технические неисправности ТС",
  "Umumiy ma'lumotlar": "Общие сведения",
  "Ushbu YTH ishtirokchisini o'chirmoqchimisiz?":
    "Вы хотите удалить этого участника ДТП?",
  "Ushbu YTH yo'lovchisini o'chirmoqchimisiz?":
    "Вы хотите удалить этого пассажира-участника ДТП?",

  Viloyat: "Область",
  "Viloyatni tanlang": "Выберите область",
  "Xalok bo'lganlarning umumiy soni": "Общее количество погибших",
  "Xaritadan tanlash": "Показать на карте",
  "Xatolik yuz berdi": "Произошла ошибка",
  "Xavfsizlik kamari, motoshlem": "Ремень безопасности, мотошлем",
  Xaydovchi: "Водитель",
  "Xaydovchini aniqlash": "Определить водителя",
  "Hisobdan chiqarish": "Исключить из списка",
  Saqlash: "Сохранить",
  YTH: "ДТП", // ?
  "Yashash joyi va manzili": "Адрес проживания",
  yil: "год",
  "Yillik tajribasini kiriting": "Yillik tajribasini kiriting",
  "Yo'l sharoitlari": "Дорожные условия",
  "Yo'l/ko'cha ahamayatini tanlang": "Выберите значение улицы/дороги",
  "Yo'l/ko'chaning ahamyati": "Значение улицы/дороги",
  "Yo'lning qaysi KMda": "На каком КМ дороги",
  "Yo'lning qismi": "Часть дороги",
  "Yo'lning qoplama turi": "Вид покрытия дороги",
  "Yo'lovchi": "Пассажир",
  "Yo'lovchi tashuvchi TV": "ТС перевозящее пассажиров",
  "YO'LOVCHILAR haqida ma'lumotlar": "Информация о пассажирах",
  Yordam: "Помощь",
  Yoritilganlik: "Освещенность",
  yosh: "возраст",
  "YTH joyida transport vositasini suratga olish": "Фотосъемка ТС при ДТП", //changed
  "YTH geo manzilni kiriting": "Введите геолокацию ДТП",
  "YTH joyidagi video va rasmlar": "Фото и видеосъемка с места ДТП",
  "YTH kartochkasi": "Карточка ДТП",
  "YTH keltirib chiqaruvchi holat": "Обстоятельства сопутствующие к ДТП",
  "YTH keltirib chiqaruvchi holatlar": "Обстоятельства сопутствующие к ДТП",
  "YTH keyin avtoulovni qanday qilib to'g'ri suratga olish kerak":
    "Как правильно сделать фотосъемку с места ДТП",
  "YTH qisqa bayoni": "Краткое описание ДТП",
  "YTH ro'yhatga olingan raqam": "Введите зарегистрированный номер ДТП",
  "YTH sodir bo'lgan joy": "Место происшествия ДТП",
  "YTH sodir bo'lgan vaqt": "Время происшествия ДТП",
  //Text changed
  "YTH statistikasi": "Статистика ДТП",
  "YTH tavsifi": "Описание ДТП",
  "YTH turi": "Вид ДТП",
  "YTH turini tanlang": "Выберите вид ДТП", //changed

  "YTH haqida ma'lumot qo'shing": "Добавить информацию о ДТП",
  "YTH ishini olib borgan xodim FISH, unvoni":
    "ФИО, должность сотрудника, по делу ДТП",
  "YTH ishini olib borgan xodim FISH, unvoni kiriting":
    "Введите ФИО, должность сотрудника, по делу ДТП",
  "YTH ishtirokchisini o'chirmoqchimisiz, ushbu ishtirokchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "Удалить участника ДТП? Все данные, введенные для этого участника, будут удалены.",
  "YTH joyidan yashiringan": "Скрылся с места ДТП",
  "YTH yo'lovchisini o'chirmoqchimisiz ? Ushbu yo'lovchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "Удалить пассажира-участника ДТП? Все данные, введенные для этого пассажира, будут удалены.", //changed
  "YXQ buzulishi": "Нарушения ПДД",
  Узбекча: "Ўзбекча",
  Holati: "Состояние",
  Tuman: "Район",
  "Xalok bo'lganlar": "Погибшие",
  "YTH Kartochka yaratish": "Создать ДТП карточку",
  "YTH kartochkalari": "Карточки ДТП",
  Yopilgan: "Закрыто",
  "O'zgartirilgan": "Изменено",
  "Hisobdan chiqarilgan": "Исключено из списка",
  "Kechikib to'ldirilmoqda": "Заполняется поздно",
  Oy: "Месяц",
  "O'lim bilan bog'lik": "ДТП со смертельным исходом",
  Jarohatlanish: "Есть пострадавшие",
  "Sog'liqqa zarar yetmagan": "Не причинен вред здоровью",
  "YTH hisob varaqasi": "Номер ДТП карточки ", //?
  "YTH ID raqami": "ИД номер ДТП",
  Tahrirlash: "Редактировать",
  "Ma'lumot muvaffaqiyatli o'chirildi": "Информация успешно удалена",
  "Ma'lumotnomalar": "Справочники",
  "Ma'lumotnoma nomi": "Название справочника",
  "Ma'lumot qo'shish": "Добавить информацию",
  Faol: "Активный",
  Nofaol: "Неактивный",
  "Davlatni tanlang": "Название государства",
  "tomonidan YXQ buzulishi": " - нарушения ПДД",
  Toifa: "Категория",
  "Haydovchini aniqlash": "Определить водителя",
  "Boshqa identifaktor": "Другой идентификатор",
  "Chet elda ro'yhatga olingan": "Зарегистрировано в другой стране",
  "Tumanni tanlang": "Выберите район",
  "Yo'lovchi qo'shish": "Добавить пассажира",
  Vazifalar: "Действия", //?
  Yaratish: "Создать",
  "Tanlangan ma'lumotlar soni:": "Выбрано:",
  Chiqish: "Выйти",
  "O'chirmoqchimisiz?": "Вы хотите удалить?",
  passenger: "Пассажир",
  driver: "ТС",
  pedestrian: "Пешеход",
  bicycle: "Велосипедист",
  carriage: "Конная повозка",
  scooter: "Скутерист",
  "Texnik ko'rikdan o'tgan": "Техосмотр пройден",
  "Yo'q": "Нет",
  "Ushbu so'rov uchun hech qanday ma'lumot topilmadi.":
    "По этому запросу информации не найдено.",
  "YTH turi bo'yicha": "По типу ДТП",
  "YTH qisqacha tavsifi (bayoni)": "Краткое описание ДТП",
  "HAYDOVCHI haqida ma'lumot": "Информация о ВОДИТЕЛЕ",
  "PIYODA haqida ma'lumot": "Информация о ПЕШЕХОДЕ",
  "VELOSIPEDCHI haqida ma'lumot": "Информация о ВЕЛОСИПЕДИСТЕ",
  "OT-ARAVACHI haqida ma'lumot": "Информация о КОННОЙ ПОВОЗКЕ",
  "SKUTERCHI haqida ma'lumot": "Информация о СКУТЕРИСТЕ",
  "Qo'shimcha ma'lumot": "Дополнительная информация",
  Shifoxona: "Больница",
  "Bu yerda YTH kartochkasi yaratilgan sanadan boshlab barcha o'zgarishlar qayd etiladi.":
    "Здесь фиксируются все последние изменения, произошедшие после даты создания карточки ДТП.",
  "Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?":
    "Вы действительно хотите исключить из списка эту карточку ДТП?",
  "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?":
    "Восстановление исключенной карточки невозможно. Пожалуйста подвтедите действие.",
  "Tizimga kirish": "Вход в систему",
  "Parolingizni kiriting!": "Введите свой пароль!",
  "Emailingizni kiriting!": "Введите свой email!",
  "Loginingizni kiriting!": "Введите ваш логин!",
  "Xodimlar uchun kirish": "Вход для сотрудников",
  "YTH hisobga olish tizimi": "АИС Учет ДТП",
  Parol: "Пароль",
  Login: "Логин",
  "Jarohatlanganlar haqida ma'lumot": "Информация о пострадавших",
  "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana va vaqtni kirish":
    "Карточка ДТП не создана. Для создания необходимо ввести тип ДТП, дату, время, регион, район и адрес",
  "YTH kartochka yaratilmadi. Yaratish uchun Piyodaning F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "Карта YTH не создана. Для создания введите Ф.И.Ш, год рождения, пол и адрес проживания Пешехода",
  "YTH kartochka yaratilmadi. Yaratish uchun Transport vositasining marka, modul, transport turi va rangini kiriting":
    "Карта YTH не создана. Для создания введите марку, модуль, вид транспорта и цвет автомобиля",
  "YTH kartochka yaratilmadi. Yaratish uchun Velosipedchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "Карта YTH не создана. Для создания введите Ф.И.Ш. Велосипедиста, год рождения, пол и адрес",
  "YTH kartochka yaratilmadi. Yaratish uchun Ot-Aravachining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "Карта YTH не создана. Для создания введите Ф.И.Ш, год рождения, пол и адрес Коня-Колесницы",
  "YTH kartochka yaratilmadi. Yaratish uchun Skuterterchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "Карта YTH не создана. Для создания введите Ф.И.Ш, год рождения, пол и адрес самокатчика",
  "YTH kartochka yaratilmadi. Yaratish uchun Haydovchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "Карта YTH не создана. Для создания введите Ф.И.Ш водителя, год рождения, пол и адрес проживания",
  TV: "ТС",
  Yil: "Год",
  Sahifa: "Страница",
  "YTH raqamini kiriting": "YTH raqamini kiriting",
  Shaxsiy: "Личное",
  "Shaxsiy ma'lumotlar": "Персональная информация",
  "Yangilanadigan ma'lumot yo'q.": "Нет обновленной информации.",
  "Ishonchingiz komilmi?": "Вы уверены?",
  "Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?":
    "Ҳақиқатданҳам пароль ёки логинингизни ўзгартирмоқчимисиз?",
  Foydalanuvchilar: "Пользователи",
  "Foydalanuvchi qo'shish": "Добавить пользователя",
  "Moddalarni kiriting": "Введите статьи",
  "Kodeks turini tanlang": "Выберите кодекс",
  "Tartib raqami": "Порядковый номер",
  male: "мужчина",
  female: "женщина",
  night: "Ночь",
  day: "День",
  healthy: "Здоровый",
  died: "Умерший",
  injured: "Пострадавший",
  administrative: "Административный",
  criminal: "Уголовный",

  Unvoni: "Заголовок",
  Guruhlar: "Группы",
  "Foydalanuvchi muvaffaqiyatli yaratildi": "Пользователь успешно создан",
  "Ism majburiy": "Имя обязательно",
  "Parol majburiy": "Пароль обязателен",
  "Login majburiy": "Логин обязателен",
  "Haqiqatdanham guruhni o'chirmoqchimisiz?":
    "Вы действительно хотите удалить группу?",
  "Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?":
    "Вы действительно хотите удалить пользователя?",
  "Guruh qo'shish": "Добавить группу",
  "Yangi guruh": "Новая группа",
  "Yangi guruh nomi": "Название новой группы",
  "Ma'lumot kiritish majburiy": "Ввод данных обязателен",
  "Parolni tasdiqlash": "Подтвердите пароль",
  "Iltimos Parolni tasdiqlang": "Пожалуйста, подтвердите пароль",
  "Siz kiritgan ikkita parol mos emas!":
    "Два введенных вами пароля не совпадают!",
  "O'chirish/Hisobdan chiqarish": "Удалить / исключить из списка",
  "O'zgartirilgan ma'lumotlar": "Измененная информация",
  "O'chirilgan": "Удалено",
  "O'chirilgan ma'lumotlar": "Удаленные данные",
  "Qo'shilgan ma'lumotlar": "Добавленная информация",
  tech_passport_series: "Серия технического паспорта",
  tech_passport_number: "Номер технического паспорта",
  plate_number: "Государственный номерной знак (ГНЗ)",
  another_identifier: "другой идентификатор",
  identifier_name: "Название идентификатора",
  identifier_number: "Номер идентификатора",
  vehicle_address: "Адрес регистрации ТС",
  is_vehicle_foreigner: "ТС зарегистрировано в другой стране",
  is_passenger_transportation: "ТС перевозящее пассажиров",
  has_trailer: "Есть прицеп",
  year_manufacture: "Год производства ТС",
  belong_to: "Владелец ТС",
  inspection_date: "Дата технического осмотра",
  vehicle_type: "Тип транспортного средства",
  vehicle_brand: "Марка ТС",
  vehicle_model: "Модель ТС",
  vehicle_color: "Цвет ТС",
  vehicle_region: "Область регистрации ТС",
  vehicle_district: "Район регистрации ТС",
  vehicle_country: "Страна регистрации ТС",
  belong_type: "Тип владельца ТС",
  technical_issues: "Технические неисправности ТС",
  is_deleted: "Исключено из списка",
  last_name: "Фамилия",
  first_name: "Имя",
  middle_name: "Отчество",
  is_hidden: "Скрылся с места ДТП",
  is_identified: "Личность не установлена",
  age: "Возраст",
  gender_type: "Пол",
  address: "Адрес",
  is_foreigner: "Иностранный гражданин",
  certificate_series: "Номер водительского удостоверения",
  driver_experience: "Опыт вождения",
  driving_time: "Время за рулем",
  safety_belt: "Ремень безопасности, мотошлем",
  health_condition: "Состояние здоровья",
  add_information: "Дополнительная информация",
  region: "Область",
  district: "Район",
  vehicle: "Транспортное средство",
  country: "Страна",
  licence_categories: "Категория удостоверения",
  hospital: "Больница",
  is_late: "Поздное заполнение",
  status: "Статус",
  card_number: "Номер карточки ДТП",
  accident_number: "Номер ДТП",
  date_accident: "Дата ДТП",
  location: "Место происшествия ДТП",
  street_name: "Название улицы",
  distance_from: "На каком КМ дороги",
  description: "Краткое описание ДТП",
  part_of_day: "Время суток",
  officer: "ФИО, должность сотрудника, по делу ДТП",
  article: "Статья",
  order_number: "Порядковый номер",
  codex: "Кодекс",
  accident_type: "Вид ДТП",
  residence_type: "Вид населенного пункта",
  street_significance: "Значение улицы/дороги",
  road_surface: "Вид покрытия дороги",
  road_condition: "Состояние проезжей части",
  weather_condition: "Погодные условия",
  illumination: "Освещенность",
  road_part: "Часть дороги",
  taken_measures: "Принятые меры",
  accident_causal: "Обстоятельства сопутствующие к ДТП",
  violation: "Нарушения ПДД",
  organization: "Организации",
  "Ichki foydalanuvchi": "Внутренний пользователь",
  "Tashqi foydalanuvchi": "Внешний пользователь",
  "Guruh nomi": "Название группы",
  "Ro'yhat": "Список",
  "O'qish": "Чтение",
  "O'zgartirish": "Изменение",
  "Tashqariga API": "Внешний API",
  "Otasining Ismi": "Отчество",
  "Haydovchining manzili bilan bir xil": "Одинаково с адресом водителя",
  "Haydovchining ismi bilan bir xil": "Одинаково с именем водителя",
  "Keyingi texnik ko'rik sanasi": "Дата следующего технического осмотра",
  "O'tgan": "Пройден",
  "O'tmagan": "Не пройден",
  "Texnik ko'rik sanasi": "Дата технического осмотра",
  "O'tgan sana": "Последяя дата",
  "Keyingi sana": "Следующая дата",
  // ============= INSPECTION DATE ===============
  authority: "authority",
  dateInpsection: "Дата технического осмотра",
  dateNextInpsection: "Следующая дата",
  resultInpsection: "Прохождение технического осмотра",
  specialMarks: "Отдельные приметы",
  pComments: "Комментарии",
  pDivision: "Отдел БДД",
  pEmptyWeight: "Пустой вес",
  pFuelType: "Тип топлива",
  pFullWeight: "Полный вес",
  pKuzov: "Номер кузова",
  pModel: "Модель",
  pMotor: "Номер мотора",
  pPlateNumber: "Государственный номерной знак (ГНЗ)",
  pRegistrationDate: "Дата регистрации",
  pSeats: "Количество сидячих мест",
  pStands: "Количество стоячих мест",
  pVehicleColor: "Цвет",
  pVehicleId: "pVehicleId",
  pVehicleType: "Тип транспорта",
  pYear: "Год выпуска",
  // Handbook field names
  technicalissues: "Технические неисправности",
  vehicletype: "Тип транспорта",
  vehiclebrand: "Марка транспорта",
  vehiclemodel: "Модель транспорта",
  vehiclecolor: "Цвет транспорта",
  vehiclecolour: "Цвет транспорта",
  vehiclemark: "Марка транспорта",
  accidentcausal: "Обстоятельства сопутствующие к ДТП",
  accidenttype: "Вид ДТП",
  residencetype: "Вид населенного пункта",
  streetsignificance: "Значение улицы/дороги",
  roadsurface: "Вид покрытия дороги",
  roadcondition: "Состояние проезжей части",
  weathercondition: "Погодные условия",
  roadpart: "Часть дороги",
  takenmeasures: "Принятые меры",
  partroad: "Часть дороги",
  roadtypeaccident: "Вид ДТП",
  licencecategory: "Категория прав",
  belongtype: "Тип принадлежности",
  typeresidence: "Вид населенного пункта",
  Nomi: "Имя",
  Kartochka: "Kарточка",
  Guruh: "Группа",
  Foydalanuvchi: "Пользователь",
  "Haydovchilik guvohnomasi va toifasi":
    "Водительское удостоверение и категория прав",
  "Guvohnoma seriyasi va raqamini kiriting":
    "Введите серию и номер удостоверения",
  Xodim: "Сотрудник",
  "Cheklangan foydalanuvchi": "Ограниченный пользователь",
  "O'zgarishlarni saqlash": "Сохранить изменения",
  "Kartochkadagi o'zgarishlarni saqlab qo'yish":
    "Сохранить изменения в карточке?",
  "Yo'l": "Дорога",
  "Yo'lni tanlang": "Выберите название дороги",
  Kod: "Код",
  "Ko'cha nomi": "Название улицы",
  "Qatorlar soni": "Kоличество полос",
  "Hudud bo'yicha": "По региону",
  "Ishtirokchi holati bo'yicha": "По статусу участника",
  Tozalash: "Сбросить",
  "Qo'llash": "Применить",
  "Sana bo'yicha": "По дате ДТП",
  "Tashkilotni tanlang": "Выберите организацию",
  "Tashkilot nomi": "Название организации",
  "Eski tizimdagi ma'lumotni yangi tizimga mos ravishda kiriting":
    "Введите информацию из старой системы в соответствии с новой системой",
  "Texnik ko'rikdan o'tmagan": "Техосмотр не пройден",
  "Qidiruv bo'yicha ma'lumot topilmadi": "Поиск не дал результатов",
  "Ma'lumot topilmadi": "Информация не найдена",
  "Server bilan aloqa uzuldi.": "Связь с сервером потеряна.",
  "Internet bilan aloqa uzuldi. Birozdan so'ng urunib ko'ring":
    "Интернет-соединение потеряно. Попробуйте позже",
  "Sizda bu amalni oshirish uchun ruhsat yo'q":
    "У вас нет разрешения на выполнение этой операции",
  "Hisobot yaratishda xatolik yuz berdi.":
    "Произошла ошибка при создании отчета.",
  Hisobotlar: "Отчеты",
  Xarita: "Карта",
  Export: "Экспорт",

  "YTH holatlari bo'yicha": "По кейсам ДТП",
  Geolokatsiya: "Геолокация",
  "Lokatsiya mavjud emas": "Местоположение недоступно",
  Sanadan: "с даты",
  Sanagacha: "до даты",
  Soatdan: "С часов",
  Soatgacha: "До часа",
  "YTH turlari bo'yicha": "По видам ДТП",
  "YTH ishtirokchi turlari bo'yicha": "По типам участников ДТП",
  "Yo'l ahamiyati bo'yicha": "По значению дороги",

  cardhistory: "История карты",
};
