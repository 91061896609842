import uz from '../lang/uz';
import oz from '../lang/oz';
import ru from '../lang/ru';
import store from '../store';
const dictionary = {
  ru,
  uz,
  oz,
}

function t(str) {
  const lang = store.getState().auth?.user?.lang || 'uz';
  if (dictionary[lang][str]) {
    return dictionary[lang][str]
  }
  // return str + '-' + lang;
  return str;
}

export { t };