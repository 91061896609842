import React, { useMemo, useState, useEffect, } from 'react';
import { t, getDescription } from '../../utils';
import { Form, Input, Button, Select, Row, Checkbox, Tooltip, Col, message, Divider, Alert, Radio } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { ReactComponent as QuestionMark } from '../../assets/images/card/info.svg';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import S from '../../styles/user';
import { BiGroup } from 'react-icons/bi';
import InputMask from "react-input-mask";
const { Option } = Select;

export default function PersonalInfo({ hideModal, fetchUsers }) {
  const myAccount = useSelector(state => state.auth.user || {});
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [form] = Form.useForm();
  const { regions, districts } = useSelector(state => state.handbooks || {});
  const errorsList = {
    password: t('Parol'),
    username: t('Login'),
  }
  const [errors, setErrors] = useState({});
  const { lang } = myAccount;
  const [user, setUser] = useState({
    district: null,
    email: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '+998',
    rank: '',
    groups: [],
    permissions: [],
    read_only: false,
    region: myAccount.region
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/auth/groups/?page_size=100');
        console.log(data)
        setGroups(data.results);
        setLoading(false);
      }
      catch (err) {
        console.error(err);
      }
    })()
  }, []);

  const handleInput = val => {
    let data = val;
    if (Object.keys(val)[0] === 'region') {
      data = { ...val, district: null };
      form.setFieldsValue(data);
    }
    console.log(data)
    setUser(state => ({ ...state, ...data }))
  }


  const handleSave = async (values) => {
    console.log(values)
    // setErrors({})
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.success,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {

          let request = {}
          for (let x in values) {
            if (values[x]) {
              request = { ...request, [x]: values[x] }
            }
          }

          if (Object.keys(request).length) {
            setLoading(true);
            await axios.post(`/auth/users/`, { ...request, groups: [values.groups] });
            message.success(t("Foydalanuvchi muvaffaqiyatli yaratildi"));
            setLoading(false);
            fetchUsers()
            hideModal();
          } else {
            message.error(t("Yangilanadigan ma'lumot yo'q."))
          }
          setLoading(false);
        }
        catch (err) {
          console.log(err);
          const data = err.response.data;
          let messages = [];
          for (let x in data) {
            setErrors(state => ({ ...state, [x]: data[x] }));
            messages.push(<li style={{ maxWidth: 600, textAlign: 'left', alignSelf: 'flex-start' }} key={x}><b>{errorsList[x] ? errorsList[x] : x}</b>: {data[x].join(', ')}</li>)
          }
          setLoading(false);
          // return message.error(messages, 70);
        }
      }

    });

  }
  const hasError = form.getFieldValue('phone')?.endsWith('_');
  const filteredDistricts = useMemo(() => user.region ? districts.filter(item => item.region === user.region) : districts, [user.region]);
  return (
    <S.AddUser>
      {
        Object.values(errors).length ?
          <Alert
            showIcon
            type="error"
            className="my-3"
            message={<ul style={{ padding: '7px 20px 0px' }}>
              {Object.values(errors).map(i => <li key={i}>{i}</li>)}
            </ul>}
          />
          :
          null
      }

      <h2 style={{ color: colors.success }}>
        <AiOutlineUser /> {t("Shaxsiy ma'lumotlar")}
      </h2>
      <Form
        name="basic"
        onFinish={handleSave}
        form={form}
        className="my-4"
        initialValues={user}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onValuesChange={handleInput}
      >
        <Row gutter={[20, 0]}>
          <Col span={24} md={12}>
            <Form.Item
              name="first_name"
              label={t("Ism")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <Input
                placeholder={t("Ism")}
                autoComplete="off"
                name="first_name"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="last_name"
              label={t("Familiya")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <Input
                placeholder={t("Familiya")}
                autoComplete="off"
                name="last_name"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="middle_name"
              label={t("Otasining Ismi")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <Input
                placeholder={t("Otasining Ismi")}
                autoComplete="off"
                name="middle_name"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="rank"
              label={t("Unvoni")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <Input
                placeholder={t("Unvoni")}
                autoComplete="off"
                name="rank"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <input type="email" hidden />
          <Col span={24} md={12}>
            <Form.Item
              name="email"
              label={t("Email")}
              validateStatus={errors.email ? 'error' : ''}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <Input
                placeholder={t("Email")}
                autoComplete="off"
                name="email"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="phone"
              label={t("Telefon")}
              validateStatus={hasError ? 'error' : 'success'}
              rules={[
                {
                  required: true,
                  message: t("Quydagi format bo'yicha to'ldiring: +998123456789"),
                  min: 13,
                  max: 13
                },
              ]}
            >

              <InputMask
                placeholder="+998 12 345 67 89"
                mask="+\9\98999999999"
                name="phone"
                className={`input-controller focusable mt-2 ${hasError ? 'ant-input-affix-wrapper' : ''}`}
              />

              {/* <Input
                placeholder={t("Telefon")}
                autoComplete="off"
                name="phone"
                maxLength="13"
                minLength="13"
                className="input-controller focusable mt-2"
              /> */}
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              name="region"
              label={t("Viloyat")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy"
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('Viloyat')}
                autoComplete="off"
                name={'region'}
                showAction={["focus", "click"]}
                allowClear
                className="custom-select-content focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                disabled={!myAccount.is_superuser && !user.is_superuser}
              >
                {
                  regions.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="district"
              label={t("Tuman")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy"
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('Tuman')}
                autoComplete="off"
                name={'district'}
                showAction={["focus", "click"]}
                allowClear
                className="custom-select-content form-control focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {
                  filteredDistricts.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="read_only"
              label={null}
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox
                // name="read_only"
                id={"read_only"}
              // checked={user.read_only}
              // onChange={e => setUser(state => ({ ...state, read_only: e.target.checked }))}
              >
                <label htmlFor={"read_only"} className="clickable">
                  {t("Cheklangan foydalanuvchi")}
                  <Tooltip placement="right" title={getDescription('read_only')} trigger={['click', 'hover']}>
                    <QuestionMark className="clickable" />
                  </Tooltip>
                </label>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <h2 style={{ color: colors.success }}><BiGroup /> {t('Guruhlar')}</h2>

            <Form.Item
              name="groups"
              label={null}
              // validateStatus={errors.groups ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: "Guruh tanlash majburiy"
                },
              ]}
            >
              <Radio.Group size="large">
                {
                  groups.map(item => {
                    return <Radio key={item.id} value={item.id}>{item.name}</Radio>
                  })
                }
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24} >
            <Divider />
            <h2 style={{ color: colors.success }}><AiOutlineLock /> {t("Xavfsizlik ma'lumotlar")}</h2>
          </Col>
          <input type="email" hidden />
          <input type="email" hidden />
          <Col span={24}>
            <Form.Item
              name="username"
              label={t("Login")}
              validateStatus={errors.username ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: t("Login majburiy")
                },
              ]}
            >
              <Input
                placeholder={t("Login")}
                autoComplete="off"
                name="username"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <input type="password" hidden />
          <Col span={24} md={12}>
            <Form.Item
              name="password"
              label={t("Parol")}
              validateStatus={errors.password ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: t("Parol majburiy")
                },
              ]}
            >
              <Input.Password
                placeholder={t("Parol")}
                autoComplete="off"
                name="password"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="confirm"
              label={t('Parolni tasdiqlash')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('Iltimos Parolni tasdiqlang'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("Siz kiritgan ikkita parol mos emas!")));
                  },
                }),
              ]}
            >
              <Input.Password className="input-controller focusable mt-2" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={hasError}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </S.AddUser>
  )
}
