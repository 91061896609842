import { paramsToObject } from '../utils';
import moment from 'moment';
import Axios from '../utils/axios';

const defaultParams = `date_accident__gte=${moment().startOf('year').utc(true).toISOString()}&date_accident__lte=${moment().endOf('year').utc(true).toISOString()}`;

function dataParser({ currentData, previouseData, previouseDate, date }) {
  let data = [];
  const titles = Object.keys(Object.values(currentData)[0]);

  for (let x in currentData) {
    const title = x;
    const dataToPush = {
      title,
      currentDate: {
        date: moment(date).format('YYYY'),
        data: Object.values(currentData[x]),
        changes: Object.values(currentData[x]),
        changesInPercent: Object.values(currentData[x]),
      },
      previouseDate: {
        date: previouseDate,
        data: Object.values(previouseData[x]),
        changes: Object.values(previouseData[x]),
        changesInPercent: Object.values(previouseData[x]),
      },
    };
    data.push(dataToPush);
  }

  return { data, titles };
}

const fetchData = async ({ params = defaultParams, url }) => {
  const { date_accident__gte, date_accident__lte, region_id } = paramsToObject(params);

  const previouseDate = moment(date_accident__gte).subtract(1, 'year').utc(true).format('YYYY');
  const previouseDateStart = moment(date_accident__gte).subtract(1, 'year').utc(true).startOf('date').toISOString();
  const previouseDateEnd = moment(date_accident__lte).subtract(1, 'year').subtract(1, 'day').utc(true).endOf('date').toISOString();

  try {
    const previousDateURL = `/reports/${url}/?date_accident__gte=${previouseDateStart}&date_accident__lte=${previouseDateEnd}${region_id ? `&region_id=${region_id}` : ''}`;
    const { data } = await Axios.get(`/reports/${url}/?` + params);
    const { data: previouseData } = await Axios.get(previousDateURL);
    return dataParser({ currentData: data, previouseData, previouseDate, date: date_accident__gte });
  }
  catch (err) {
    return { error: err.message, data: [], titles: [] }
  }
}

export { fetchData };

