import styled from 'styled-components';
import colors from './colors';


const S = {}

S.AddUser = styled.div`
  .input-controller {
    height: 50px !important;
    padding:15px 20px 15px 12px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    height: 50px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder{
    line-height: 50px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item label {
    margin-bottom: -10px;
    padding-bottom: 0;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  .delete-button {
    height: 30px;
    padding: 5px 20px;
    border-radius: 30px;
    
    &:hover {
      background-color: ${colors.danger};
      
      svg {
        fill: #fff;
      }
    }
  }
`;

S.List = styled.div`
  #search-bar {
    height: 40px;

   .ant-input-search-button {
     padding: 2px 10px;
     width: 42px;
     color: ${colors.text};
   }
   
  }
  d.flex {
    align-items: center;
  }
  .anticon-search svg {
    margin-left: 0;
  }
  #add-card {
    margin-left: 15px;
  }

  .ant-table-row:nth-of-type(even) {
    /* background-color: #fafafa; */
  }

  .card-list {
    .ant-pagination {
      justify-content:center;
      margin-top: 35px;
    }

    button svg {
      margin-left: 0; 
      font-size: 14px;
      vertical-align: baseline;
    }
  }

  .ant-tag {
    padding: 3px 20px;
    border-radius: 20px;

    &.active {
      background-color: ${colors.successLight};
      color: ${colors.success};
      border-color: transparent;
    }
    &.inactive {
      background-color: ${colors.dangerLight};
      color: ${colors.danger};
      border-color: transparent;
    }
  }
  .more-btn {
    border: none;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &:hover {
      background-color: ${colors.successLight};
      color: ${colors.success};
    }
  }
`;


export default S;