import React, { useState } from 'react';
import { t, getHandbookName } from '../../utils';
import { Form, Button, Select, Row, Col, message, } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

export default function PersonalInfo({ data }) {
  const [loading, setLoading] = useState(false);
  const myAccount = useSelector(state => state.auth.user || {});
  const { id, lang, region } = data;
  const { regions } = useSelector(state => state.handbooks || {});
  const history = useHistory();

  const [user, setUser] = useState({ region });

  const handleSelect = (name, value) => {
    setUser(user => ({ ...user, [name]: value }));
  }

  const handleSave = async (event) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          setLoading(true);
          await axios.patch(`/auth/users/${id}/`, { region: user.region });
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
          setLoading(false);
          history.push('/external-users');

        }
        catch (err) {
          const { data = {} } = err.response;
          let messages = [];
          for (let x in data) {
            messages.push(<li key={x}>{getHandbookName(x) ?? x}: {data[x].join(', ')}</li>)
          }
          return message.error(messages, 7);
        }
      }

    })
  }


  return (
    <div>
      <h2>{t("Shaxsiy ma'lumotlar")}</h2>
      <Form
        name="control-ref"
        onFinish={handleSave}
        className="my-4"
        initialValues={user}
      >
        <Row gutter={[20, 0]}>

          <Col span={24}>
            <Form.Item
              name="region"
              rules={[
                {
                  required: false,
                  message: 'something'
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Viloyat")}</label>
              <Select
                showSearch
                placeholder={t('Viloyat')}
                onChange={value => handleSelect('region', value)}
                name={'region'}
                showAction={["focus", "click"]}
                value={user.region}
                allowClear
                className="custom-select-content focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                disabled={!myAccount.is_superuser && !user.is_superuser}
              >
                {
                  regions?.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
        </Row>

        <Button
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          size="large"
          loading={loading}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </div >
  )
}
