import React from "react";
import { Col, Collapse } from "antd";
import { useSelector } from "react-redux";
import { t } from "../utils";

const { Panel } = Collapse;

const helpTextDescriptions = {
  uz: [
    {
      title: "STATUSLAR",
      text: [
        "YTH kartochka yaratilganda 'To'ldirilmoqda' statusida yaratiladi.",
        "YTH sodir bo'lgan sana va kartochka yaratilgan sana orasidagi vaqt 3 kundan ko'p bo'lsa 'Kech to'ldirilmoqda' statusi qo'llaniladi.",
        "YTH kartochkasi yaratilgandan sanadan 30 kun ichida ma'lumotlarni yangilab qo'shib borish mumkin. 30 kun o'tkandan so'ng YTH kartochka statusi 'Yopilgan' statusiga almashadi.",
        "'Yopilgan' statusdagi kartochalarga o'zgartirish kiritish uchun oldin YTH kartochkasini qaytadan ochish kerak bo'ladi. Buning uchun 'Qayta ochish'(qulf rasmi bor tugmani) bosish va asosni tanlash kerak bo'ladi.",
        "'Yopilgan' statusdan so'ng o'zgartirishlar kiritilsa YTH kartochka statusi 'O'zgargan'ga almashadi.",
        "'Hisobdan chiqarilgan' statusidagi YTH kartochkalari statistikaga ta'sir qilmaydi va hisobga qo'shilmaydi. Ushbu statusdagi kartochkalarni qayta tiklash imkoni mavjud emas.",
      ],
    },
    {
      title: "O'ZGARTIRISHLAR",
      text: [
        "YTH kartochkasi bo'yicha to'liq tarixni 'O'zgarishlar tarixi' qismida ko'rish mumkin.",
      ],
    },
    {
      title: "YTH KARTOCHKA RAQAMI HAQIDA",
      text: [
        "Masalan, YTH #99-130221: 99=tartib raqami, 130221= 13-kun,2-oy,2021-yil(ya'ni 13-fevral, 2021-yil) anglatadi.",
      ],
    },
    {
      title: "YTH KARTOCHKALARINI TO'LDIRISH HAQIDA",
      text: [
        "YTH kartochka yozuvi sistemada yaralishi uchun YTH sodir bo'lgan sana, vaqt, viloyat, shahar/tuman, manzil va YTH turini kiritish kerak.",
        "Har bir maydon oldidagi so'roq ustiga kursorni olib borilsa, u yerga qanday ma'lumot kiritish kerakligi haqida bilib olish mumkin.",
      ],
    },
    {
      title: "1. Umumiy ma'lumotlar bo'limi",
      text: [
        "Bu qismda YTH umumiy ma'lumotlari to'ldiriladi - YTH turi, sodir bo'lgan vaqt, joy, yo'l sharoitlari, YTH bayoni va voqea joyidan olindan surat/videolar. ",
      ],
    },
    {
      title: "2. YTH ishtirokchilari bo'limi",
      text: [
        "Ishtirokchilar haqida ma'lumotlarni YTH kartochkasi sistemada yaratilib, tartib raqam berilgandan so'ng kiritish mumkin bo'ladi. Ya'ni ishtirokchilarni qo'shish uchun 1-bo'limdagi(Umumiy ma'lumotlar) YTH sodir bo'lgan sana, vaqt va YTH turini kiritish kerak",
        "TV qisqartmasi = transport vositasi.",
        "Transport vositasi o'chirilganda, uning ichidagi barcha ma'lumotlar o'chib ketadi - transport, haydovchi va yo'lovchilar haqidagi ma'lumotlar.",
      ],
    },
    {
      title: "3. Jarohatlanganlar bo'limi",
      text: [
        "Bu qismda YTH ishtirokchilari jarohatlanganligi haqida ma'lumot kiritiladi. Agarda ishtirokchi sog'ligiga zarar yetmagan bo'lsa sog'lom deb kiritilishi kerak.",
      ],
    },
    {
      title: "4. Ko'rilgan choralar bo'limi",
      text: [
        "YTH ishi bo'yicha ko'rilgan choralar va ushbu ishni olib borgan xodim haqida ma'lumotlar",
      ],
    },
  ],
  oz: [
    {
      title: "СТАТУСЛАР",
      text: [
        "ЙТҲ карточка яратилганда 'Тўлдирилмоқда' статусида яратилади.",

        "ЙТҲ содир бўлган сана ва карточка яратилган сана орасидаги вақт 3 кундан кўп бўлса 'Кеч тўлдирилмоқда'' статуси қўлланилади.",

        "ЙТҲ карточкаси яратилгандан санадан 30 кун ичида маълумотларни янгилаб қўшиб бориш мумкин. 30 кун ўткандан сўнг ЙТҲ карточка статуси 'Ёпилган' статусига алмашади.",

        "'Ёпилган' статусдаги карточаларга ўзгартириш киритиш учун олдин ЙТҲ карточкасини қайтадан очиш керак бўлади. Бунинг учун 'Қайта очиш'(қулф расми бор тугмани) босиш ва асосни танлаш керак бўлади.",

        "'Ёпилган' статусдан сўнг ўзгартиришлар киритилса ЙТҲ карточка статуси 'Ўзгарган'га алмашади.",

        "'Ҳисобдан чиқарилган' статусидаги ЙТҲ карточкалари статистикага таъсир қилмайди ва ҳисобга қўшилмайди. Ушбу статусдаги карточкаларни қайта тиклаш имкони мавжуд эмас.",
      ],
    },
    {
      title: "ЎЗГАРТИРИШЛАР",
      text: [
        "ЙТҲ карточкаси бўйича тўлиқ тарихни 'Ўзгаришлар тарихи' қисмида кўриш мумкин.",
      ],
    },
    {
      title: "ЙТҲ КАРТОЧКА РАҚАМИ ҲАҚИДА",
      text: [
        "Масалан, ЙТҲ #99-130221: 99=тартиб рақами, 130221= 13-кун,2-ой,2021 йил(яъни 13 февраль, 2021 йил) англатади.",
      ],
    },
    {
      title: "ЙТҲ КАРТОЧКАЛАРИНИ ТЎЛДИРИШ ҲАҚИДА",
      text: [
        "ЙТҲ карточка ёзуви системада яралиши учун ЙТҲ содир бўлган сана, вақт, вилоят, шаҳар/туман, манзил ва ЙТҲ турини киритиш керак.",
        "Ҳар бир майдон олдидаги сўроқ устига курсорни олиб борилса, у ерга қандай маълумот киритиш кераклиги ҳақида билиб олиш мумкин.",
      ],
    },
    {
      title: "1. Умумий маълумотлар бўлими",
      text: [
        "Бу қисмда ЙТҲ умумий маълумотлари тўлдирилади - ЙТҲ тури, содир бўлган вақт, жой, йўл шароитлари, ЙТҲ баёни ва воқеа жойидан олиндан сурат/видеолар.",
      ],
    },
    {
      title: "2. ЙТҲ иштирокчилари бўлими",
      text: [
        "Иштирокчилар ҳақида маълумотларни ЙТҲ карточкаси системада яратилиб, тартиб рақам берилгандан сўнг киритиш мумкин бўлади. Яъни иштирокчиларни қўшиш учун 1-бўлимдаги(Умумий маълумотлар) ЙТҲ содир бўлган сана, вақт ва ЙТҲ турини киритиш керак",
        "ТВ қисқартмаси = транспорт воситаси.",
        "Транспорт воситаси ўчирилганда, унинг ичидаги барча маълумотлар ўчиб кетади - транспорт, ҳайдовчи ва йўловчилар ҳақидаги маълумотлар.",
      ],
    },
    {
      title: "3. Жароҳатланганлар бўлими",
      text: [
        "Бу қисмда ЙТҲ иштирокчилари жароҳатланганлиги ҳақида маълумот киритилади. Агарда иштирокчи соғлигига зарар етмаган бўлса соғлом деб киритилиши керак.",
      ],
    },
    {
      title: "4. Кўрилган чоралар бўлими",
      text: [
        "ЙТҲ иши бўйича кўрилган чоралар ва ушбу ишни олиб борган ходим ҳақида маълумотлар",
      ],
    },
  ],
  ru: [
    {
      title: "СТАТУСЫ",
      text: [
        "При создании, ДТП-карточка создается в статусе «Заполняется»",
        "Если промежуток времени между датой аварии и датой создания карты превышает 3 дня, применяется статус «Заполняется поздно».",
        "Вы можете обновить данные в течение 30 дней с момента создания карточки ДТП. Через 30 дней статус карточки изменится на «Закрыт».",
        "Чтобы внести изменения в карты со статусом «Закрыт», вам нужно сначала 'открыть заново' карточку ДТП. Для этого нужно нажать на «Открыть заново» (кнопка с изображением замка) и выбрать основание.",
        "Если изменения вносятся после статуса «Закрыт», будет применен статус «Изменен».",
        "ДТП карточки со статусом «Исключен» не влияют на статистику и не будут учитываться. Восстановить карточки этого статуса невозможно.",
      ],
    },
    {
      title: "ИЗМЕНЕНИЯ",
      text: [
        "Полную историю карточки ДТП можно найти в разделе «История изменений».",
      ],
    },
    {
      title: "О НОМЕРЕ ДТП КАРТОЧКИ",
      text: [
        "Например, ДТП # 99-130221 означает: 99 = порядковый номер, 130221 = 13 февраля 2021г.",
      ],
    },
    {
      title: "О ЗАПОЛНЕНИИ ДТП КАРТОЧКИ",
      text: [
        "Для того, чтобы в системе была создана запись карточки ДТП, необходимо ввести дату, время (когда произошло ДТП), регион, город/район, геолокация и тип ДТП",
        "Если вы наведете курсор на значок вопроса перед каждым полем, вы сможете узнать, какую информацию туда вводить.",
      ],
    },
    {
      title: "1. Общая информация",
      text: [
        "В этом разделе заполняется общая информация о ДТП - тип ДТП, время, место происшествия, дорожные условия, описание ДТП и фото/видео с места происшествия.",
      ],
    },
    {
      title: "2. Участники ДТП",
      text: [
        "Информацию об участниках ДТП можно будет добавлять только после создания карточки ДТП в системе и присвоения порядкового номера. То есть, чтобы добавить участников, вам нужно сначала ввести дату, время и тип ДТП в Разделе 1 (Общая информация)",
        "TС = транспортное средство.",
        "Когда ТС удаляется, все данные внутри него стираются - информация об автомобиле, водителе и пассажирах.",
      ],
    },
    {
      title: "3. Пострадавшие",
      text: [
        "В этом разделе представлена ​​информация о травмах участников ДТП. Если здоровью участника не было нанесено вреда, состояние здоровья должно быть 'Здоровый'",
      ],
    },
    {
      title: "4. Принятые меры",
      text: [
        "Информация о принятых мерах по делу ДТП и о сотруднике, который вел это дело",
      ],
    },
  ],
};

export default function HelpSidebarSection(props) {
  const myAccount = useSelector((state) => state.auth.user || {});
  return (
    <Col span={5} className="pl-4">
      <h2>{t("Yordam")}</h2>
      <Collapse accordion>
        {helpTextDescriptions[myAccount.lang]?.map((item, index) => {
          return (
            <Panel header={item.title} key={index}>
              {item.text.length > 1 ? (
                <ul>
                  {item.text.map((text, key) => (
                    <li key={key}>{text}</li>
                  ))}
                </ul>
              ) : (
                <p>{item.text[0]}</p>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </Col>
  );
}
