import React from 'react';
import { Spin } from 'antd';

function Spinner({ loading = false, ...props }) {
  return loading ?
    (
      <div {...props} style={{ display: 'flex', height: 200, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
    :
    null
}

export default Spinner;