import { ErrorMessage } from "formik";
import { t } from "../utils";

/* eslint-disable import/no-anonymous-default-export */
export const info = {
  dtpNumber: "loream ipsum",
};

export const typeOfTransport = [
  {
    static: true,
    id: "driver",
    name: "TV",
    name_ru: "ТС",
    name_uz: "TV",
    name_oz: "ТВ",
  },
  {
    static: true,
    id: "pedestrian",
    name: "Piyoda",
    name_ru: "Пешеход",
    name_uz: "Piyoda",
    name_oz: "Пиёда",
  },
  {
    static: true,
    id: "bicycle",
    name: "Velosiped",
    name_ru: "Велосипед",
    name_uz: "Velosiped",
    name_oz: "Велосипед",
  },
  {
    static: true,
    id: "carriage",
    name: "Ot-arava",
    name_ru: "Конная Повозка",
    name_uz: "Ot-arava",
    name_oz: "От-арава",
  },
  {
    static: true,
    id: "scooter",
    name: "Skuter",
    name_ru: "Скутер",
    name_uz: "Skuter",
    name_oz: "Скутер",
  },
];

export const stepTwoSectionTitle = {
  driver: "HAYDOVCHI haqida ma'lumot",
  pedestrian: "PIYODA haqida ma'lumot",
  bicycle: "VELOSIPEDCHI haqida ma'lumot",
  carriage: "OT-ARAVACHI haqida ma'lumot",
  scooter: "SKUTERCHI haqida ma'lumot",
};

export const typeOfDay = [
  {
    static: true,
    id: "day",
    name: "Kunning yorug' vaqti",
    name_ru: "Светлое время суток",
    name_uz: "Kunning yorug' vaqti",
    name_oz: "Куннинг ёруғ вақти",
  },
  {
    static: true,
    id: "night",
    name: "Kunning qorong'u vaqti",
    name_ru: "Темное время суток",
    name_uz: "Kunning qorong'u vaqti",
    name_oz: "Куннинг қоронғу вақти",
  },
];

export const typeOfCodex = [
  {
    static: true,
    id: "administrative",
    name: "Ma'muriy",
    name_ru: "Административный",
    name_uz: "Ma'muriy",
    name_oz: "Маъмурий",
  },
  {
    static: true,
    id: "criminal",
    name: "Jinoiy",
    name_ru: "Уголовный",
    name_uz: "Jinoiy",
    name_oz: "Жиноий",
  },
];

export const gender = [
  {
    static: true,
    id: "male",
    name: "Erkak",
    name_ru: "Мужчина",
    name_uz: "Erkak",
    name_oz: "Эркак",
  },
  {
    static: true,
    id: "female",
    name: "Ayol",
    name_ru: "Женский",
    name_uz: "Ayol",
    name_oz: "Аёл",
  },
];

export const genderById = {
  male: {
    static: true,
    id: "male",
    name: "Erkak",
    ru: "Мужчина",
    uz: "Erkak",
    oz: "Эркак",
  },
  female: {
    static: true,
    id: "female",
    name: "Ayol",
    ru: "Женский",
    uz: "Ayol",
    oz: "Аёл",
  },
};

export const typeOfHealthConditions = [
  {
    static: true,
    id: "healthy",
    name: "Sog'lom",
    name_ru: "Здоровый",
    name_uz: "Sog'lom",
    name_oz: "Соғлом",
  },
  {
    static: true,
    id: "died",
    name: "Vafot etgan",
    name_ru: "Умерший",
    name_uz: "Vafot etgan",
    name_oz: "Вафот этган",
  },
  {
    static: true,
    id: "injured",
    name: "Jarohatlangan",
    name_ru: "Пострадавший",
    name_uz: "Jarohatlangan",
    name_oz: "Жароҳатланган",
  },
];

export const defaultData = {
  gender_type: "",
  driver_experience: null,
  driving_time: null,
};

export const requiredFields = {
  f5: [
    // "accident_number",
    "date_accident",
    "accident_time",
    "part_of_day",
    "accident_type",
    "id",
    // "status",
    "region",
    "district",
    "location",
  ],
  f6: {
    vehicles: ["type", "vehicle_brand", "vehicle_color", "vehicle_model"],
    pedestrians: [
      // "address",
      "age",
      // "district",
      "first_name",
      "gender_type",
      // "key",
      "last_name",
      "middle_name",
      // "region",
      // "type",
      // "violation",
    ],
    drivers: ["first_name", "middle_name", "age", "last_name", "gender_type"],
  },
  f7: {
    pedestrian: ["health_condition"],
    carriage: ["health_condition"],
    bicycle: ["health_condition", "safety_belt"],
    scooter: ["health_condition", "safety_belt"],
    driver: ["health_condition", "safety_belt"],
  },
};

export const errorMessages = {
  tabErrors: {
    f5: t(
      "Keyingi qadamga o'tish uchun YTH turini, sodir bo'lgan sana, vaqt, viloyat, tuman va manzil kiritish kerak"
    ),
    f6: t(
      "Keyingi qadamga o'tish uchun talab qilingan malumotlarni kiritish kerak"
    ),
    f7: t(
      "Keyingi qadamga o'tish uchun YTH turini, sodir bo'lgan sana, vaqt, viloyat, tuman va manzil kiritish kerak"
    ),
  },
  f5: t(
    "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana, vaqt, viloyat, tuman va manzil kiritish kerak"
  ),
  f6: {
    vehicle: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Transport vositasining marka, modul, transport turi va rangini kiriting"
    ),
    pedestrian: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Piyodaning F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting"
    ),
    bicycle: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Velosipedchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting"
    ),
    carriage: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Ot-Aravachining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting"
    ),
    scooter: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Skuterterchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting"
    ),
    driver: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Haydovchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting"
    ),
  },
  f7: {
    pedestrian: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Piyodaning holatini kiriting"
    ),
    bicycle: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Velosipedchining holati va motoshlemni kiriting"
    ),
    carriage: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Ot-Aravachining holatini kiriting"
    ),
    scooter: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Skuterterchining holati va motoshlemni kiriting"
    ),
    driver: t(
      "YTH kartochka yaratilmadi. Yaratish uchun Haydovchining holati va motoshlemini kiriting"
    ),
  },
};
