import styled from 'styled-components';
import colors from './colors';

const S = {}

S.List = styled.div`
  
  d.flex {
    align-items: center;
  }
  .ant-table-thead th.ant-table-column-sort {
    background-color: #fafafa;
  }
  .ant-table-column-sort {
    background-color: #fff;
  }
  .card-list {
    .ant-pagination {
      justify-content:center;
      margin-top: 35px;
    }

    button svg {
      margin-left: 0; 
      font-size: 14px;
      vertical-align: baseline;
    }
  }
  .delete, .edit {
    padding: 2px 10px;
    height: 22px;
    transition: .4s;

    &:hover {
      background-color: ${colors.danger};
      color: #fff;
      transition: .4s;
      transform: scale(1.2);
    }
  }
  .edit {
    color: ${colors.blue};

    &:hover {
      background-color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }

  .status-active {
    background-color: ${colors.successLight};
    color: ${colors.success};
    border-color: ${colors.successLight};
    text-transform: capitalize;
    padding: 3px 8px;
    letter-spacing: 0.5px;
  }

  .status-deleted {
    background-color: ${colors.dangerLight};
    color: ${colors.danger};
    border-color: ${colors.dangerLight};
    text-transform: capitalize;
    padding: 3px 8px;
    letter-spacing: 0.5px;
  }
  .swal2-actions {
    .swal2-confirm {
      color: #fff;
    }
  }
`;

S.ListItem = styled.li.attrs((props) => ({
  // color: '#333',
}))`
  margin-bottom: 45px;
  position: relative;

  .ant-card-bordered {
    border-color: ${colors.border};
  }

  .header {
    justify-content: space-between;
    align-items: center;

    .date {
      font-size: 14px;
    }
    .date-icon {
      font-size: 20px;
      vertical-align: text-bottom;
    }

    .user { 
      font-size: 16px;
      /* color: ${colors.secondary} */
    }

    .user-icon {
      font-size: 22px;
      vertical-align: text-bottom;
      margin-right: 5px;
      color:#ff9800;
    }

    .title {
      h3 {
        font-size: 18px;
        font-family: "Roboto-Medium";
        line-height: 23px;
        margin-top: 6px;
        color: ${props => props.color};
      }
    }
  }

  .status-icon {
    position: absolute;
    left: -40px;
    top: 33px;
    font-size: 20px;
    background: ${colors.success};
    width: 40px;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 0;
    border-radius: 50%;
  }

  &::before {
    content: '';
    position: absolute;
    left: -40px;
    top: 36px;
    width: 40px;
    height: 1px;
    background: ${colors.success};
  }
  .ant-card-head {
    border-bottom-color: ${colors.border};
  }
  .ant-card-head-title {
    padding-bottom: 8px;
  }

  .sub-header {
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;

    .action-icon {
      margin-right: 10px;
      font-size: 22px;
      color: #8e8e8e;
    }
  }
  .status {
    margin-top: 15px;
  }
  .history-list-item {
    .old-value {
      margin-left: 4px;
      text-decoration: line-through;
      color: ${colors.secondary};
    }
    .change-icon, .added-icon {
      vertical-align: middle;
      margin: 0 7px;
      font-size: 20px;
      color: ${colors.danger};
    }
    .added-icon {
      color: ${colors.success};
    }
    .file-icon {
      vertical-align: middle;
    }
  }
  .file-name {
    color: ${colors.blue};
    margin-left: 5px;
    vertical-align: text-top;
  }
`;

export default S;