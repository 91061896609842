import * as types from '../actionTypes';

const changeLanguage = lang => {
  return ({ type: types.AUTH_CHANGE_LANG, payload: lang });
}

const updateUser = data => {
  return ({ type: types.UPDATE_USER, payload: data });
}

const loginAction = token => {
  return ({ type: types.AUTH_SIGN_IN, payload: token });
}

const logoutAction = () => {
  return ({ type: types.AUTH_SIGN_OUT });
}

export { changeLanguage, loginAction, logoutAction, updateUser };