/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "1. Umumiy ma'lumotlar": "1. Umumiy ma'lumotlar",
  "1.1dan - 1.6gacha ketma-ketlikda": "1.1dan - 1.6gacha ketma-ketlikda",
  "2. YTH ishtirokchilari": "2. YTH ishtirokchilari",
  "YTH ishtirokchilari": "YTH ishtirokchilari",
  "3. Jarohatlanganlar": "3. Jarohatlanganlar",
  "4. Ko'rilgan choralar": "4. Ko'rilgan choralar",
  "24 soatlik format": "24 soatlik format",
  "Ag'darilish": "Ag'darilish",
  "Aholi yashash punktini tanlang": "Aholi yashash punktini tanlang",
  "Uzbekiston xududidan tashqarida":
    "Tanlangan koordinatalar O‘zbekistondan tashqarida bo‘lishi mumkin emas",
  Aniqlash: "Aniqlash",
  Asos: "Asos",
  Asosiy: "Asosiy",
  "Transport yoqilg'i turi": "Transport yoqilg'i turi",
  "Faqat lotin harflarini kiriting!": "Faqat lotin harflarini kiriting!",
  "Asosni kiriting": "Asosni kiriting",
  "Axoli yashash punkti": "Axoli yashash punkti",
  "Bekor qilish": "Bekor qilish",
  "Bu qismda YTHni chisqacha tasnifi to‘ldiriladi. To‘ldirish tartibi:":
    "Bu qismda YTHni chisqacha tasnifi to‘ldiriladi. To‘ldirish tartibi:",
  "Chet el fuqarosi": "Chet el fuqarosi",
  "Davlat raqami": "Davlat raqami",
  "Davlat raqami ko'rinadigan qilib old (chap-o'ng) va orqa (chap-o'ng) tomonlaridan suratga oling":
    "Davlat raqami ko'rinadigan qilib old (chap-o'ng) va orqa (chap-o'ng) tomonlaridan suratga oling",
  "Davlat raqamini kiriting": "Davlat raqamini kiriting",
  "Diqqat YTH sanasi 3 kundan avval": "Diqqat YTH sanasi 3 kundan avval",
  "Diqqat YTH sanaga kelajak sana kiritildi":
    "Diqqat YTH sanaga kelajak sana kiritildi",
  "Diqqat YTH oyga kelajak oy kiritildi":
    "Diqqat YTH oyga kelajak oy kiritildi",
  "Diqqat YTH yilga kelajak yili kiritildi":
    "Diqqat YTH yilga kelajak yili kiritildi",
  "F.I.SH": "F.I.SH",
  "F.I.SH kiriting": "F.I.SH kiriting",
  Familyasi: "Familyasi",
  "Fayl yuklash": "Fayl yuklash",
  "Tex pasport raqami": "Tex pasport raqami",
  "Tex pasport seriyasi": "Tex pasport seriyasi",
  "Tex pasport seriyasi va raqami": "Tex pasport seriyasi va raqami",
  "Foydalanuvchi nomi yoki parol noto'g'ri":
    "Foydalanuvchi nomi yoki parol noto'g'ri",
  "Geo manzil": "Geo manzil",
  "Guvohnoma seriyasi va toifasi": "Guvohnoma seriyasi va toifasi",
  "Guvohnoma seriyasini kiriting": "Guvohnoma seriyasini kiriting",
  "Hafta kuni": "Hafta kuni",
  "Hatolik yuz berdi": "Hatolik yuz berdi",
  "Haydovchi tomonidan YXQ buzulishi": "Haydovchi tomonidan YXQ buzulishi",
  "Ishlab chiqarilgan yil": "Ishlab chiqarilgan yil",
  "Ishtirokchi qo'shish": "Ishtirokchi qo'shish",
  "Ishtirokchi turi": "Ishtirokchi turi",
  "Ishtirokchi turini tanlang": "Ishtirokchi turini tanlang",
  Ismi: "Ismi",
  Jarohatlanganlar: "Jarohatlanganlar",
  "Jarohatlanganlar umumiy soni": "Jarohatlanganlar umumiy soni",
  Jinsi: "Jinsi",
  "Jinsini tanlang": "Jinsini tanlang",
  Keyingisi: "Keyingisi",
  "Ko'cha nomi": "Ko'cha nomi",
  "Ko'cha nomini kiriting": "Ko'cha nomini kiriting",
  "Ko'rilgan choralar": "Ko'rilgan choralar",
  Kun: "Kun",
  "Kunning vaqt": "Kunning vaqt",
  "Kunning vaqtini": "Kunning vaqtini",
  "Kunning vaqtini tanlang": "Kunning vaqtini tanlang",
  "Ma'lumot muvaffaqiyatli yangilandi": "Ma'lumot muvaffaqiyatli yangilandi",
  "Ma'lumot muvaffaqiyatli yaratildi": "Ma'lumot muvaffaqiyatli yaratildi",
  "Ma'lumot yo'q": "Ma'lumot yo'q",
  "Manzilni kiriting": "Manzilni kiriting",
  Marka: "Marka",
  Model: "Model",
  "Muvaffaqiyatli tizimga kirish!": "Muvaffaqiyatli tizimga kirish!",
  "O'chirish": "O'chirish",
  "O'zbekcha": "O'zbekcha",
  "O'zgarishlar tarixi": "O'zgarishlar tarixi",
  "Ob-havo sharoiti": "Ob-havo sharoiti",
  "Piyodani urib yuborish": "Piyodani urib yuborish",
  "Qatnov qismining xolati": "Qatnov qismining xolati",
  "Qaysi modda bo'yicha": "Qaysi modda bo'yicha",
  "Qayta ochish": "Qayta ochish",
  Qidiruv: "Qidiruv",
  "Qora qalin bilan belgilaganlar to‘ldirilishi shart":
    "Qora qalin bilan belgilaganlar to‘ldirilishi shart",
  Rangi: "Rangi",
  "Ro'yhatga olingan(xududiy tegishligi)":
    "Ro'yhatga olingan(xududiy tegishligi)",
  "Ruldagi vaqti": "Ruldagi vaqti",
  Ruscha: "Ruscha",
  Sana: "Sana",
  "Shahar/Tuman": "Shahar/Tuman",
  "Shahar/tumanni tanlang": "Shahar/tumanni tanlang",
  Sharifi: "Sharifi",
  "Shaxsi aniqlanmagan": "Shaxsi aniqlanmagan",
  Soat: "Soat",
  "Suratga 4 burchakdan oling": "Suratga 4 burchakdan oling",
  "Suratga olish burchagi 45 darajada bo'lishi kerak":
    "Suratga olish burchagi 45 darajada bo'lishi kerak",
  Tajribasi: "Tajribasi",
  "Talab qilingan ma'lumotlarni kiriting":
    "Talab qilingan ma'lumotlarni kiriting",
  Tanlash: "Tanlash",
  "Tartib raqami(jinoiy/mamutiy ish nomeri yoki rad etish raqami)":
    "Tartib raqami(jinoiy/mamutiy ish nomeri yoki rad etish raqami)",
  Tasdiqlash: "Tasdiqlash",
  Tegishliligi: "Tegishliligi",
  "Tex pasport seriyasi va raqamini kiriting":
    "Tex pasport seriyasi va raqamini kiriting",
  "Texnik ko'rikdan o'tgan sana": "Texnik ko'rikdan o'tgan sana",
  "Tirkama mavjud": "Tirkama mavjud",
  "To'ldirilmoqda": "To'ldirilmoqda",
  "To'qnashuv": "To'qnashuv",
  "To'siqqa urilish": "To'siqqa urilish",
  "Transport turi": "Transport turi",
  "TRANSPORT VOSITASI haqida ma'lumotlar":
    "TRANSPORT VOSITASI haqida ma'lumotlar",
  "Transportni aniqlash": "Transportni aniqlash",
  "Tug'ilgan yili": "Tug'ilgan yili",
  Tun: "Tun",
  "Turgan TV-ga urilish": "Turgan TV-ga urilish",
  TV: "TV",
  "TV kimga tegishli": "TV kimga tegishli",
  "TV texnik nosozlik turlarini tanlang":
    "TV texnik nosozlik turlarini tanlang",
  "TV texnik nosozliklari": "TV texnik nosozliklari",
  "Umumiy ma'lumotlar": "Umumiy ma'lumotlar",
  "Ushbu YTH ishtirokchisini o'chirmoqchimisiz?":
    "Ushbu YTH ishtirokchisini o'chirmoqchimisiz?",
  "Ushbu YTH yo'lovchisini o'chirmoqchimisiz?":
    "Ushbu YTH yo'lovchisini o'chirmoqchimisiz?",
  "Velosipedchini urib yuborish": "Velosipedchini urib yuborish",
  Viloyat: "Viloyat",
  "Viloyatni tanlang": "Viloyatni tanlang",
  "Xalok bo'lganlarning umumiy soni": "Xalok bo'lganlarning umumiy soni",
  "Xaritadan tanlash": "Xaritadan tanlash",
  "Xatolik yuz berdi": "Xatolik yuz berdi",
  "Xavfsizlik kamari, motoshlem": "Xavfsizlik kamari, motoshlem",
  Xaydovchi: "Xaydovchi",
  "Xaydovchini aniqlash": "Xaydovchini aniqlash",
  "Hisobdan chiqarish": "Hisobdan chiqarish",
  Saqlash: "Saqlash",
  YTH: "YTH",
  "Yashash joyi va manzili": "Yashash joyi va manzili",
  yil: "yil",
  "Yillik tajribasini kiriting": "Yillik tajribasini kiriting",
  "Yo'l sharoitlari": "Yo'l sharoitlari",
  "Yo'l/ko'cha ahamayatini tanlang": "Yo'l/ko'cha ahamayatini tanlang",
  "Yo'l/ko'chaning ahamyati": "Yo'l/ko'chaning ahamyati",
  "Yo'lning qaysi KMda": "Yo'lning qaysi KMda",
  "Yo'lning qismi": "Yo'lning qismi",
  "Yo'lning qoplama turi": "Yo'lning qoplama turi",
  "Yo'lovchi": "Yo'lovchi",
  "Yo'lovchi tashuvchi TV": "Yo'lovchi tashuvchi TV",
  "YO'LOVCHILAR haqida ma'lumotlar": "YO'LOVCHILAR haqida ma'lumotlar",
  Yordam: "Yordam",
  Yoritilganlik: "Yoritilganlik",
  yosh: "yosh",
  "YTH joyida transport vositasini suratga olish":
    "YTH joyida transport vositasini suratga olish",
  "YTH geo manzilni kiriting": "YTH geo manzilni kiriting",
  "YTH joyidagi video va rasmlar": "YTH joyidagi video va rasmlar",
  "YTH kartochkasi": "YTH kartochkasi",
  "YTH keltirib chiqaruvchi holat": "YTH keltirib chiqaruvchi holat",
  "YTH keltirib chiqaruvchi holatlar": "YTH keltirib chiqaruvchi holatlar",
  "YTH keyin avtoulovni qanday qilib to'g'ri suratga olish kerak":
    "YTH keyin avtoulovni qanday qilib to'g'ri suratga olish kerak",
  "YTH qisqa bayoni": "YTH qisqa bayoni",
  "YTH qisqacha tavsifi": "YTH qisqacha tavsifi",
  "YTH raqamini kiriting": "YTH raqamini kiriting",
  "YTH ro'yhatga olingan raqam": "YTH ro'yhatga olingan raqam",
  "YTH sodir bo'lgan joy": "YTH sodir bo'lgan joy",
  "YTH sodir bo'lgan vaqt": "YTH sodir bo'lgan vaqt",
  //Text changed
  "YTH statistikasi": "YTH statistikasi",
  "YTH tavsifi": "YTH tavsifi",
  "YTH turi": "YTH turi",
  "YTH turi tanlang": "YTH turi tanlang",
  "YTH turi va raqamini kiriting": "YTH turi va raqamini kiriting",
  "YTH haqida ma'lumot qo'shing": "YTH haqida ma'lumot qo'shing",
  "YTH ishini olib borgan xodim FISH, unvoni":
    "YTH ishini olib borgan xodim FISH, unvoni",
  "YTH ishini olib borgan xodim FISH, unvoni kiriting":
    "YTH ishini olib borgan xodim FISH, unvoni kiriting",
  "YTH ishtirokchisini o'chirmoqchimisiz, ushbu ishtirokchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "YTH ishtirokchisini o'chirmoqchimisiz, ushbu ishtirokchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi",
  "YTH joyidan yashiringan": "YTH joyidan yashiringan",
  "YTH yo'lovchisini o'chirmoqchimisiz, ushbu yo'lovchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "YTH yo'lovchisini o'chirmoqchimisiz, ushbu yo'lovchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi",
  "YXQ buzulishi": "YXQ buzulishi",
  Узбекча: "Узбекча",
  "YTH ID raqami": "YTH ID raqami",
  "YTH hisob varaqasi": "YTH hisob varaqasi",
  Holati: "Holati",
  Tuman: "Tuman",
  "Xalok bo'lganlar": "Xalok bo'lganlar",
  "YTH Kartochka yaratish": "YTH Kartochka yaratish",
  "YTH kartochkalari": "YTH kartochkalari",
  Yopilgan: "Yopilgan",
  "O'zgartirilgan": "O'zgartirilgan",
  "Hisobdan chiqarilgan": "Hisobdan chiqarilgan",
  "Kechikib to'ldirilmoqda": "Kechikib to'ldirilmoqda",
  Oy: "Oy",
  "O'lim bilan bog'lik": "O'lim bilan bog'lik",
  Jarohatlanish: "Jarohatlanish",
  "Sog'liqqa zarar yetmagan": "Sog'liqqa zarar yetmagan",
  Tahrirlash: "Tahrirlash",
  "Ma'lumot muvaffaqiyatli o'chirildi": "Ma'lumot muvaffaqiyatli o'chirildi",
  "Ma'lumotnomalar": "Ma'lumotnomalar",
  "Ma'lumotnoma nomi": "Ma'lumotnoma nomi",
  "Ma'lumot qo'shish": "Ma'lumot qo'shish",
  Faol: "Faol",
  Nofaol: "Nofaol",
  "Davlatni tanlang": "Davlatni tanlang",
  "tomonidan YXQ buzulishi": "tomonidan YXQ buzulishi",
  Toifa: "Toifa",
  "Haydovchini aniqlash": "Haydovchini aniqlash",
  "Boshqa identifaktor": "Boshqa identifaktor",
  "Chet elda ro'yhatga olingan": "Chet elda ro'yhatga olingan",
  "Tumanni tanlang": "Tumanni tanlang",
  "Yo'lovchi qo'shish": "Yo'lovchi qo'shish",
  Vazifalar: "Vazifalar",
  Yaratish: "Yaratish",
  "Tanlangan ma'lumotlar soni:": "Tanlangan ma'lumotlar soni:",
  Chiqish: "Chiqish",
  "O'chirmoqchimisiz?": "O'chirmoqchimisiz?",
  passenger: "Yo'lovchi",
  driver: "TV",
  Haydovchi: "Haydovchi",
  pedestrian: "Piyoda",
  bicycle: "Velosipedchi",
  carriage: "Ot-aravachi",
  scooter: "Skuterchi",
  "Texnik ko'rikdan o'tgan": "Texnik ko'rikdan o'tgan",
  "Yo'q": "Yo'q",
  "Ushbu so'rov uchun hech qanday ma'lumot topilmadi.":
    "Ushbu so'rov uchun hech qanday ma'lumot topilmadi.",
  "YTH turi bo'yicha": "YTH turi bo'yicha",
  "YTH qisqacha tavsifi (bayoni)": "YTH qisqacha tavsifi (bayoni)",
  "HAYDOVCHI haqida ma'lumot": "HAYDOVCHI haqida ma'lumot",
  "PIYODA haqida ma'lumot": "PIYODA haqida ma'lumot",
  "VELOSIPEDCHI haqida ma'lumot": "VELOSIPEDCHI haqida ma'lumot",
  "OT-ARAVACHI haqida ma'lumot": "OT-ARAVACHI haqida ma'lumot",
  "SKUTERCHI haqida ma'lumot": "SKUTERCHI haqida ma'lumot",
  "Qo'shimcha ma'lumot": "Qo'shimcha ma'lumot",
  Shifoxona: "Shifoxona",
  "Bu yerda YTH kartochkasi yaratilgan sanadan boshlab barcha o'zgarishlar qayd etiladi.":
    "Bu yerda YTH kartochkasi yaratilgan sanadan boshlab barcha o'zgarishlar qayd etiladi.",
  "Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?":
    "Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?",
  "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?":
    "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?",
  "Tizimga kirish": "Tizimga kirish",
  "Parolingizni kiriting!": "Parolingizni kiriting!",
  "Loginingizni kiriting!": "Loginingizni kiriting!",
  "Xodimlar uchun kirish": "Xodimlar uchun kirish",
  "YTH hisobga olish tizimi": "YTH hisobga olish tizimi",
  Parol: "Parol",
  Login: "Login",
  "Jarohatlanganlar haqida ma'lumot": "Jarohatlanganlar haqida ma'lumot",
  "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana va vaqtni kirish":
    "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana, vaqt, viloyat, tuman va manzil kiritish kerak.",
  Yil: "Yil",
  Sahifa: "Sahifa",
  Shaxsiy: "Shaxsiy",
  "Shaxsiy ma'lumotlar": "Shaxsiy ma'lumotlar",
  "Yangilanadigan ma'lumot yo'q.": "Yangilanadigan ma'lumot yo'q.",
  "Ishonchingiz komilmi?": "Ishonchingiz komilmi?",
  "Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?":
    "Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?",
  Foydalanuvchilar: "Foydalanuvchilar",
  "Foydalanuvchi qo'shish": "Foydalanuvchi qo'shish",
  "Moddalarni kiriting": "Moddalarni kiriting",
  "Kodeks turini tanlang": "Kodeks turini tanlang",
  "Tartib raqami": "Tartib raqami",
  "Ma'lumot avtomatik ravishda saqlanmoqda.":
    "Ma'lumot avtomatik ravishda saqlanmoqda.",

  Unvoni: "Unvoni",
  Guruhlar: "Guruhlar",
  "Foydalanuvchi muvaffaqiyatli yaratildi":
    "Foydalanuvchi muvaffaqiyatli yaratildi",
  "Ism majburiy": "Ism majburiy",
  "Parol majburiy": "Parol majburiy",
  "Login majburiy": "Login majburiy",
  "Haqiqatdanham guruhni o'chirmoqchimisiz?":
    "Haqiqatdanham guruhni o'chirmoqchimisiz?",
  "Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?":
    "Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?",
  "Guruh qo'shish": "Guruh qo'shish",
  "Yangi guruh": "Yangi guruh",
  "Yangi guruh nomi": "Yangi guruh nomi",
  "Ma'lumot kiritish majburiy": "Ma'lumot kiritish majburiy",
  "Parolni tasdiqlash": "Parolni tasdiqlash",
  "Iltimos Parolni tasdiqlang": "Iltimos Parolni tasdiqlang",
  "Siz kiritgan ikkita parol mos emas!": "Siz kiritgan ikkita parol mos emas!",
  "O'chirish/Hisobdan chiqarish": "O'chirish/Hisobdan chiqarish",
  "O'zgartirilgan ma'lumotlar": "O'zgartirilgan ma'lumotlar",
  "O'chirilgan": "O'chirilgan",
  "O'chirilgan ma'lumotlar": "O'chirilgan ma'lumotlar",
  "Qo'shilgan ma'lumotlar": "Qo'shilgan ma'lumotlar",
  male: "Erkak",
  female: "Ayol",
  night: "Tun",
  day: "Kun",
  healthy: "Sog'lom",
  died: "Vafot etgan",
  injured: "Jarohatlangan",
  administrative: "Ma'muriy",
  criminal: "Jinoiy",

  tech_passport_series: "Texpasport seriyasi",
  tech_passport_number: "Texpasport raqami",
  plate_number: "Davlat raqam belgisi (DRB)",
  another_identifier: "Boshqa identifikator",
  identifier_name: "Identifikator nomi",
  identifier_number: "Identifikator raqami",
  vehicle_address: "Transport vositasi ro'yxatga olingan manzili",
  is_vehicle_foreigner: "Transport vositasi chet elda ro'yxatga olingan",
  is_passenger_transportation: "Yo'lovchi tashuvchi transport",
  has_trailer: "Tirkama mavjud",
  year_manufacture: "Ishlab chiqarilgan yili",
  belong_to: "Mulkdor",
  inspection_date: "Texnik ko'rikdan o'tkan sana",
  vehicle_type: "Transport turi",
  vehicle_brand: "Transport markasi",
  vehicle_model: "Transport rusumi",
  vehicle_color: "Transport rangi",
  vehicle_region: "Transport ro'yxatga olingan viloyat",
  vehicle_district: "Transport ro'yxatga olingan tuman",
  vehicle_country: "Transport ro'yxatga olingan davlat",
  belong_type: "Mulkdor turi",
  technical_issues: "Texnik nosozliklar",
  is_deleted: "Hisobdan chiqarilgan",
  last_name: "Familiyasi",
  first_name: "Ismi",
  middle_name: "Sharifi",
  is_hidden: "YTH joyidan yashiringan",
  is_identified: "Shaxsi aniqlanmagan",
  age: "Yoshi",
  gender_type: "Jinsi",
  address: "Manzili",
  is_foreigner: "Chet el fuqarosi",
  certificate_series: "Haydovchilik guvohnoma raqami",
  driver_experience: "Haydovchi tajribasi",
  driving_time: "Ro'ldagi vaqti",
  safety_belt: "Xavfsizlik kamari, motoshlem",
  health_condition: "Holati",
  add_information: "Qo'shimcha ma'lumotlar",
  region: "Viloyat",
  district: "Tuman",
  vehicle: "Transport vositasi",
  country: "Davlat",
  licence_categories: "Guvohnoma kategoriyasi",
  hospital: "Kasalxona",
  is_late: "Kechikmoqda",
  status: "Status",
  card_number: "Kartochka raqami",
  accident_number: "YTH raqami",
  date_accident: "YTH sanasi",
  location: "YTH sodir bo'lgan joy",
  street_name: "Ko'chaning nomi",
  distance_from: "Yo'lning qaysi KMda",
  description: "YTH qisqa bayoni",
  part_of_day: "Kunning vaqti",
  officer: "YTH ishini olib borgan xodim",
  article: "Modda",
  order_number: "Tartib raqami",
  codex: "Kodeks",
  accident_type: "YTH turi",
  residence_type: "Axoli yashash joyi turi",
  street_significance: "Yo'l/ko'chaning ahamiyati",
  road_surface: "Yo'lning qoplama turi",
  road_condition: "Qatnov qismining holati",
  weather_condition: "Ob-havo sharoiti",
  illumination: "Yoritilganlik",
  road_part: "Yo'lning qismi",
  taken_measures: "Ko'rilgan choralar",
  accident_causal: "YTH keltirib chiqaruvchi holatlar",
  violation: "YXQ buzulishi",
  "Ichki foydalanuvchi": "Ichki foydalanuvchi",
  "Tashqi foydalanuvchi": "Tashqi foydalanuvchi",
  "Guruh nomi": "Guruh nomi",
  "Ro'yhat": "Ro'yhat",
  "O'qish": "O'qish",
  "O'zgartirish": "O'zgartirish",
  "Tashqariga API": "Tashqariga API",
  "Otasining Ismi": "Otasining Ismi",
  "Haydovchining manzili bilan bir xil": "Haydovchining manzili bilan bir xil",
  "Haydovchining ismi bilan bir xil": "Haydovchining ismi bilan bir xil",
  "Keyingi texnik ko'rik sanasi": "Keyingi texnik ko'rik sanasi",
  "O'tgan": "O'tgan",
  "O'tmagan": "O'tmagan",
  "Texnik ko'rik sanasi": "Texnik ko'rik sanasi",
  "O'tgan sana": "O'tgan sana",
  "Keyingi sana": "Keyingi sana",
  // ============= INSPECTION DATE ===============
  dateInpsection: "Texnik ko'rikdan o'tgan sana",
  dateNextInpsection: "Keyingi sana",
  resultInpsection: "Texnik ko'rikdan o'tganligi",
  specialMarks: "Alohida belgilar",
  pComments: "Izohlar",
  pDivision: "YHX bo'limi",
  pEmptyWeight: "Yuksiz vazni",
  pFuelType: "Yonilg'i turi",
  pFullWeight: "To'la vazni",
  pKuzov: "Kuzov raqami",
  pModel: "Rusumi",
  pMotor: "Motor raqami",
  pPlateNumber: "Davlat raqam belgisi (DRB)",
  pRegistrationDate: "Qayd etilgan sana",
  pSeats: "O'rindiqlar soni",
  pStands: "Tik turadigan joylar soni",
  pVehicleColor: "Rangi",
  pVehicleType: "Transport turi",
  pYear: "Ishlab chiqarilgan yili",
  // Handbook field names
  technicalissues: "Texnik nosozliklar",
  vehicletype: "Transport turi",
  vehiclebrand: "Transport markasi",
  vehiclemodel: "Transport rusumi",
  vehiclecolor: "Transport rangi",
  vehiclecolour: "Transport rangi",
  vehiclemark: "Transport markasi",
  accidentcausal: "YTH keltirib chiqaruvchi holatlar",
  accidenttype: "YTH turi",
  residencetype: "Axoli yashash joyi turi",
  streetsignificance: "Yo'l/ko'chaning ahamiyati",
  roadsurface: "Yo'lning qoplama turi",
  roadcondition: "Qatnov qismining holati",
  weathercondition: "Ob-havo sharoiti",
  roadpart: "Yo'lning qismi",
  takenmeasures: "Ko'rilgan choralar",
  partroad: "Yo'lning qismi",
  roadtypeaccident: "YTH turi",
  licencecategory: "Guvohnoma kategoriyasi",
  belongtype: "Mulkdor turi",
  typeresidence: "Axoli yashash joyi turi",
  roadlist: "Yo'l",
  organization: "Tashkilotlar",
  Nomi: "Nomi",
  Kartochka: "Kartochka",
  Guruh: "Guruh",
  Foydalanuvchi: "Foydalanuvchi",
  "Haydovchilik guvohnomasi va toifasi": "Haydovchilik guvohnomasi va toifasi",
  "Guvohnoma seriyasi va raqamini kiriting":
    "Guvohnoma seriyasi va raqamini kiriting",
  Xodim: "Xodim",
  "Cheklangan foydalanuvchi": "Cheklangan foydalanuvchi",
  "O'zgarishlarni saqlash": "O'zgarishlarni saqlash",
  "Kartochkadagi o'zgarishlarni saqlab qo'yish":
    "Kartochkadagi o'zgarishlarni saqlab qo'yish",
  "Yo'l": "Yo'l",
  "Yo'lni tanlang": "Yo'lni tanlang",
  Kod: "Kod",
  // ---------- Map statistics
  "Hudud bo'yicha": "Hudud bo'yicha",
  "Ishtirokchi holati bo'yicha": "Ishtirokchi holati bo'yicha",
  Tozalash: "Tozalash",
  "Qo'llash": "Qo'llash",
  "Sana bo'yicha": "Sana bo'yicha",
  "Tashkilotni tanlang": "Tashkilotni tanlang",
  "Tashkilot nomi": "Tashkilot nomi",
  "Eski tizimdagi ma'lumotni yangi tizimga mos ravishda kiriting":
    "Eski tizimdagi ma'lumotni yangi tizimga mos ravishda kiriting",
  "Texnik ko'rikdan o'tmagan": "Texnik ko'rikdan o'tmagan",
  "Qidiruv bo'yicha ma'lumot topilmadi": "Qidiruv bo'yicha ma'lumot topilmadi",
  "Ma'lumot topilmadi": "Ma'lumot topilmadi",
  "Server bilan aloqa uzuldi.": "Server bilan aloqa uzuldi.",
  "Internet bilan aloqa uzuldi. Birozdan so'ng urunib ko'ring":
    "Internet bilan aloqa uzuldi. Birozdan so'ng urunib ko'ring",
  "Sizda bu amalni oshirish uchun ruhsat yo'q":
    "Sizda bu amalni oshirish uchun ruhsat yo'q",
  "Hisobot yaratishda xatolik yuz berdi.":
    "Hisobot yaratishda xatolik yuz berdi.",
  Hisobotlar: "Hisobotlar",
  Xarita: "Xarita",
  Export: "Export",
  "Hisobot oralig'i": "Hisobot oralig'i",
  "Hisobot yaratilgan sana": "Hisobot yaratilgan sana",

  cardhistory: "O'zgarishlar tarixi",
  "Tasdiqlash kodini kiriting": "Tasdiqlash kodini kiriting",
  "5 raqmli kod": "5 raqmli kod",
  "Kod hato": "Kod hato",
  "Ushbu faylni o'chirmoqchimisiz?": "Ushbu faylni o'chirmoqchimisiz?",
  "Ko'cha nomi": "Ko'cha nomi",
  "Qatorlar soni": "Qatorlar soni",
  pTexpassport: "pTexpassport",
  pDateRegistration: "pDateRegistration",
  pPassAddress: "pPassAddress",
  pPassDistrCode: "pPassDistrCode",
  pPassRegionCode: "pPassRegionCode",
  pPassStrCode: "pPassStrCode",
  pPassNumber: "pPassNumber",
  pPassSeries: "pPassSeries",
  pMiddleName: "pMiddleName",
  pFirstName: "pFirstName",
  pLastName: "pLastName",
  authority: "authority",
  pVehicleId: "pVehicleId",
  pRegionOfBirth: "pRegionOfBirth",
  pDateOfBirth: "pDateOfBirth",
  pDateOfIssue: "pDateOfIssue",
  pDateOfExpiry: "pDateOfExpiry",
  pIssuingAuthority: "pIssuingAuthority",
  pPersonalIDNumber: "pPersonalIDNumber",
  pLicenceNumber: "pLicenceNumber",
  pLicenceCategory: "pLicenceCategory",
  pDateExpiryPerCategory: "pDateExpiryPerCategory",
  pOldLicenceNumber: "pOldLicenceNumber",
  pSex: "pSex",
};
