import styled from "styled-components";
import colors from "./colors";

const S = {};

S.Main = styled.div`
  .page-wrapper {
    align-items: stretch;

    .leaflet-container {
      margin-top: 0;
    }
  }
  .leaflet-container {
    .marker-cluster-custom.crosswalk {
      background-color: #3197df !important;
    }

    .leaflet-top.leaflet-right {
      position: relative;
    }

    #marker-legend {
      position: absolute;
      top: 90px;
      box-shadow: 0 0 3px #000000;
      right: 10px;
      padding: 3px 6px;
      background-color: white;
      display: flex;
      justify-content: center;
      gap: 4px;
      align-items: center;
      border-radius: 5px;
      pointer-events: auto;

      input,
      img {
        cursor: pointer;
      }

      img {
        border-radius: 5px;
        height: 30px;
        width: 30px;
        object-fit: cover;
      }
    }

    .marker-cluster-custom {
      background-color: #e91e63;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      color: #fff;
    }
    .leaflet-popup-tip {
      background: #333;
    }
    .leaflet-popup-content-wrapper {
      background: #333;
      color: #d7d7d7;
      font-size: 13px;
    }
    .leaflet-popup-content a {
      color: #03a9f4;
      font-size: 14px;
      display: block;
      text-align: center;
      margin-top: 4px;
    }
    .crosswalks img {
      border-radius: 5px;
      height: 35px;
      width: 35px;
      object-fit: cover;
    }

    .icon {
      border-radius: 50%;

      &.died {
        background-color: #ff0000;
      }
      &.injured {
        background-color: #eb9494;
      }
      &.healthy {
        background-color: #45e942;
      }
    }
  }

  .col-left {
    flex: 1;
    margin-right: 16px;
  }
  .col-right {
    width: 300px;
  }
  .map-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(255 255 255 / 60%);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-dot-item {
      background-color: #000;
    }
  }
`;

S.Summary = styled.div`
  .header {
    justify-content: space-between;
  }
  .card-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 5px -15px;
  }
  h4 {
    font-size: 16px;
    line-height: 21px;
    color: #333333;
  }
  .card {
    background-color: #fafafa;
    border-radius: 6px;
    padding: 16px;
    width: 50%;
    margin: 15px;

    p {
      color: #767677;
      margin-bottom: 8px;
    }
  }
`;

S.Filters = styled.div`
  padding: 10px 7px;
  .ant-collapse-content-box {
    padding: 0;
    margin-bottom: 16px;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    background-color: #fff;

    h3 {
      margin-bottom: 0;
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    padding-right: 0 !important;

    h3 {
      flex: 1;
    }
  }

  .ant-picker.ant-picker-range {
    height: 42px;
    width: 100%;
    border: none;
    display: flex;
    padding-left: 0;

    .ant-picker-suffix {
      order: -1;
      padding: 0;
      margin-right: 7px;
      font-size: 18px;
    }
  }

  .ant-picker-input {
    border: 1px solid #d6d8da;
    padding: 7px;
    border-radius: 3px;
    text-align: center;
  }

  .ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-range .ant-picker-active-bar {
    display: none;
  }

  .ant-checkbox-group,
  .ant-checkbox {
    top: unset;
    display: flex;
    flex-wrap: wrap;
  }

  .ant-checkbox-wrapper.ant-checkbox-group-item,
  .ant-checkbox-wrapper.ant-checkbox-item {
    border: 1px solid #d6d8da;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    font-size: 11px;
    width: calc(50% - 16px);
    flex: 1;
    min-width: 100px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    border: 1px solid #42c293;
    background-color: #edfaf6;
  }

  .focusable:focus,
  .ant-input:focus,
  .ant-picker-focused,
  .ant-select-focused,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-upload:focus {
    outline: none;
  }

  .filter-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
`;

export default S;
