/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Input, Button, Table, Divider, Collapse, message } from 'antd';
import axios from '../../utils/axios';
import { useParams, useHistory } from 'react-router-dom';
import { t } from '../../utils';
import { useSelector } from 'react-redux';
import colors from '../../styles/colors';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Panel } = Collapse;

export default function EditGroup(props) {
  const params = useParams();
  const [data, setData] = useState({ name: '', permissions: [] });
  const myAccount = useSelector(state => state.auth.user || {});
  const permissions = useSelector(state => state.permissions || {});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const history = useHistory();
  const { id } = params;

  const fetchGroup = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/auth/groups/${id}/`);
      setData(res.data);
      setLoading(false);
    }
    catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchGroup();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await axios.patch(`/auth/groups/${id}/`, data);
      setLoading(false);
      history.push('/groups');
    }
    catch (err) {
      console.error(err);
      message.error(err.response.data.name);
      setErrors({ name: err.response.data.name })
      setLoading(false);
    }
  }

  const handleChange = useCallback((event) => {
    let { value, checked } = event.target;
    value = parseInt(value);
    if (checked) {
      setData(state => ({ ...state, permissions: [...new Set([...state.permissions, value])] }));
    } else {
      const permissions = data.permissions.filter(i => i !== value)
      setData(state => ({ ...state, permissions }));
    }
  }, [data])

  const columns = useMemo(() => [
    {
      title: <div>{t("Nomi")}</div>,
      dataIndex: 'name',
      render: val => val === 'group' ? t('Guruh') : val === 'card' ? t("Kartochka") : val === 'user' ? t("Foydalanuvchi") : val
    },
    {
      title: <div className="text-center">{t("Ro'yhat")}</div>,
      dataIndex: 'list',
      render: (val, record) => {
        const value = record.list || record.view;
        return <div className="text-capitalize text-center">
          <Checkbox
            value={value}
            disabled={!value}
            onChange={handleChange}
            checked={data.permissions.indexOf(value) > -1}
          />
        </div>
      }
    },
    {
      title: <div className="text-center">{t("O'qish")}</div>,
      dataIndex: 'retrieve',
      render: (val, record) => {
        const value = record.retrieve || record.view;
        return (
          <div className="text-capitalize text-center">
            <Checkbox
              value={value}
              disabled={!value}
              onChange={handleChange}
              checked={data.permissions.indexOf(value) > -1}
            />
          </div>
        )
      },
    },
    {
      title: <div className="text-center">{t('Yaratish')}</div>,
      dataIndex: 'create',
      render: (val, record) => {
        const value = record.create || record.add;
        return (
          <div className="text-capitalize text-center">
            <Checkbox
              disabled={!value}
              onChange={handleChange}
              value={value}
              checked={data.permissions.indexOf(value) > -1}
            />
          </div>
        )
      },
    },
    {
      title: <div className="text-center">{t("O'zgartirish")}</div>,
      dataIndex: 'update',
      render: (val, record) => {
        const value = record.update || record.change;
        return (
          <div className="text-capitalize text-center">
            <Checkbox
              disabled={!value}
              onChange={handleChange}
              value={value}
              checked={data.permissions.indexOf(value) > -1}
            />
          </div>
        )
      },
    },
    {
      title: <div className="text-center">{t("O'chirish/Hisobdan chiqarish")}</div>,
      dataIndex: 'destroy',
      render: (val, record) => {
        const value = record.destroy || record.delete;
        return (
          <div className="text-capitalize text-center">
            <Checkbox
              disabled={!value}
              onChange={handleChange}
              value={value}
              checked={data.permissions.indexOf(value) > -1}
            />
          </div>
        )
      },
    },
  ], [data.permissions, myAccount.lang]);

  const permissionsList = useMemo(() => {
    let result = {
      card: {},
      group: {},
      user: {},
      handbooks: {},
    }

    const handbooksList = [
      'technicalissues',
      'vehicle',
      'vehiclecolor',
      'vehiclebrand',
      'vehiclemodel',
      'vehicletype',
      'violation',
      'accidentcausal',
      'illumination',
      'partroad',
      'roadcondition',
      'roadsurface',
      'accidenttype',
      'takenmeasures',
      'weathercondition',
      'session',
      'participants',
      'licencecategory',
      'hospital',
      'belongtype',
      'typeresidence',
      'streetsignificance',
      'country',
      'district',
      'region',
      'roadlist',
      'codex',
      'organization',
      'cardhistory',
    ]

    for (let i = 0; i < permissions.permissions.length; i++) {
      const id = permissions.permissions[i].id;
      const item = permissions.permissions[i].codename;
      const key = item.slice(item.lastIndexOf('_') + 1);
      const itemType = item.slice(0, item.indexOf('_'));
      // general permissions
      if (result[key]) {
        if (itemType in result[key]) {
          result = {
            ...result,
            [key]: {
              ...result[key],
              [itemType]: id
            }
          }
        } else {
          result = {
            ...result,
            [key]: {
              ...result[key],
              [itemType]: id,
              name: key,
            }
          }
        }
      }
      // handbook permissions
      if (handbooksList.includes(key)) {
        if (itemType === 'retrieve' || itemType === 'list') {
          if (result.handbooks[key]) {
            result.handbooks = {
              ...result.handbooks,
              [key]: {
                ...result.handbooks[key],
                [itemType]: id,
                value: [...result.handbooks[key].value, id],
              }
            }
          } else {
            result.handbooks = {
              ...result.handbooks,
              [key]: {
                [itemType]: id,
                name: key,
                value: [id],
              }
            }
          }
        }
      }
    }
    const { handbooks, ...generalPermissions } = result;
    return {
      general: Object.values(generalPermissions),
      handbook: Object.values(handbooks),
    }
  }, [permissions,]);

  const handleColumnSelection = (checked, field) => {
    let columnPermissions = [];
    if (field === 'all') {
      columnPermissions = permissionsList.handbook.map(item => item.value).flat();
    } else {
      columnPermissions = permissionsList.handbook.map(item => item[field]);
    }

    if (checked) {
      setData(state => ({ ...state, permissions: [...new Set([...state.permissions, ...columnPermissions])] }));
    } else {
      const permissions = data.permissions.filter(i => !columnPermissions.includes(i));
      setData(state => ({ ...state, permissions }));
    }
  }

  const isAllChecked = useMemo(() => permissionsList.handbook?.every(item => data.permissions.includes(item.list) && data.permissions.includes(item.retrieve)), [permissions.handbook, data.permissions]);
  const isAllListChecked = useMemo(() => permissionsList.handbook?.every(item => data.permissions.includes(item.list)), [permissions.handbook, data.permissions]);
  const isAllRetrieveChecked = useMemo(() => permissionsList.handbook?.every(item => data.permissions.includes(item.retrieve)), [permissions.handbook, data.permissions]);

  const handbookColumns = useMemo(() => [
    {
      title: t("Nomi"),
      dataIndex: 'name',
      render: val => t(val)
    },
    {
      title: (
        <div className="text-center">
          {t("Ro'yhat")}
          <Checkbox
            checked={isAllListChecked}
            className="ml-3"
            onChange={e => handleColumnSelection(e.target.checked, 'list')}
          />
        </div>
      ),
      dataIndex: 'list',
      render: (val, record) => {
        const value = record.list || record.view;
        return <div className="text-capitalize text-center">
          <Checkbox
            value={value}
            disabled={!value}
            onChange={handleChange}
            checked={data.permissions.indexOf(value) > -1}
          />
        </div>
      }
    },
    {
      title:
        <div className="text-center">
          {t("O'qish")}
          <Checkbox
            checked={isAllRetrieveChecked}
            className="ml-3"
            onChange={e => handleColumnSelection(e.target.checked, 'retrieve')}
          />
        </div>,
      dataIndex: 'retrieve',
      render: (val, record) => {
        const value = record.retrieve || record.view;
        return (
          <div className="text-capitalize text-center">
            <Checkbox
              value={value}
              disabled={!value}
              onChange={handleChange}
              checked={data.permissions.indexOf(value) > -1}
            />
          </div>
        )
      },
    },

  ], [data.permissions, myAccount.lang]);

  return (
    <div>
      <h2>{t("Yangi guruh")}</h2>
      <Divider />
      <div className="d-flex">
        <Input
          name="name"
          value={data.name}
          style={{ borderColor: errors.name?.length ? colors.danger : '' }}
          className="input-controller mr-3"
          placeholder={t("Yangi guruh nomi")}
          onChange={event => setData(state => ({ ...state, name: event.target.value }))}
        />
        <Button
          size="large"
          type="primary"
          onClick={handleSave}
          className="flex-centered"
        >{t("Saqlash")}</Button>
      </div>
      <div style={{ color: colors.danger }}>{errors.name}</div>
      <Table
        dataSource={permissionsList.general}
        columns={columns}
        loading={loading}
        className="card-list my-5 text-center"
        rowKey="name"
        // rowSelection={rowSelection}
        scroll={{ x: true }}
        rowClassName="clickable"
        pagination={false}
      />

      <Collapse>
        <Panel header={<div className="d-flex" >
          <h3 className="mb-0">{t("Ma'lumotnomalar")}</h3>
          <div onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isAllChecked}
              className="ml-3"
              onChange={e => handleColumnSelection(e.target.checked, 'all')}
            />
          </div>
        </div>

        } key="1">
          <Table
            dataSource={permissionsList.handbook}
            columns={handbookColumns}
            loading={loading}
            className="card-list text-center"
            rowKey="name"
            // rowSelection={rowSelection}
            scroll={{ x: true }}
            rowClassName="clickable"
            pagination={false}
          />
        </Panel>
      </Collapse>

    </div>
  )
}
