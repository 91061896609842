import styled from "styled-components";
import colors from "./colors";

const S = {};

S.NewCard = styled.div`
  .ant-tabs.ant-tabs-top.main-tab.scrollable {
    overflow: visible;
  }
  .statistics {
    margin-top: 20px;
    word-break: break-all;
  }

  .calendar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;

    .ant-picker-input {
      width: auto;
    }
    .ant-picker-input > input {
      display: none;
    }
    .ant-picker-suffix {
      font-size: 24px;
      margin: 0;
    }
  }
  .ant-collapse-item {
    border: none;
    background-color: #fff;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    border: none;
  }

  .anticon.anticon-right.ant-collapse-arrow {
    left: 0;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
  .ant-tabs-tab {
    border-bottom: 4px solid transparent;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 4px solid ${colors.success};
    z-index: 2;
    transition: 0.4s;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 25px;
    font-size: 15px;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .grey {
    background-color: ${colors.darkGrey};
    color: #8d8e8f;
    border: 1px solid transparent;
    font-size: 16px;

    svg {
      vertical-align: middle;
      margin-left: 4px;
    }
    .ant-tag {
      background-color: #d8dadb;
      border: 1px solid rgba(158, 158, 158, 0.36);
      color: #8d8e8f;
      vertical-align: bottom;
    }
  }

  /* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow-y: scroll;
  }
   */
  .custom-tabs {
    overflow: visible;
  }
  .custom-tabs .ant-tabs-nav-list {
    /* padding-bottom: 10px; */
    transform: translate(0px, 0px);
    overflow: auto;
    position: relative;
    /* bottom: -10px; */
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 30px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: #fff;

    .tab-title {
      font-size: 16px;
    }
    .anticon svg {
      font-size: 14px;
      margin-left: 0;
    }
  }
  .custom-tabs .ant-tabs-content {
    background-color: ${colors.grey};
  }
  .ant-tabs-nav-add .anticon svg {
    font-size: 14px;
    margin-left: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
    background-color: ${colors.grey};
    border-bottom: none;
    border-color: ${colors.borderLight};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.text};
    font-family: "Roboto-Medium";
  }
  .late-entry {
    .text-danger {
      margin-left: 7px;
    }
  }
  .add-btn {
    color: ${colors.text};
    text-transform: uppercase;
    font-weight: 500;
    margin-left: auto;

    button {
      padding-left: 3px;
    }
  }

  .custom-tabs {
    .ant-tabs-tab-remove {
      display: none;
    }
  }
  .nested-tabs .ant-tabs-tab-remove {
    display: block;
  }
  .nested-tabs {
    .ant-tabs-nav-list .ant-tabs-tab-with-remove {
      border-radius: 5px;
      border: 1px solid #9e9e9e;
    }
    .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
      background-color: ${colors.text};

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
  .old-values {
    color: #ff9800;
    margin-bottom: 15px;

    .value {
      background: #fff0d4;
      color: #ff9800;
      padding: 8px 8px;
      border-radius: 4px;
    }
  }

  .main-tab.scrollable.ant-tabs-nav {
    overflow: visible;
    & .ant-tabs-nav-wrap {
      width: 100%;
      overflow-y: auto;
      display: flex;
      padding-bottom: 15px;
    }
  }

  .custom-tabs .ant-tabs-nav {
    & .ant-tabs-nav-wrap {
      padding-bottom: 0px !important;
      overflow: visible;
    }
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 0px;
  }

  .error {
    border-color: red !important;
    outline-color: red !important;
  }

  @media screen and (max-width: 1440px) {
    .main-tabs .ant-tabs-nav-list .ant-tabs-tab {
      margin-right: 25px;
    }
    .tab-title {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    .main-tabs .ant-tabs-nav-list .ant-tabs-tab {
      margin-right: 25px;
    }
    .tab-title {
      font-size: 14px;
    }
  }
`;

S.CardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    margin: 0;
    margin-right: auto;
    margin-left: 12px;
  }

  button {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-shadow: none;
    box-shadow: none;
    padding: 10px;
    margin-left: 7px;

    .icon {
      margin-right: 4px;
    }
    span {
      display: flex;
      align-items: center;
    }
    .ant-tag {
      margin-left: 12px;
      padding-top: 3px;
    }
  }
  .grey {
    background-color: ${colors.darkGrey};
    color: #8d8e8f;
    border: 1px solid transparent;

    .ant-tag {
      background-color: #d8dadb;
      border: 1px solid rgba(158, 158, 158, 0.36);
      color: #8d8e8f;
    }
  }
  .f-code {
    display: none;
  }
`;

S.Participants = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 5px;
  padding: 20px;

  .participant-title {
    font-size: 20px;
    font-family: "Roboto-Medium";
  }
  .plate-number {
    border: 1px solid ${colors.text};
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 24px;
    font-family: "Roboto-Medium";
    .br-1 {
      margin-right: 3px;
      padding: 2px 4px;
    }
    & > span {
      padding: 2px 0;
      &:first-of-type {
        height: 100%;
        line-height: 19px;
      }
    }

    .br-1 {
      border-color: ${colors.text};
    }

    .flag {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      padding: 2px;
      svg {
        width: 12px;
      }
      b {
        color: #0492ac;
        font-size: 7px;
        margin-top: 3px;
        line-height: 1;
      }
    }
  }

  .sub-title {
    margin-bottom: 5px;
  }

  .title {
    font-size: 18px;
    line-height: 23px;
    font-family: "Roboto-Medium";
    margin: 0;
  }
`;

S.List = styled.div`
  #search-bar {
    height: 40px;

    .ant-input-search-button {
      padding: 2px 10px;
      width: 42px;
      color: ${colors.text};
    }
  }
  d.flex {
    align-items: center;
  }
  .anticon-search svg {
    margin-left: 0;
  }
  #add-card {
    margin-left: 15px;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-row:nth-of-type(even) {
    background-color: #fafafa;
  }

  .card-list {
    .ant-pagination {
      justify-content: center;
      margin-top: 35px;
    }

    button svg {
      margin-left: 0;
      font-size: 14px;
      vertical-align: baseline;
    }
  }
  .page-wrapper {
    align-items: stretch;

    .leaflet-container {
      margin-top: 0;
    }
  }

  .col-left {
    flex: 1;
    margin-right: 16px;
  }
  .col-right {
    width: 300px;
  }
`;

S.History = styled.div`
  .history-list {
    list-style: none;
    position: relative;
    margin-left: 20px;

    &::before {
      content: "";
      height: calc(100% - 36px);
      position: absolute;
      top: 36px;
      left: 0px;
      border-left: 1px solid ${colors.success};
    }

    &::after {
      content: "";
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 20px;
      height: 1px;
      background: ${colors.success};
    }
  }
`;

S.HistoryItem = styled.li.attrs((props) => ({
  // color: '#333',
}))`
  margin-bottom: 45px;
  position: relative;

  .ant-card-bordered {
    border-color: ${colors.border};
  }

  .header {
    justify-content: space-between;
    align-items: center;

    .date {
      font-size: 14px;
    }
    .date-icon {
      font-size: 20px;
      vertical-align: text-bottom;
    }

    .user {
      font-size: 16px;
      /* color: ${colors.secondary} */
    }

    .user-icon {
      font-size: 22px;
      vertical-align: text-bottom;
      margin-right: 5px;
      color: #ff9800;
    }

    .title {
      h3 {
        font-size: 18px;
        font-family: "Roboto-Medium";
        line-height: 23px;
        margin-top: 6px;
        color: ${(props) => props.color};
      }
    }
  }

  .status-icon {
    position: absolute;
    left: -40px;
    top: 33px;
    font-size: 20px;
    background: ${colors.success};
    width: 40px;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 0;
    border-radius: 50%;
  }

  &::before {
    content: "";
    position: absolute;
    left: -40px;
    top: 36px;
    width: 40px;
    height: 1px;
    background: ${colors.success};
  }
  .ant-card-head {
    border-bottom-color: ${colors.border};
  }
  .ant-card-head-title {
    padding-bottom: 8px;
  }

  .sub-header {
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;

    .action-icon {
      margin-right: 10px;
      font-size: 22px;
      color: #8e8e8e;
    }
  }
  .participant {
    background-color: #f6f6f6;
    padding: 7px;
  }
  .status {
    margin-top: 15px;
  }
  .history-list-item {
    .old-value {
      margin-left: 4px;
      text-decoration: line-through;
      color: ${colors.secondary};
    }
    .change-icon,
    .added-icon {
      vertical-align: middle;
      margin: 0 7px;
      font-size: 20px;
      color: ${colors.danger};
    }
    .added-icon {
      color: ${colors.success};
    }
    .file-icon {
      vertical-align: middle;
    }
  }
  .file-name {
    color: ${colors.blue};
    margin-left: 5px;
    vertical-align: text-top;
  }
  .attachment {
    .history-list-item.deleted {
      color: ${colors.danger};
      text-decoration: line-through;
      a {
        color: ${colors.danger};
      }
    }

    .history-list-item.added {
      color: ${colors.success};
      a {
        color: ${colors.success};
      }
    }
  }
`;

S.InspectionDetails = styled.div`
  background-color: ${colors.borderLight};
  border: 1px solid ${colors.border};

  .card__header-img {
    width: 100px;
  }
  .card_header-details {
    padding-left: 15px;

    h2 {
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${colors.border};
      padding-bottom: 4px;
    }
    .card_header-details b {
      font-size: 11px;
    }
  }
`;

export default S;
