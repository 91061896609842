import store from '../store';
import descriptions from '../constants/helpTextDescriptions';
import handbookDescriptions from '../constants/handbookNames';
import { omitBy, isObject, transform, isEqual, differenceWith } from 'lodash';
export * from './locals';

function getIdsFromObject(data) {
  let result = {};
  for (let x in data) {
    const item = data[x];
    if (Array.isArray(item)) {
      const list = item.map(i => typeof i === 'number' ? i : i.id);
      result = { ...result, [x]: list };
    } else if (item === null) {
      result = { ...result, [x]: null };
    } else if (typeof item === 'object') {
      result = { ...result, [x]: item.id };
    } else {
      result = { ...result, [x]: item };
    }
  }
  return result;
}

function allowOnlyLatin(str) {
  return str.replace(/[^A-Za-z0-9]/ig, '');
}

function toUpperCase(e) {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(start, end);
  return e;
}

function sort(a, b, field) {
  if (field) {
    return a[field]?.toLowerCase().localeCompare(b[field]?.toLowerCase().localeCompare());
  }
  return a?.toLowerCase().localeCompare(b?.toLowerCase().localeCompare());
}

function getDescription(name) {
  const lang = store.getState().auth?.user?.lang || 'uz';
  const description = descriptions[name] ? descriptions[name][lang] : null;
  if (description) {
    return description;
  }
  return '********** Missing ***********'
}

function getHandbookName(name) {
  const lang = store.getState().auth?.user?.lang || 'uz';
  const description = handbookDescriptions[name] ? handbookDescriptions[name][lang] : name;
  return description;
}

function getNotNullValues(obj) {
  const { vehicles: vehicleArr, participants: participantsArr, ...data } = obj;
  const getValidValues = a => a === undefined || a === null || a === '';
  const vehicles = vehicleArr?.map(item => {
    const participants = item.participants.map(i => omitBy(i, getValidValues));
    return omitBy({ ...item, participants }, getValidValues);
  });
  const participants = participantsArr?.map(i => omitBy(i, getValidValues));

  const result = omitBy({ ...data, vehicles, participants }, getValidValues);
  return result;
}

function indexById(arr = []) {
  let requestData = {};
  arr?.forEach(item => {
    requestData[item.id] = item;
  })
  return requestData;
}

function getIdsFromArrayOfObjects(arr) {
  let result = null;
  if (Array.isArray(arr)) {
    result = arr.map(item => ((typeof item === 'object') && item !== null) ? item.id : item);
  } else {
    result = (typeof arr === 'object') ? arr?.id ? arr.id : arr : arr;
  }
  return result;
}

function parseDataGettingObjectIds(data) {
  let obj = {};
  for (let x in data) {
    obj = { ...obj, [x]: getIdsFromArrayOfObjects(data[x]) }
  }
  return obj;
}

function allowNullValues(obj) {
  const { vehicles: vehicleArr, participants: participantsArr, ...data } = obj;
  const getValidValues = a => a === undefined;
  const vehicles = vehicleArr?.map(item => {
    const participants = item.participants.map(i => omitBy(i, getValidValues));
    return omitBy({ ...item, participants }, getValidValues);
  });
  const participants = participantsArr?.map(i => omitBy(i, getValidValues));
  return omitBy({ ...data, vehicles, participants }, getValidValues);
}

function setDefaultValue(event) {
  const { value, type } = event.target;
  if (value === undefined || value === null) {
    return type === 'number' ? 0 : '';
  }
  return value;
}

function getOptionsByFieldNameId(arr, field, id) {
  if (!Array.isArray(arr) || arr.length === 0) return [];
  if (!field || !id) return arr;
  const result = arr.filter(item => item[field] === id);
  return result
}

function getRoadListByFieldNameId(arr, street_significance_id, region_id = null) {
  const result = arr.filter(item => {
    if (item.street_significance == street_significance_id) {
      if (item.region === region_id || item.region === null) {
        return true
      }
    } else if (street_significance_id == 3 && region_id === null) {
      return true
    }
  });
  return result
}

const getIdIfObject = val => {
  if (Array.isArray(val)) {
    return val.map(item => {
      if (typeof item === 'number') {
        return item
      } else if (typeof item === 'object') {
        return item.id
      } else {
        return item;
      }
    })
  }
  return val?.id ? val.id : val;
};

function getObjectDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

function getArrayDiff(array, base) {
  return differenceWith(array, base, isEqual);
}

function getParticipantObjectDiff(newObj, obj) {
  let value = {};
  for (let x in newObj) {
    const excludedKeys = ['is_late', 'id', 'type', 'created_date', 'modified_date', 'card_number', 'created_by', 'inspectionDetails', 'driverDetails', 'tech_passport_series_and_number'];

    if (!excludedKeys.includes(x)) {
      const whiteList = ['accident_causal', 'attachments', 'licence_categories', 'files', 'technical_issues', 'violation'];
      if (whiteList.includes(x)) {
        if (Array.isArray(newObj[x])) {
          const sortedData = newObj[x].sort();
          if (JSON.stringify(sortedData) !== JSON.stringify(obj[x].sort())) {
            value = { ...value, [x]: newObj[x] };
          }
        } else {
          value = { ...value, [x]: newObj[x] }
        }
      } else {
        if (newObj[x] !== obj[x]) {
          value = { ...value, [x]: newObj[x] }
        }
      }
    }
  }

  return Object.keys(value).length ?
    { ...value, id: newObj.id }
    : { id: newObj.id, isEdited: false };
}

function getDiff(obj, base) {
  const { vehicles: vehicleArr = [], participants: participantsArr = [], ...data } = obj;
  const { vehicles: baseVehicle = [], participants: baseParticipants = [], ...baseData } = base;

  const vehicles = vehicleArr?.map(item => {
    const isObjectExist = baseVehicle.find(i => i.id === item.id);
    if (!isObjectExist) {
      return item;
    } else {
      const currentParticipants = isObjectExist.participants || [];
      const newParticipants = item.participants || [];
      const participants = newParticipants.map(item => {
        const oldParticipant = currentParticipants.find(i => i.id === item.id);

        if (!oldParticipant) {
          return item;
        }
        return getParticipantObjectDiff(item, oldParticipant);
      });
      return { ...getParticipantObjectDiff(item, isObjectExist), participants }
    }
  });

  const participants = participantsArr?.map(item => {
    const oldParticipant = baseParticipants.find(i => i.id === item.id);
    if (!oldParticipant) {
      return item;
    } else {
      return getParticipantObjectDiff(item, oldParticipant);
    }
  });
  const validParticipants = (vehicles.length && participants.length) ? { vehicles, participants } : (participants.length ? { participants } : (vehicles.length ? { vehicles } : {}));
  // Check for empty values 
  return { ...getParticipantObjectDiff(data, baseData), ...validParticipants }
}

function isAdmin() {
  return store.getState().auth.user?.is_superuser;
}

function isReadOnlyUser() {
  return store.getState().auth.user?.read_only;
}

function filterParams(filters) {
  let data = "";
  for (let x in filters) {
    if (Array.isArray(filters[x]) && filters[x].length) {
      data += `&${x}=${filters[x].join(',')}`;
    } else if (!Array.isArray(filters[x]) && filters[x]) {
      data += `&${x}=${filters[x]}`;
    }
  }
  return data;
};

function paramsToObject(parmas) {
  const arr = parmas.split('&');
  let paramObject = {};
  arr.forEach(item => {
    if (item) {
      const [key, value] = item.split('=');
      paramObject[key] = value;
    }
  });
  return paramObject;
}

export {
  allowNullValues,
  filterParams,
  getArrayDiff,
  getDescription,
  getHandbookName,
  getIdIfObject,
  getIdsFromArrayOfObjects,
  getIdsFromObject,
  getNotNullValues,
  getObjectDiff,
  getDiff,
  getOptionsByFieldNameId,
  indexById,
  paramsToObject,
  parseDataGettingObjectIds,
  setDefaultValue,
  sort,
  allowOnlyLatin,
  isAdmin,
  isReadOnlyUser,
  toUpperCase,
  getRoadListByFieldNameId,
}