import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { t, getHandbookName, getDescription } from '../../utils';
import { Form, Input, Button, Select, Row, Col, Tooltip, Checkbox, Spin, message, Radio, Divider, } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { BiGroup } from 'react-icons/bi';
import { Spinner } from '../../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as QuestionMark } from '../../assets/images/card/info.svg';
import InputMask from "react-input-mask";


const { Option } = Select;

export default function PersonalInfo({ data }) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const myAccount = useSelector(state => state.auth.user || {});
  const { id, lang, first_name, last_name, email, phone, region, district, rank, middle_name, read_only, } = data;
  const { regions, districts } = useSelector(state => state.handbooks || {});
  const history = useHistory();

  const [user, setUser] = useState({
    district,
    email,
    first_name,
    middle_name,
    groups: data.groups,
    last_name,
    phone,
    rank,
    region,
    read_only,
  });

  const handleInput = event => {
    const { value, name } = event.target;
    const defaultValue = value ? value : '';
    setUser(user => ({ ...user, [name]: defaultValue }));
  }

  const handleSelect = (name, value) => {
    const defaultValue = value ? value : null;
    if (name === 'region') {
      setUser(user => ({ ...user, [name]: defaultValue, district: null }));
    } else {
      setUser(user => ({ ...user, [name]: defaultValue }));
    }
  }

  const handleChange = useCallback((event) => {
    let { value, checked } = event.target;
    if (checked) {
      setUser(state => ({ ...state, groups: [value] }));
    }
  }, [user]);


  useEffect(() => {
    (async () => {
      try {
        setGroupLoading(true);
        const { data } = await axios.get('/auth/groups/?page_size=100');
        setGroups(data.results);
        setGroupLoading(false);
      }
      catch (err) {
        console.error(err);
      }
    })()
  }, []);

  const handleSave = async (event) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          let request = {}
          for (let x in user) {
            if ((user[x] || user[x] === false) && user[x] !== data[x]) {
              request = { ...request, [x]: user[x] }
            } else if (!user[x] && user[x] !== data[x]) {
              request = { ...request, [x]: null }
            }
          }

          if (Object.keys(request).length) {
            const { data } = await axios.patch(`/auth/users/${id}/`, { ...request, groups: user.groups });
            message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
            history.push('/users');
          } else {
            message.warning(t("Yangilanadigan ma'lumot yo'q."))
          }

        }
        catch (err) {
          const { data = {} } = err.response;
          let messages = [];
          for (let x in data) {
            messages.push(<li key={x}>{getHandbookName(x) ?? x}: {data[x].join(', ')}</li>)
          }
          return message.error(messages, 7);
        }
      }

    })
  }

  const filteredDistricts = useMemo(() => user.region ? districts?.filter(item => item.region === user.region) : districts, [myAccount]);
  const hasError = user.phone?.endsWith('_');

  return (
    <div>
      <h2>{t("Shaxsiy ma'lumotlar")}</h2>
      <Form
        name="control-ref"
        onFinish={handleSave}
        className="my-4"
        initialValues={user}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  // required: true,
                  message: "To'ldiring"
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Ism")}</label>
              <Input
                placeholder={t("Ism")}
                name="first_name"
                onChange={handleInput}
                value={user.first_name}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Familiya")}</label>
              <Input
                placeholder={t("Ism")}
                name="last_name"
                onChange={handleInput}
                value={user.last_name}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="middle_name"
              rules={[
                {
                  // required: true,
                  message: "Ma'lumot kiritish majburiy",
                },
              ]}
            >
              <label htmlFor="middle_name"> {t("Otasining ismi")}</label>
              <Input
                placeholder={t("Otasining ismi")}
                autoComplete="off"
                name="middle_name"
                onChange={handleInput}
                value={user.middle_name}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="rank"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="rank"> {t("Unvoni")}</label>
              <Input
                placeholder={t("Unvoni")}
                name="rank"
                onChange={handleInput}
                value={user.rank}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Email")}</label>
              <Input
                placeholder={t("Email")}
                name="email"
                onChange={handleInput}
                value={user.email}
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              validateStatus={hasError ? 'error' : 'success'}
              rules={[
                {
                  required: user.phone ? user.phone.length < 12 : true,
                  message: t("Quydagi format bo'yicha to'ldiring: +998123456789"),
                  min: 13,
                  max: 13
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Telefon")}</label>
              <InputMask
                placeholder="+998 12 345 67 89"
                mask="+\9\98999999999"
                name="phone"
                value={user.phone}
                onChange={handleInput}
                className={`input-controller focusable mt-2 ${hasError ? 'ant-input-affix-wrapper' : ''}`}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="region"
              rules={[
                {
                  required: false,
                  message: 'something'
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Viloyat")}</label>
              <Select
                showSearch
                placeholder={t('Viloyat')}
                onChange={value => handleSelect('region', value)}
                name={'region'}
                showAction={["focus", "click"]}
                value={user.region}
                allowClear
                className="custom-select-content focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                disabled={!myAccount.is_superuser && !user.is_superuser}
              >
                {
                  regions.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              rules={[
                {
                  required: false,
                  message: 'something'
                },
              ]}
            >
              <label htmlFor="first_name"> {t("Tuman")}</label>
              <Select
                showSearch
                placeholder={t('Tuman')}
                onChange={value => handleSelect('district', value)}
                name={'district'}
                showAction={["focus", "click"]}
                value={user.district}
                allowClear
                className="custom-select-content focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                notFoundContent={loading ? <Spin size="small" /> : null}
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {
                  filteredDistricts?.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="read_only"
              label={null}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox
                name="read_only"
                id={"read_only"}
                checked={user.read_only}
                onChange={e => setUser(state => ({ ...state, read_only: !state.read_only }))}
              >
                <label htmlFor={"read_only"} className="clickable">
                  {t("Cheklangan foydalanuvchi")}
                  <Tooltip placement="right" title={getDescription('read_only')} trigger={['click', 'hover']}>
                    <QuestionMark className="clickable" />
                  </Tooltip>
                </label>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Col span={24} className="mb-4">
          <Divider />
          <h2 style={{ color: colors.success }}><BiGroup /> {t('Guruhlar')}</h2>
          {
            !groupLoading ?
              <Radio.Group size="large" value={user.groups[0]} onChange={handleChange}>
                {
                  groups.map(item => {
                    return <Radio key={item.id} value={item.id}>{item.name}</Radio>
                  })
                }
              </Radio.Group>
              :
              <Spinner loading={groupLoading} />
          }
          <Divider />
        </Col>

        <Button
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={hasError}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </div >
  )
}
