const crosswalkLocations = [
  {
    id: "1.            ",
    streetName: "Бокинская кўчаси презд қаршисида",
    lineCount: 4,
    location: "41.289896,69.312706",
  },
  {
    id: "2.      ",
    streetName: "Оханграбо кўчаси 2-уй қаршисида",
    lineCount: 4,
    location: "41.248495,69.329528",
  },
  {
    id: "3.      ",
    streetName: "Оханграбо кўчаси 15-уй қаршисида ",
    lineCount: 4,
    location: "41.248893,69.331689",
  },
  {
    id: "4.      ",
    streetName: "Джаркўргон кўчаси МОЯ МЕЧТА заводи қаршисида",
    lineCount: 4,
    location: "41.272055,69.330890",
  },
  {
    id: "5.      ",
    streetName: "Асалобод кўчаси 5-уй қаршисида",
    lineCount: 4,
    location: "41.281673,69.337912",
  },
  {
    id: "6.      ",
    streetName: "Иззат кўчаси МТМ қаршисида",
    lineCount: 4,
    location: "41.284489,69.339308",
  },
  {
    id: "7.      ",
    streetName: "Иззат кўчаси Меҳрибонлик уйи қаршисида",
    lineCount: 4,
    location: "41.285510,69.338829",
  },
  {
    id: "8.      ",
    streetName: "Иззат кўчаси Тез ёрдам постанцияси қаршисида",
    lineCount: 4,
    location: "41.281724,69.340359",
  },
  {
    id: "9.      ",
    streetName: "Иззат кўчаси Яшнобод сув соз қаршиси",
    lineCount: 4,
    location: "41.275539,69.342774",
  },
  {
    id: "10.            ",
    streetName: "Иззат кўчаси 69-уй қаршисида",
    lineCount: 4,
    location: "41.271377,69.343860",
  },
  {
    id: "11.            ",
    streetName: "Ш.Мардон-Занжирсорой кўчалар кесишмаси",
    lineCount: 4,
    location: "41.273419,69.346856",
  },
  {
    id: "12.            ",
    streetName: "Ш.Мардон-Занжирсорой кўчалар кесишмаси",
    lineCount: 4,
    location: "41.273456,69.346915",
  },
  {
    id: "13.            ",
    streetName: "Ш.Мардон-Мурабий кўчалар кесишмаси",
    lineCount: 4,
    location: "41.274769,69.357164",
  },
  {
    id: "14.            ",
    streetName: "Зумрад –Хуснобод кўчалар кесишмаси",
    lineCount: 2,
    location: "41.276189,69.353454",
  },
  {
    id: "15.            ",
    streetName: "Хосият кўчаси 69-уй қаршисида",
    lineCount: 4,
    location: "41.279207,69.342364",
  },
  {
    id: "16.            ",
    streetName: "Авиасозлар кўчаси “Авиасозлар” дворец қаршисида",
    lineCount: 4,
    location: "41.291419,69.341973",
  },
  {
    id: "17.            ",
    streetName: "Авиасозлар-Дубовий кўчалар кесишмаси",
    lineCount: 4,
    location: "41.294302,69.33681",
  },
  {
    id: "18.            ",
    streetName: "Бешариқ кўчаси 226-мактаб қаршисида",
    lineCount: 4,
    location: "41.284583,69.342702",
  },
  {
    id: "19.            ",
    streetName: "Бешариқ кўчаси 41-уй қаршисида ",
    lineCount: 4,
    location: "41.288795,69.352793",
  },
  {
    id: "20.            ",
    streetName: "Бешариқ кўчаси “Антей” дўкони қаршисида",
    lineCount: 4,
    location: "41.290632,69.356979",
  },
  {
    id: "21.            ",
    streetName: "Бирлашган кўчаси Яшнобод РАЙ газ қаршисида",
    lineCount: 4,
    location: "41.291143,69.351751",
  },
  {
    id: "22.            ",
    streetName: "Бешариқ кўчаси Кадишева бозори қаршисида",
    lineCount: 4,
    location: "41.286832,69.348040",
  },
  {
    id: "23.            ",
    streetName: "Чўлпон кўчаси “Мойка” қаршисида ",
    lineCount: 4,
    location: "41.296005,69.362980",
  },
  {
    id: "24.            ",
    streetName: "Чўлпон кўчаси Мачит қаршисида",
    lineCount: 4,
    location: "41.296493,69.364166",
  },
  {
    id: "25.            ",
    streetName: "Чўлпон кўчаси “Чўлпон” блок пўсти қаршисида",
    lineCount: 4,
    location: "41.297400,69.366162",
  },
  {
    id: "26.            ",
    streetName: "М.Улугбек кўчаси “Боткино” қаршисида",
    lineCount: 8,
    location: "41.307584,69.310073",
  },
  {
    id: "27.            ",
    streetName: "Охонгорон шох – Алиева кўчалар кесишмаси",
    lineCount: 8,
    location: "41.276308,69.360999",
  },
  {
    id: "28.            ",
    streetName: "ТХАЙ кўчаси Миллий гвардия қаршисида",
    lineCount: 8,
    location: "41.255455,69.358492",
  },
  {
    id: "29.            ",
    streetName: "ТХАЙ кўчаси Бетон МАХ қаршисида",
    lineCount: 8,
    location: "41.246206,69.346670",
  },
  {
    id: "30.            ",
    streetName: "Махтумкули кўчаси 1-уй қаршисида",
    lineCount: 8,
    location: "41.303442,69.316700",
  },
  {
    id: "31.            ",
    streetName: "Махтумкули кўчаси АВТОРИТЕТ дўкони қаршисида",
    lineCount: 8,
    location: "41.303905,69.319085",
  },
  {
    id: "32.            ",
    streetName: "Махтумкули кўчаси АЗС “ФАТХ ОИЛ” қаршисида",
    lineCount: 8,
    location: "41.304092,69.312571",
  },
  {
    id: "33.            ",
    streetName: "Махтумкули кўчаси Амир Темур ҳиёбонига чиқишда",
    lineCount: 8,
    location: "41.310586,69.281809",
  },
  {
    id: "34.            ",
    streetName: "Махтумкули кўчаси айланма харакати",
    lineCount: 2,
    location: "41.310586,69.281809",
  },
  {
    id: "35.            ",
    streetName: "Махтумкули кўчаси айланма харакати",
    lineCount: 2,
    location: "41.305791,69.338983",
  },
  {
    id: "36.            ",
    streetName: "Махтумкули кўчаси айланма харакати",
    lineCount: 2,
    location: "41.305791,69.338983",
  },
  {
    id: "37.            ",
    streetName: "Элбек кўчаси Чкалов метроси бекати қаршисида",
    lineCount: 4,
    location: "41.294055,69.323777",
  },
  {
    id: "38.            ",
    streetName: "Алимкент кўчаси 153-мактаб қаршисида",
    lineCount: 6,
    location: "41.290819,69.328167",
  },
  {
    id: "39.            ",
    streetName: "Алимкент кўчаси айланма харакат ж/д переезд ",
    lineCount: 6,
    location: "41.283625,69.333716",
  },
  {
    id: "40.            ",
    streetName: "Алимкент кўчаси айланма харакат ж/д переезд ",
    lineCount: 6,
    location: "41.283221,69.334186",
  },
  {
    id: "41.            ",
    streetName: "Корасув кўчаси мачит қаршисида",
    lineCount: 8,
    location: "41.289485,69.338585",
  },
  {
    id: "42.            ",
    streetName: "Аслобод кўчаси 5-уй қаршисида",
    lineCount: 4,
    location: "41.281604,69.337897",
  },
  {
    id: "43.            ",
    streetName: "С.Азимова кўчаси 145-мактаб қаршисида",
    lineCount: 6,
    location: "41.304095,69.288695",
  },
  {
    id: "44.            ",
    streetName: "Истиклол -С.Барака кўчаси ",
    lineCount: 6,
    location: "41.301452,69.289222",
  },
  {
    id: "45.            ",
    streetName: "Охангарон шох Гўзал мфйга кириш",
    lineCount: 8,
    location: "41.276697,69.361543",
  },
  {
    id: "46.            ",
    streetName: "Охангарон шох Сеул қаршисида ",
    lineCount: 8,
    location: "41.281107,69.360903",
  },
  {
    id: "47.            ",
    streetName: "Охангарон шох 22б уй қаршиси",
    lineCount: 8,
    location: "41.288546,69.359583",
  },
  {
    id: "48.            ",
    streetName: "Охангарон шох яшнобод экобозор қаршиси",
    lineCount: 8,
    location: "41.290774,69.358034",
  },
  {
    id: "1.                  ",
    streetName: "М.Улуғбек кўчаси 47- уй қаршисида",
    lineCount: 8,
    location: "41.322925,69.322758",
  },
  {
    id: "2.                  ",
    streetName: "М.Улуғбек кўчаси “ЭКО Бозор” қаршисида",
    lineCount: 8,
    location: "41.353260,69.354823",
  },
  {
    id: "3.                  ",
    streetName: "М. Улуғбек кўчаси Гиникология институти қаршисида",
    lineCount: 6,
    location: "41.356696,69.358640",
  },
  {
    id: "4.                  ",
    streetName: "Феруза кўчаси 205-мактаб қаршисида",
    lineCount: 4,
    location: "41.357444,69.367870",
  },
  {
    id: "5.                  ",
    streetName: "Юзробод кўчаси “Кафе Ануш” қаршисида",
    lineCount: 8,
    location: "41.354403,69.373179",
  },
  {
    id: "6.                  ",
    streetName: "Юзробод кўчаси “Локаматив” қаршисида",
    lineCount: 8,
    location: "41.363065,69.392552",
  },
  {
    id: "7.                  ",
    streetName: "Бий-зайтун кўчалар кесишмаси ",
    lineCount: 4,
    location: "41.330538,69.347969",
  },
  {
    id: "8.                  ",
    streetName: "Бий кўчаси 301-интернат қаршисида",
    lineCount: 4,
    location: "41.328818,69.345441",
  },
  {
    id: "9.                  ",
    streetName: "Бий-Жасорат кўчалар кесишмаси",
    lineCount: 4,
    location: "41.341476,69.360004",
  },
  {
    id: "10.             ",
    streetName: "Бий “Агро” мактаби қаршисида",
    lineCount: 4,
    location: "41.348794,69.367822",
  },
  {
    id: "11.             ",
    streetName: "Бий кўчаси “Тўра-Бува” мачити қаршисида",
    lineCount: 4,
    location: "41.350177,69.376684",
  },
  {
    id: "12.             ",
    streetName: "Паркент кўчаси 69-мактаб қаршисида",
    lineCount: 8,
    location: "41.317951,69.314123",
  },
  {
    id: "13.             ",
    streetName: "Олтинтепа-5-тор Олтинтепа кўчалар кесишмаси",
    lineCount: 4,
    location: "41.314509,69.334602",
  },
  {
    id: "14.             ",
    streetName: "Олтинтепа кўчаси 112-мактаб қаршисида",
    lineCount: 4,
    location: "41.322576,69.345790",
  },
  {
    id: "15.             ",
    streetName: "Олтинтепа-Сирдарё кўчалар кесишмаси",
    lineCount: 4,
    location: "41.326178,69.348884",
  },
  {
    id: "16.             ",
    streetName: "Газалкент кўчаси СНБ биноси қаршисида",
    lineCount: 4,
    location: "41.327693,69.353586",
  },
  {
    id: "17.             ",
    streetName: "Газалкент-Гулсара кўчалар кесишмаси",
    lineCount: 4,
    location: "41.338639,69.363385",
  },
  {
    id: "18.             ",
    streetName: "Газалкент – Интизор кўчалар кесишмаси",
    lineCount: 4,
    location: "41.333600,69.358867",
  },
  {
    id: "19.             ",
    streetName: "Ш.Бурханов-Суфизода кўчалар кесишмаси",
    lineCount: 4,
    location: "41.340218,69.351190",
  },
  {
    id: "20.             ",
    streetName: "Неъмат кўчаси котельния қаршисида",
    lineCount: 6,
    location: "41.355028,69.341368",
  },
  {
    id: "21.             ",
    streetName: "Ҳуросон кўчаси Ядир физика институти қаршисида",
    lineCount: 4,
    location: "41.406189,69.458881",
  },
  {
    id: "22.             ",
    streetName: "К.Ниёзий кўчаси МТМ қаршисида",
    lineCount: 4,
    location: "41.319661,69.294175",
  },
  {
    id: "23.             ",
    streetName: "Ниёзбек йўли кўчаси Интирхаус коледжи қаршисида",
    lineCount: 4,
    location: "41.323418,69.292508",
  },
  {
    id: "24.             ",
    streetName: "Оккурғон кўчаси 99-мактаб қаршисида",
    lineCount: 4,
    location: "41.323418,69.292508",
  },
  {
    id: "25.             ",
    streetName: "Оккурган-Катта дархон кўчалар кесишмаси",
    lineCount: 4,
    location: "41.330683,69.305974",
  },
  {
    id: "26.             ",
    streetName: "Оккургон кўчаси “Блек-бир” кафеси қаршисида",
    lineCount: 6,
    location: "41.330001,69.305515",
  },
  {
    id: "27.             ",
    streetName: "Осиё кўчаси 142-мактаб қаршисида",
    lineCount: 6,
    location: "41.325072,69.302373",
  },
  {
    id: "28.             ",
    streetName: "Юзработ  кўчаси 35-мактаб қаршисида",
    lineCount: 4,
    location: "41.361872,69.391470",
  },
  {
    id: "29.             ",
    streetName: "Гулсанам кўчаси “Навруз” бозори қаршисида",
    lineCount: 4,
    location: "41.354493,69.387761",
  },
  {
    id: "30.             ",
    streetName: "Зиёлилар кўчаси ИНХА институти қаршисида ",
    lineCount: 8,
    location: "41.338564,69.333978",
  },
  {
    id: "31.             ",
    streetName: "МКАД “КРАФЕРС” фабрикаси қаршисида",
    lineCount: 8,
    location: "41.334504,69.308057",
  },
  {
    id: "32.             ",
    streetName: "Мингбулоқ-Ш.Бурхонов кўчалар кесишмаси",
    lineCount: 4,
    location: "41.331287,69.365322",
  },
  {
    id: "33.             ",
    streetName: "Сайрам –Ясий кўчалар кесишмаси",
    lineCount: 6,
    location: "41.334834,69.323921",
  },
  {
    id: "34.             ",
    streetName: "Сайрам – 1-тор Сайрам кўчалар кесишмаси",
    lineCount: 6,
    location: "41.332688,69.324407",
  },
  {
    id: "35.             ",
    streetName: "5-тор Сайрам-7-тор Сайрам кўчалар кесишмаси",
    lineCount: 4,
    location: "41.329469,69.324877",
  },
  {
    id: "36.             ",
    streetName: "5-тор Сайрам кўчаси Англия мактаби қаршисида",
    lineCount: 4,
    location: "41.329303,69.324383",
  },
  {
    id: "37.             ",
    streetName: "7-тор Сайрам кўчаси 1-уй қаршисида",
    lineCount: 4,
    location: "41.324565,69.317257",
  },
  {
    id: "38.             ",
    streetName: "Дўрмон йўли кўчаси Нефти и газа им.Губкина қаршисида",
    lineCount: 8,
    location: "41.335458,69.344124",
  },
  {
    id: "39.             ",
    streetName: "Дўрмон йўли –Навнихол кўчалар кесишмасида ",
    lineCount: 6,
    location: "41.344399,69.329589",
  },
  {
    id: "40.             ",
    streetName: "Хумойин кўчаси масифга киришда ",
    lineCount: 4,
    location: "41.344487,69.385838",
  },
  {
    id: "41.             ",
    streetName: "Риштон  кўчаси 222-мактаб қаршисида ",
    lineCount: 2,
    location: "41.322898,69.354255",
  },
  {
    id: "42.             ",
    streetName: "Салом кўчаси 241-мактаб қаршисида ",
    lineCount: 2,
    location: "41.327267,69.343953",
  },
  {
    id: "43.             ",
    streetName: "М проезд Т. Малик кўчаси Тош обл УБДД қаршисида ",
    lineCount: 2,
    location: "41.314347,69.351051",
  },
  {
    id: "44.             ",
    streetName: "Т.Малик кўчаси Тош обл УБДД қаршисида ",
    lineCount: 10,
    location: "41.314351,69.351899",
  },
  {
    id: "45.             ",
    streetName: "Т.Малик кўчаси Авайхон махалласи қаршисида",
    lineCount: 10,
    location: "41.315692,69.354379",
  },
  {
    id: "46.             ",
    streetName: "Т.Малик кўчаси “Геофизика” қаршисида ",
    lineCount: 8,
    location: "41.338830,69.384561",
  },
  {
    id: "47.             ",
    streetName: "Т.Малик кўчаси Шайх-Зайвудин мачити қаршисида",
    lineCount: 8,
    location: "41.349465,69.354341",
  },
  {
    id: "48.             ",
    streetName: "Т.Малик кўчаси Шайх-Зайвудин мачити қаршисида",
    lineCount: 8,
    location: "41.349928,69.353635",
  },
  {
    id: "49.             ",
    streetName: "Т.Малик кўчаси Локомотив парки қаршисида",
    lineCount: 6,
    location: "41.355206,69.344812",
  },
  {
    id: "50.             ",
    streetName: "Асака кўчаси 18-мактаб қаршисида",
    lineCount: 2,
    location: "41.317020,69.298110",
  },
  {
    id: "51.             ",
    streetName: "Асака-Исмойили кўчалар кесишмаси",
    lineCount: 4,
    location: "41.316151,69.303413",
  },
  {
    id: "52.             ",
    streetName: "Исмойилий кўчаси шахсий МТМ қаршисида",
    lineCount: 2,
    location: "41.318005,69.304073",
  },
  {
    id: "53.             ",
    streetName: "Гулсара – Уқитувчи кўчалар кесишмаси",
    lineCount: 4,
    location: "41.337109,69.366182",
  },
  {
    id: "54.             ",
    streetName: "Каланлдар кўчаси 225-мактаб қаршисида ",
    lineCount: 4,
    location: "41.331664,69.326950",
  },
  {
    id: "55.             ",
    streetName: "Каландар кўчаси Ҳалқ қабулхонаси қаршисида",
    lineCount: 4,
    location: "41.333556,69.328939",
  },
  {
    id: "56.             ",
    streetName: "Мусавилар кўчаси 332-МТМ қаршисида",
    lineCount: 4,
    location: "41.330539,69.327479",
  },
  {
    id: "57.             ",
    streetName: "Я.Гулом кўчаси МТМ қаршисида",
    lineCount: 4,
    location: "41.312273,69.289277",
  },
  {
    id: "58.             ",
    streetName: "Я.Гулом – Ўзбекистон овози кўчалар кесишмаси",
    lineCount: 4,
    location: "41.311850,69.289421",
  },
  {
    id: "59.             ",
    streetName: "Я.Гулом – Ўзбекистон овози кўчалар кесишмаси",
    lineCount: 4,
    location: "41.311773,69.289242",
  },
  {
    id: "60.             ",
    streetName: "Ўзбекистон овози кўчаси 64-мактаби қаршисида",
    lineCount: 4,
    location: "41.311937,69.292801",
  },
  {
    id: "61.             ",
    streetName: "Ўзбекистон овози кўчаси ЭКО парк қаршисида",
    lineCount: 4,
    location: "41.312247,69.293942",
  },
  {
    id: "62.             ",
    streetName: "К.Дархон-проезд К.Дархон кўчалар кесишмаси",
    lineCount: 4,
    location: "41.331236,69.309417",
  },
  {
    id: "63.             ",
    streetName: "Шахрисабз-Олой кўчалар кесишмаси",
    lineCount: 10,
    location: "41.315768,69.284138",
  },
  {
    id: "64.             ",
    streetName: "Феруза-Пила  кўчалар кесишмаси ",
    lineCount: 8,
    location: "41.354388,69.373210",
  },
  {
    id: "65.            Сас",
    streetName: "Сайрам 7-тор кўчаси Давлат статистика қумитаси қаршиси",
    lineCount: 4,
    location: "41.324284,69.317391",
  },
  {
    id: "1.      ",
    streetName: "Оҳангарон шоҳ кўчаси “Голф клуб” қаршисида",
    lineCount: 8,
    location: "41.255715,69.382028",
  },
  {
    id: "2.      ",
    streetName: "Оҳангарон шоҳ “Шарк” йпх масканига йетмасдан",
    lineCount: 8,
    location: "41.249273,69.390242",
  },
  {
    id: "3.      ",
    streetName: "Х.Байқаро кўчаси 28А-уй қаршисида",
    lineCount: 4,
    location: "41.253573,69.373217",
  },
  {
    id: "4.      ",
    streetName: "Х.Байқаро кўчаси ИИВ ШПП қаршисида",
    lineCount: 4,
    location: "41.252530,69.371695",
  },
  {
    id: "5.      ",
    streetName: "Рохат кўчаси Зилола махалласига кириш жойи",
    lineCount: 4,
    location: "41.249334,69.372596",
  },
  {
    id: "6.      ",
    streetName: "Бархаёт кўчаси Телевидения ва Радио колледжи қаршисида",
    lineCount: 2,
    location: "41.247406,69.375010",
  },
  {
    id: "7.      ",
    streetName: "Х.Байқаро кўчаси МТМ қаршисида",
    lineCount: 4,
    location: "41.249530,69.367031",
  },
  {
    id: "8.      ",
    streetName: "Х.Байқаро кўчаси Солиқ департаменти қаршисида",
    lineCount: 4,
    location: "41.249530,69.367031",
  },
  {
    id: "9.      ",
    streetName: "Х.Байқаро кўчаси “Сувмаш) қаршисида",
    lineCount: 4,
    location: "41.239379,69.346840",
  },
  {
    id: "10.            ",
    streetName: "Х.Байқаро кўчаси Бектемир хокимияти қаршисида",
    lineCount: 4,
    location: "41.234698,69.337642",
  },
  {
    id: "11.            ",
    streetName: "Бектемир шоҳ кўчаси 8-уй қаршисида",
    lineCount: 4,
    location: "41.232019,69.335518",
  },
  {
    id: "12.            ",
    streetName: "Бектемир шоҳ кўчаси Мачит қаршисида",
    lineCount: 4,
    location: "41.228618,69.335837",
  },
  {
    id: "13.            ",
    streetName: "Ю.Чирчиқ кўчаси Бектемир ИИБ қаршисида",
    lineCount: 2,
    location: "41.237376,69.353052",
  },
  {
    id: "14.            ",
    streetName: "Ю.Чирчиқ кўчаси 290-мактаб қаршисида",
    lineCount: 2,
    location: "41.235160,69.350412",
  },
  {
    id: "15.            ",
    streetName: "ТХАЙ кўчаси Деҳқон оптовй бозор қаршисида",
    lineCount: 4,
    location: "41.236928,69.325166",
  },
  {
    id: "16.            ",
    streetName: "ТХАЙ кўчаси Фарғона такси автотураргохи қаршиисда",
    lineCount: 6,
    location: "41.243918,69.338986",
  },
  {
    id: "17.           ",
    streetName: "4Р2 кўчаси “Ўзбекинвест” қаршиси",
    lineCount: 8,
    location: "41.223857,69.334185",
  },
  {
    id: "18.            ",
    streetName: "4Р2 кўчаси “Халқ қабулхонаси” қаршиси",
    lineCount: 8,
    location: "41.211856,69.334310",
  },
  {
    id: "19.            ",
    streetName: "4Р21 кўчаси Автомойка қаршиси",
    lineCount: 8,
    location: "41.216033,69.351303",
  },
  {
    id: "20.            ",
    streetName: "4Р21 кўчаси 23-мактаб қаршиси",
    lineCount: 8,
    location: "41.225671,69.366197",
  },
  {
    id: "1.                  ",
    streetName: "Кичик Бешёғоч-Андижон кўчалари кесишмасида",
    lineCount: 2,
    location: "41.28861,69.267729",
  },
  {
    id: "2.                  ",
    streetName: "КХАЙ-Темир йўл кесишмаси қаршисида",
    lineCount: 8,
    location: "41.26314569.233874",
  },
  {
    id: "3.                  ",
    streetName: "Қушбеги массиви №358-МТМ қаршисида",
    lineCount: 2,
    location: "41.267611,69.24104",
  },
  {
    id: "4.                  ",
    streetName: "Бошлиқ мавзеси №127-мактаб қаршисида",
    lineCount: 2,
    location: "41.267569,69.250944",
  },
  {
    id: "5.                  ",
    streetName: "Қушбеги массиви №319-Мактаб қаршисида",
    lineCount: 2,
    location: "41.267301,69.237388",
  },
  {
    id: "6.                  ",
    streetName: "Қушбеги кўчаси “Зиғир ош” қаршисида",
    lineCount: 4,
    location: "41.272320,69.245509",
  },
  {
    id: "7.                  ",
    streetName: "Қушбеги-Ш.Руставели  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.276965,69.244788",
  },
  {
    id: "8.                  ",
    streetName: "Ш.Руставели-“Азия Ташкент” мехмонхонаси",
    lineCount: 8,
    location: "41.262744,69.228629",
  },
  {
    id: "9.                  ",
    streetName: "Ш.Руставели-№59-Оилавий поликлиника қаршиси",
    lineCount: 8,
    location: "41.271189,69.237943",
  },
  {
    id: "10.             ",
    streetName: "Ш.Руставели-№104-уй қаршиси",
    lineCount: 8,
    location: "41.272672,69.23957",
  },
  {
    id: "11.             ",
    streetName: "Ш.Руставели-А.Қаххор  кўчалари кесишмасида",
    lineCount: 8,
    location: "41.286746,69.255273",
  },
  {
    id: "12.             ",
    streetName: "Муқимий кўчаси-№52-уй қаршисида",
    lineCount: 10,
    location: "41.281115,69.240546",
  },
  {
    id: "13.             ",
    streetName: "С.Сирожиддинов кўчаси-№144-мактиб қаршиси",
    lineCount: 2,
    location: "41.281715,69.236709",
  },
  {
    id: "14.             ",
    streetName: "Шохжахон-Ўрикзор  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.283673,69.241781",
  },
  {
    id: "15.             ",
    streetName: "Шохжахон-Аския  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.285976,69.250629",
  },
  {
    id: "16.             ",
    streetName: "Шохжахон кўчаси-№11-уй қаршисида",
    lineCount: 2,
    location: "41.283961,69.243087",
  },
  {
    id: "17.             ",
    streetName: "Шохжахон кўчаси-№89-Мактаб қаршисида",
    lineCount: 2,
    location: "41.286198,69.251766",
  },
  {
    id: "18.             ",
    streetName: "Ракат кўчаси №135-мактаб қаршисида",
    lineCount: 2,
    location: "41.286707,69.241742",
  },
  {
    id: "19.             ",
    streetName: "Ракатбоши-Шаршара кўчалари кесишмасида",
    lineCount: 2,
    location: "41.300184,69.256084",
  },
  {
    id: "20.             ",
    streetName: "Ракатбоши кўчаси №250-МТМ қаршисида",
    lineCount: 2,
    location: "41.301859,69.260428",
  },
  {
    id: "21.             ",
    streetName: "Ракатбоши-Шаршара кўчалари кесишмасида",
    lineCount: 2,
    location: "41.300184,69.256084",
  },
  {
    id: "22.             ",
    streetName: "Ат.Термизий-Белариқ кўчалари кесишмасида",
    lineCount: 4,
    location: "41.288387,69.242676",
  },
  {
    id: "23.             ",
    streetName: "Ат.Термизий-№63-уй қаршисида",
    lineCount: 4,
    location: "41.289108,69.242396",
  },
  {
    id: "24.             ",
    streetName: "Ат.Термизий-А.Авлоний  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.289495,69.242214",
  },
  {
    id: "25.             ",
    streetName: "Ўрикзор-Шохжахон  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.283673,69.241781",
  },
  {
    id: "26.             ",
    streetName: "Ўрикзор-№128-уй қаршисида",
    lineCount: 4,
    location: "41.293454,69.24592",
  },
  {
    id: "27.             ",
    streetName: "Ўрикзор-Аския  кўчалари кесишмасида",
    lineCount: 4,
    location: "41.293175,69.2479",
  },
  {
    id: 28,
    streetName: "Бобур-А.Авлоний кўчалари кесишмасида",
    lineCount: 8,
    location: "41.291815,69.250747",
  },
  {
    id: "29.             ",
    streetName: "Бобур кўчаси -“Дўслик боғи” қаршисида",
    lineCount: 10,
    location: "41.289142,69.251916",
  },
  {
    id: "30.             ",
    streetName: "Бобур кўчаси Яккасарой ТИИБ биноси қаршисида",
    lineCount: 8,
    location: "41.273335,69.258944",
  },
  {
    id: "31.             ",
    streetName: "Бобур кўчаси №46/1-уй қаршисида",
    lineCount: 8,
    location: "41.276721,69.257483",
  },
  {
    id: "32.             ",
    streetName: "Бобур-3-тор-Ўрикзор кўчалари кесишмасида",
    lineCount: 8,
    location: "41.293484,69.246105",
  },
  {
    id: "33.             ",
    streetName: "Бобур-Шаршара кўчалари кесишмасида",
    lineCount: 2,
    location: "41.299982,69.249101",
  },
  {
    id: "34.             ",
    streetName: "Глинка кўчаси №118-мактаб қаршисида 2 та жойда",
    lineCount: 6,
    location: "41.288017,69.259382",
  },
  {
    id: "35.             ",
    streetName: "Глинка кўчаси №44-уй қаршисида",
    lineCount: 6,
    location: "41.285033,69.263776",
  },
  {
    id: "36.             ",
    streetName: "Братислава кўчаси Низомий “ТДТУ” тарих факултети",
    lineCount: 4,
    location: "41.291376,69.254644",
  },
  {
    id: "37.             ",
    streetName: "Ю.Хос.Хожиб кўчаси Маданият институти қаршиси",
    lineCount: 4,
    location: "41.300049,69.261267",
  },
  {
    id: "38.             ",
    streetName: "Ю.Хос.Хожиб кўчаси №75А-уй қаршиси",
    lineCount: 4,
    location: "41.295037,69.254807",
  },
  {
    id: "39.             ",
    streetName: "Ю.Хос.Хожиб-Ракатбоши кўчалари кесишмасида",
    lineCount: 4,
    location: "41.296402,69.256587",
  },
  {
    id: "40.             ",
    streetName: "Ю.Хос.Хожиб кўчаси “Қизил ярим ой жамияти” биноси",
    lineCount: 4,
    location: "41.30046,69.261795",
  },
  {
    id: "41.             ",
    streetName: "Ю.Хос.Хожиб кўчаси ИИВ айланма ҳаракатига етмасдан",
    lineCount: 4,
    location: "41.301671,69.263341",
  },
  {
    id: "42.             ",
    streetName: "Ю.Хос.Хожиб-Сарабўстон кўчалари кесишмасида",
    lineCount: 3,
    location: "41.301523,69.264034",
  },
  {
    id: "43.             ",
    streetName: "Ю.Хос.Хожиб кўчаси ИИВ автотураргоҳида 2 жойда",
    lineCount: 4,
    location: "41.301864,69.264264",
  },
  {
    id: "44.             ",
    streetName: "Нукус кўчаси №32-уй қаршисида",
    lineCount: 8,
    location: "41.282841,69.262158",
  },
  {
    id: "45.             ",
    streetName: "А.Қаххор-М.Таробий кўчалари кесишмасида",
    lineCount: 2,
    location: "41.285122,69.258033",
  },
  {
    id: "46.             ",
    streetName: "А.Қаххор кўчаси № 58-оилавий поликлиника қаршисида",
    lineCount: 2,
    location: "41.277329,69.262854",
  },
  {
    id: "47.             ",
    streetName: "А.Қаххор кўчаси № 48-мактаб қаршисида",
    lineCount: 2,
    location: "41.273948,69.264167",
  },
  {
    id: "48.             ",
    streetName: "Боғибўстон-“АТС” қаршисида",
    lineCount: 4,
    location: "41.279111,69.240275",
  },
  {
    id: "49.             ",
    streetName: "Боғибўстон-С.Насафий кўчалари кесишмасида",
    lineCount: 4,
    location: "41.268544,69.228526",
  },
  {
    id: "50.             ",
    streetName: "Боғибўстон-Яккасарой кўчалари кесишмасида",
    lineCount: 4,
    location: "41.267764,69.227722",
  },
  {
    id: "51.             ",
    streetName: "Боғибўстон- Яккасарой 4-тор кўчалари кесишмасида",
    lineCount: 4,
    location: "41.265167,69.224862",
  },
  {
    id: "52.             ",
    streetName: "Боғибўстон- Боғибўстон 4-тор кўчалари кесишмасида",
    lineCount: 4,
    location: "41.271605,69.232005",
  },
  {
    id: "53.             ",
    streetName: "Чўпонота кўчаси №273-МТМ қаршисида",
    lineCount: 4,
    location: "41.271692,69.228788",
  },
  {
    id: "54.             ",
    streetName: "Чўпонота кўчаси №100-мактаб қаршисида",
    lineCount: 4,
    location: "41.272324,69.227818",
  },
  {
    id: "55.             ",
    streetName: "Яккасарой кўчаси “ХУМО” мактаб қаршиси",
    lineCount: 2,
    location: "41.266448,69.230887",
  },
  {
    id: "56.             ",
    streetName: "Миробод кўчаси №6-уй қаршиси",
    lineCount: 8,
    location: "41.30035,69.267482",
  },
  {
    id: "57.             ",
    streetName: "Юнус Ражабий-Ракатбоши кўчалари кесишмасида",
    lineCount: 2,
    location: "41.304445,69.261053",
  },
  {
    id: "58.             ",
    streetName: "Юнус Ражабий-Ташқи ишлар вазирлиги қаршисида",
    lineCount: 2,
    location: "41.305052,69.263117",
  },
  {
    id: "59.             ",
    streetName: "Юнус Ражабий №62-уй қаршисида",
    lineCount: 2,
    location: "41.303676,69.25834",
  },
  {
    id: "60.             ",
    streetName: "Янги Сергели кўчаси “Дружба бургер” қаршиси",
    lineCount: 8,
    location: "41.250398,69.217623",
  },
  {
    id: "61.             ",
    streetName: "Янги Сергели кўчаси “Автомактаб” қаршиси",
    lineCount: 8,
    location: "41.246091,69.218163",
  },
  {
    id: "62.             ",
    streetName: "Янги Сергели-Эркинлик кўчалари кесишмаси ",
    lineCount: 8,
    location: "41.24689,69.218292",
  },
  {
    id: "63.             ",
    streetName: "Янги Сергели кўчаси “Мўжиза” тўйҳонаси қаршиси",
    lineCount: 8,
    location: "41.222193,69.222697",
  },
  {
    id: "64.             ",
    streetName: "Янги Сергели кўчаси Мошина бозор асосий дарвозаси қаршисида",
    lineCount: 6,
    location: "41.231267,69.216967",
  },
  {
    id: "65.             ",
    streetName: "Янги Сергели кўчаси “OIL” АЁҚШ қаршисида",
    lineCount: 6,
    location: "41.230218,69.217656",
  },
  {
    id: "66.             ",
    streetName: "Янги Сергели кўчаси МЕТАН Газ заправка қаршисида",
    lineCount: 6,
    location: "41.224449,69.221177",
  },
  {
    id: "67.             ",
    streetName: "Янги Сергели кўчаси “Мусиқа” мактаби қаршисида",
    lineCount: 6,
    location: "41.214898,69.227804",
  },
  {
    id: "68.             ",
    streetName: "Янги Сергели кўчаси “Ярмарка” бозори қаршисида",
    lineCount: 6,
    location: "41.211215,69.230688",
  },
  {
    id: "69.             ",
    streetName: "Мехригиё кўчаси “Деҳқон” бозор автотураргоҳи қаршиси",
    lineCount: 4,
    location: "41.226198,69.217863",
  },
  {
    id: "70.             ",
    streetName: "Мехригиё кўчаси “Азиз” кафеси қаршиси ",
    lineCount: 4,
    location: "41.225699,69.216603",
  },
  {
    id: "71.             ",
    streetName: "Мехригиё – Жахонаро кўчалари чорраҳаси ",
    lineCount: 4,
    location: "41.224503,69.213574",
  },
  {
    id: "72.             ",
    streetName: "Шокирариқ кўчаси “Тараннум” кафеси қаршиси ",
    lineCount: 4,
    location: "41.217913,69.220512",
  },
  {
    id: "73.             ",
    streetName: "Шокирариқ кўчаси “Эъзоз” кафеси қаршиси ",
    lineCount: 4,
    location: "41.216626,69.217074",
  },
  {
    id: "74.             ",
    streetName: "Шокирариқ кўчаси “Шовот” кафеси қаршиси ",
    lineCount: 4,
    location: "41.216401,69.216424",
  },
  {
    id: "75.             ",
    streetName: "Шокирариқ кўчаси “Туман электр тармоқ” биноси қар. ",
    lineCount: 4,
    location: "41.216418,69.216566",
  },
  {
    id: "76.             ",
    streetName: "Сўғдиёна – Жалоир кўчалари ",
    lineCount: 2,
    location: "41.22173,69.230912",
  },
  {
    id: "77.             ",
    streetName: "Обихаёт кўчаси “Асака” банки қаршиси",
    lineCount: 4,
    location: "41.219202,69.242697",
  },
  {
    id: "78.             ",
    streetName: "Обихаёт кўчаси “Автосервис” қаршиси",
    lineCount: 4,
    location: "41.218682,69.241451",
  },
  {
    id: "79.             ",
    streetName: "Обихаёт кўчаси 104-мактабга кириш жойи",
    lineCount: 4,
    location: "41.217986,69.239763",
  },
  {
    id: "80.             ",
    streetName: "Обихаёт – Жолоир кўчалари чорраҳаси",
    lineCount: 4,
    location: "41.21607,69.23515",
  },
  {
    id: "81.             ",
    streetName: "Обихаёт кўчаси “Файз мебел” биноси қаршиси",
    lineCount: 4,
    location: "41.215458,69.233618",
  },
  {
    id: "82.             ",
    streetName: "Обихаёт кўчаси “Халқ қабулҳонаси” қаршиси",
    lineCount: 4,
    location: "41.217568,69.238792",
  },
  {
    id: "83.             ",
    streetName: "Қипчоқ кўчаси “Нурхаёт” мфйга кириш жойи қаршиси",
    lineCount: 6,
    location: "41.213682,69.240748",
  },
  {
    id: "84.             ",
    streetName: "Қипчоқ кўчаси “Қуддус Шариф” жомеъ масжиди қар.",
    lineCount: 6,
    location: "41.212724,69.238413",
  },
  {
    id: "85.             ",
    streetName: "Қипчоқ кўчаси “Стоматология” қаршиси",
    lineCount: 6,
    location: "41.208716,69.228519",
  },
  {
    id: "86.             ",
    streetName: "Қипчоқ кўчаси “Нур фарм” қаршиси",
    lineCount: 6,
    location: "41.207939,69.226735",
  },
  {
    id: "87.             ",
    streetName: "Қипчоқ кўчаси “Дўка Хлеб” қаршиси",
    lineCount: 6,
    location: "41.207279,69.225135",
  },
  {
    id: "88.             ",
    streetName: "Хонабод 1-тор кўчаси Янги қурилаётган бино қаршиси",
    lineCount: 4,
    location: "41.218842,69.253265",
  },
  {
    id: "89.             ",
    streetName: "Хонабод 1-тор кўчаси “Тошкент тола” қаршиси",
    lineCount: 4,
    location: "41.22512,69.266836",
  },
  {
    id: "90.             ",
    streetName: "Қумариқ кўчаси “Тошкент-3” аэропорти айланмаси",
    lineCount: 6,
    location: "41.249329,69.264302",
  },
  {
    id: "91.             ",
    streetName: "Қумариқ – Обирахмат кўчалари кесишмаси",
    lineCount: 6,
    location: "41.25134,69.254092",
  },
  {
    id: "92.             ",
    streetName: "Қумариқ кўчаси 13-оилавий поликлиникаси қаршиси",
    lineCount: 6,
    location: "41.252086,69.252708",
  },
  {
    id: "93.             ",
    streetName: "Қумариқ кўчаси “Медиа фарм” биноси қаршиси ",
    lineCount: 6,
    location: "41.255531,69.249098",
  },
  {
    id: "94.             ",
    streetName: "Қумариқ кўчаси “Элит” маркет қаршиси",
    lineCount: 8,
    location: "41.259646,69.255231",
  },
  {
    id: "95.             ",
    streetName: "Халқаро аэропорти айланмаси",
    lineCount: 6,
    location: "41.267493,69.267029",
  },
  {
    id: "96.             ",
    streetName: "Халқаро аэропорти айланмаси янги бино қаршиси",
    lineCount: 6,
    location: "41.265405,69.267033",
  },
  {
    id: "97.             ",
    streetName: "С.Толипов кўчаси аэропорт автотураргоҳи ",
    lineCount: 4,
    location: "41.268417,69.268469",
  },
  {
    id: "98.             ",
    streetName: "С.Толипов кўчаси 9-уй қаршиси ",
    lineCount: 4,
    location: "41.269296,69.27238",
  },
  {
    id: "99.             ",
    streetName: "С.Толипов кўчаси “Сомсаҳона” қаршиси  ",
    lineCount: 4,
    location: "41.26958,69.274937",
  },
  {
    id: "100.        ",
    streetName: "Чоштепа кўчаси бекат қаршиси (Янги Сергели кўчаси томондан)  ",
    lineCount: 4,
    location: "41.251635,69.214009",
  },
  {
    id: "101.        ",
    streetName: "Чоштепа кўчаси “Стадион” қаршиси",
    lineCount: 4,
    location: "41.246981,69.206212",
  },
  {
    id: "102.        ",
    streetName: "Чоштепа кўчаси “Хавас” дўкони қаршиси",
    lineCount: 4,
    location: "41.245706,69.203744",
  },
  {
    id: "103.        ",
    streetName: "ТХАЙ – Паст Дархон кўчалари кесишмаси",
    lineCount: 8,
    location: "41.235207,69.208943",
  },
  {
    id: "104.        ",
    streetName: "ТХАЙ Газ заправка қаршиси ",
    lineCount: 8,
    location: "41.232886,69.206227",
  },
  {
    id: "105.        ",
    streetName: "ТХАЙ “KIA” автосалони қаршиси",
    lineCount: 8,
    location: "41.235023,69.208187",
  },
  {
    id: "106.        ",
    streetName: "ТХАЙ “Стадион” қаршиси",
    lineCount: 8,
    location: "41.238179,69.210207",
  },
  {
    id: "107.        ",
    streetName: "ТХАЙ “Мебел” дўкони қаршиси ",
    lineCount: 8,
    location: "41.232789,69.170538",
  },
  {
    id: "108.        ",
    streetName: "ТХАЙ “Макро” супермаркети қаршиси",
    lineCount: 8,
    location: "41.244814,69.268368",
  },
  {
    id: "109.        ",
    streetName: "ТХАЙ “Анҳор” кафеси қаршиси",
    lineCount: 8,
    location: "41.244463,69.275003",
  },
  {
    id: "110.        ",
    streetName: "М.Турсунзода кўчаси 2-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.228326,69.203067",
  },
  {
    id: "111.        ",
    streetName: "М.Турсунзода кўчаси 7-уй қаршиси ",
    lineCount: 4,
    location: "41.225082,69.205183",
  },
  {
    id: "112.        ",
    streetName: "М.Турсунзода кўчаси 3-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.221480,69.208449",
  },
  {
    id: "113.        ",
    streetName: "М.Турсунзода кўчаси 4-уй қаршиси ",
    lineCount: 4,
    location: "41.218257,69.209937",
  },
  {
    id: "114.        ",
    streetName: "М.Турсунзода кўчаси 6-уй қаршиси ",
    lineCount: 4,
    location: "41.215974,69.211797",
  },
  {
    id: "115.        ",
    streetName: "М.Турсунзода кўчаси 8-мактаб қаршиси ",
    lineCount: 4,
    location: "41.207532,69.217957",
  },
  {
    id: 116,
    streetName: "М.Турсунзода кўчаси 5-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.205337,69.219762",
  },
  {
    id: "Янгихаёт тумани",
  },
  {
    id: "117.        ",
    streetName: "Чоштепа кўчаси 1-метро бекати қаршиси",
    lineCount: 4,
    location: "41.238830,69.195502",
  },
  {
    id: "118.        ",
    streetName: "Чоштепа кўчаси “Маркет” қаршиси",
    lineCount: 4,
    location: "41.231404,69.184737",
  },
  {
    id: "119.        ",
    streetName: "Чоштепа кўчаси 290-уй қаршиси",
    lineCount: 4,
    location: "41.235145,69.187632",
  },
  {
    id: "120.        ",
    streetName: "Чоштепа кўчаси 326-уй қаршиси",
    lineCount: 4,
    location: "41.231578,69.184767",
  },
  {
    id: "121.        ",
    streetName: "Қипчоқ кўчаси намунали уйлар қаршиси",
    lineCount: 6,
    location: "41.202973,69.216307",
  },
  {
    id: "122.        ",
    streetName: "Қипчоқ кўчаси намунали уйлар қаршиси",
    lineCount: 6,
    location: "41.203394,69.21734",
  },
  {
    id: "123.        ",
    streetName: "Қипчоқ кўчаси намунали уйлар қаршиси",
    lineCount: 6,
    location: "41.203394,69.21734",
  },
  {
    id: "124.        ",
    streetName: "5-метро бекат қаршиси",
    lineCount: 6,
    location: "41.205404,69.219818",
  },
  {
    id: "125.        ",
    streetName: "Чинор – Боғичинор кўчалари кесишмаси ",
    lineCount: 4,
    location: "41.200420,69.217446",
  },
  {
    id: "126.        ",
    streetName: "Чинор – Полвонёр кўчалари кесишмаси ",
    lineCount: 4,
    location: "41.201426,69.219704",
  },
  {
    id: "127.        ",
    streetName: "Чинор кўчаси прокуратура қаршиси ",
    lineCount: 4,
    location: "41.201786,69.220413",
  },
  {
    id: "128.        ",
    streetName: "Чинор – Қанора кўчалари кесишмаси ",
    lineCount: 4,
    location: "41.205956,69.230688",
  },
  {
    id: "129.        ",
    streetName: "Қанора – Маргиб кўчалари кесишмаси",
    lineCount: 2,
    location: "41.195687,69.233823",
  },
  {
    id: "130.        ",
    streetName: "Райҳон – Чортоқ кўчалари чорраҳаси",
    lineCount: 2,
    location: "41.194574,69.234628",
  },
  {
    id: "131.        ",
    streetName: "Райҳон кўчаси бекат қаршиси",
    lineCount: 2,
    location: "41.192998,69.235921",
  },
  {
    id: "132.        ",
    streetName: "Райҳон кўчаси бекат қаршиси",
    lineCount: 2,
    location: "41.193110,69.240965",
  },
  {
    id: "133.        ",
    streetName: "Софдил кўчаси “Грин Парк” қаршиси ",
    lineCount: 2,
    location: "41.203696,69.230065",
  },
  {
    id: "134.        ",
    streetName: "Давлатобод – Биродарлик кўчалари чорраҳаси ",
    lineCount: 2,
    location: "41.196886,69.228848",
  },
  {
    id: "135.        ",
    streetName: "Биродарлик кўчаси 353-МТТ қаршиси ",
    lineCount: 2,
    location: "41.196074,69.226482",
  },
  {
    id: "136.        ",
    streetName: "Биродарлик – Полвонёр кўчалари кесишмаси",
    lineCount: 2,
    location: "41.194984,69.224351",
  },
  {
    id: "137.        ",
    streetName: "Биродарлик – Боғичинор кўчалари кесишмаси",
    lineCount: 2,
    location: "41.194092,69.222195",
  },
  {
    id: "138.        ",
    streetName: "Биродарлик – Қўрғонтепа кўчалари кесишмаси",
    lineCount: 2,
    location: "41.192219,69.217618",
  },
  {
    id: "139.        ",
    streetName: "Қўрғонтепа кўчаси 37-оилавий поликлиника қаршиси",
    lineCount: 2,
    location: "41.197600,69.214411",
  },
  {
    id: "140.        ",
    streetName: "Қўрғонтепа кўчаси “Олчазор” мфй қаршиси",
    lineCount: 4,
    location: "41.206587,69.207346",
  },
  {
    id: "141.        ",
    streetName: "Қўрғонтепа кўчаси “Минимаркет” қаршиси",
    lineCount: 4,
    location: "41.207011,69.206637",
  },
  {
    id: "142.        ",
    streetName: "Қўрғонтепа кўчаси бекат қаршиси ",
    lineCount: 4,
    location: "41.218320,69.198825",
  },
  {
    id: "143.        ",
    streetName: "Мехригиё кўчаси “Элит маркет” қаршиси ",
    lineCount: 6,
    location: "41.220077,69.202569",
  },
  {
    id: "144.        ",
    streetName: "Мехригиё кўчаси “Tonelli” дўкони қаршиси",
    lineCount: 6,
    location: "41.220627,69.20387",
  },
  {
    id: "145.        ",
    streetName: "М.Турсунзода кўчаси 2-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.228267,69.203069 ",
  },
  {
    id: "146.        ",
    streetName: "М.Турсунзода кўчаси 7-уй қаршиси ",
    lineCount: 4,
    location: "41.225446,69.204884",
  },
  {
    id: "147.        ",
    streetName: "М.Турсунзода кўчаси 3-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.221250,69.208242",
  },
  {
    id: "148.        ",
    streetName: "М.Турсунзода кўчаси 4-уй қаршиси ",
    lineCount: 4,
    location: "41.218370,69.209921",
  },
  {
    id: "149.        ",
    streetName: "М.Турсунзода кўчаси 6-уй қаршиси ",
    lineCount: 4,
    location: "41.216126,69.211595",
  },
  {
    id: "150.        ",
    streetName: "М.Турсунзода кўчаси 8-мактаб қаршиси ",
    lineCount: 4,
    location: "41.208250,69.217644",
  },
  {
    id: "151.        ",
    streetName: "М.Турсунзода кўчаси 5-метро бекати қаршиси ",
    lineCount: 4,
    location: "41.205211,69.219844",
  },
  {
    id: 1,
    streetName: "Мунис кўчаси “Дунё” тўйхонаси қаршисида",
    lineCount: 4,
    location: "41.244923,69.310595",
  },
  {
    id: 2,
    streetName: "Мунис кўчаси кўрлар жамияти қаршисида",
    lineCount: 4,
    location: "41.245224,69.316468",
  },
  {
    id: 3,
    streetName: "Мунис кўчаси 22-уй қаршисида ",
    lineCount: 4,
    location: "41.246899,69.3221",
  },
  {
    id: 4,
    streetName: "Мунис кўчаси Корзинка қаршисида",
    lineCount: 4,
    location: "41.245014,69.311413",
  },
  {
    id: 5,
    streetName: "Янги Қўйлиқ кўчаси 1/1 уй қаршисида",
    lineCount: 4,
    location: "41.251189,69.302215",
  },
  {
    id: 6,
    streetName: "Янги Қўйлиқ кўчаси 20-уй қаршисида",
    lineCount: 4,
    location: "41.252072,69.30207",
  },
  {
    id: 7,
    streetName: "Янги Қўйлиқ кўчаси 20-уй қаршисида",
    lineCount: 4,
    location: "41.252464,69.302225",
  },
  {
    id: 8,
    location: 69.302225,
  },
  {
    id: 9,
    streetName: "Янги Қўйлиқ кўчаси 5-уй қаршисида",
    lineCount: 6,
    location: "41.255350,69.319076",
  },
  {
    id: 10,
    streetName: "Янги Қўйлиқ кўчаси 3-уй (коко шанель) қаршисида",
    lineCount: 6,
    location: "41.255350,69.319076",
  },
  {
    id: 11,
    streetName: "А.Фитрат кўчаси 81А-уй қаршисида",
    lineCount: 6,
    location: "41.26959,69.301682",
  },
  {
    id: 12,
    streetName: "А.Фитрат кўчаси 11-туғруқхона қаршисида",
    lineCount: 6,
    location: "41.269418,69.300138",
  },
  {
    id: 13,
    streetName: "А.Фитрат-Фидокорлар кўчалар кесишмаси",
    lineCount: 6,
    location: "41.267749,69.294517",
  },
  {
    id: 14,
    streetName: "А.Фитрат кўчаси 146-уй қаршисида",
    lineCount: 6,
    location: "41.268594,69.290462",
  },
  {
    id: 15,
    streetName: "А.Фитрат кўчаси 162-уй қаршисида",
    lineCount: 6,
    location: "41.269719,69.289097",
  },
  {
    id: 16,
    streetName: "А.Фитрат кўчаси Миробод ИИБ қаршисида",
    lineCount: 6,
    location: "41.271287,69.286367",
  },
  {
    id: 17,
    streetName: "А.Фитрат кўчаси Миробод ИИБ қаршисида",
    lineCount: 6,
    location: "41.271287,69.286367",
  },
  {
    id: 18,
    streetName: "А.Фитрат-Бонакатий айланма харакатида                 ",
    lineCount: 6,
    location: "41.272775,69.284284",
  },
  {
    id: 19,
    streetName: "А.Фитрат-Бонакатий айланма харакатида                 ",
    lineCount: 6,
    location: "41.273251,69.283734",
  },
  {
    id: 20,
    streetName: "А.Фитрат-Бонакатий айланма харакатида                 ",
    lineCount: 6,
    location: "41.272662,69.282986",
  },
  {
    id: 21,
    streetName: "Бонакатий кўчаси Транспорт институти қаршисида",
    lineCount: 4,
    location: "41.274827,69.285299",
  },
  {
    id: 22,
    streetName: "Г.Алиева кўчаси 202-уй қаршисида",
    lineCount: 4,
    location: "41.277406,69.273917",
  },
  {
    id: 23,
    streetName: "Г.Алиева кўчаси 221-уй қаршисида",
    lineCount: 4,
    location: "41.274136,69.277211",
  },
  {
    id: 24,
    streetName: "Г.Алиева-Дашнобод кўчалар кесишмаси",
    lineCount: 4,
    location: "41.275740,69.274972",
  },
  {
    id: 25,
    streetName: "Г.Алиева кўчаси “Азарбаджон центири” қаршисида",
    lineCount: 4,
    location: "41.287019,69.28268",
  },
  {
    id: 26,
    streetName: "Темирйўлчилар-Саракул кўчалар кесишмаси",
    lineCount: 4,
    location: "41.280920,69.283514",
  },
  {
    id: 27,
    streetName: "Саракул кўчаси 11-уй қаршисида",
    lineCount: 4,
    location: "41.284649,69.283842",
  },
  {
    id: 28,
    streetName: "Саракул кўчаси Авнгард дўкони қаршисида",
    lineCount: 4,
    location: "41.284522,69.283437",
  },
  {
    id: 29,
    streetName: "Саракул кўчаси “Локаматив меҳмонҳонаси” қаршисида",
    lineCount: 4,
    location: "41.279977,69.285633",
  },
  {
    id: 30,
    streetName: "Саракул кўчаси 57-мактаб қаршисида",
    lineCount: 4,
    location: "41.278062,69.286701",
  },
  {
    id: 31,
    streetName: "Саракул кўчаси “Америка мактаб) қаршисида",
    lineCount: 4,
    location: "41.270471,69.295322",
  },
  {
    id: 32,
    streetName: "Мехрижон – Эски Саракул кўчалар кесишмаси",
    lineCount: 4,
    location: "41.281076,69.292336",
  },
  {
    id: 33,
    streetName: "Фурқат кўчаси “Фурқат боғи” қаршисида",
    lineCount: 4,
    location: "41.274798,69.300162",
  },
  {
    id: 34,
    streetName: "Фурқат кўчаси 12-уй қаршисида",
    lineCount: 4,
    location: "41.275467,69.300354",
  },
  {
    id: 35,
    streetName: "Сайхун кўчаси 1-уй қаршисида",
    lineCount: 6,
    location: "41.286848,69.297877",
  },
  {
    id: 36,
    streetName: "Сайхун кўчаси 17/1 уй қаршисида ",
    lineCount: 6,
    location: "41.286970,69.297734",
  },
  {
    id: 37,
    streetName: "Сайхун кўчаси 17/2 уй қаршисида",
    lineCount: 6,
    location: "41.286881,69.297396",
  },
  {
    id: 38,
    streetName: "Сайхун-Хамал кўчалар кесишмаси",
    lineCount: 4,
    location: "41.283810,69.301285",
  },
  {
    id: 39,
    streetName: "Мехрижон кўчаси 65-мактаб қаршисида",
    lineCount: 4,
    location: "41.284538,69.292209",
  },
  {
    id: 40,
    streetName: "Мехрижон кўчаси Миробод РУБ қаршисида",
    lineCount: 4,
    location: "41.287249,69.287174",
  },
  {
    id: 41,
    streetName: "Мехрижон кўчаси темирйўлар поликникаси қаршисида",
    lineCount: 5,
    location: "41.286364,69.288563",
  },
  {
    id: 42,
    streetName: "Нукус кўчаси Гламур дўкони қаршисида",
    lineCount: 8,
    location: "41.298454,69.288652",
  },
  {
    id: 43,
    streetName: "Туркистон кўчаси вокзал қаршисида",
    lineCount: 2,
    location: "41.291574,69.28622",
  },
  {
    id: 44,
    streetName: "Туркистон кўчаси “Гл.Почта” қаршисида",
    lineCount: 2,
    location: "41.291574,69.28622",
  },
  {
    id: 45,
    streetName: "А.Темур кўчаси Автодор коледжи қаршисида",
    lineCount: 6,
    location: "41.302524,69.2794",
  },
  {
    id: 46,
    streetName: "А.Темур кўчаси “Билайн” копанияси қаршисида",
    lineCount: 6,
    location: "41.308172,69.278901",
  },
  {
    id: 47,
    streetName: "С.Азимов кўчаси “Макро” дўкони қаршисида  ",
    lineCount: 4,
    location: "41.302121,69.286441",
  },
  {
    id: 48,
    streetName: "С.Азимова кўчаси Маданият саройи қаршисида",
    lineCount: 4,
    location: "41.307880,69.292645",
  },
  {
    id: 49,
    streetName: "И.Каримов кўчаси “Фотон” заводи қаршисида",
    lineCount: 6,
    location: "41.306227,69.276934",
  },
  {
    id: 50,
    streetName: "И.Каримов кўчаси “Марказий Банк” қаршисида",
    lineCount: 6,
    location: "41.310850,69.257292",
  },
  {
    id: 51,
    streetName: "И.Каримов кўчаси “ЦУМ” қаршисида",
    lineCount: 6,
    location: "41.308755,69.26933",
  },
  {
    id: 52,
    streetName: "Матбуотчилар кўчаси “Пойтхт” савдо мажмуаси қаршисида",
    lineCount: 3,
    location: "41.311489,69.274028",
  },
  {
    id: 53,
    streetName: "Матбуотчилар кўчаси “Пойтхт” савдо мажмуаси қаршисида",
    lineCount: 3,
    location: "41.311627,69.273741",
  },
  {
    id: 54,
    streetName: "А.Темур ҳиёбони –Матбуочилар кўчалар кесишмаси",
    lineCount: 3,
    location: "41.310513,69.277516",
  },
  {
    id: 55,
    streetName: "А.Темур хиёбони-Истикбол кўчасига кириш жойида",
    lineCount: 2,
    location: "41.309792,69.280953",
  },
  {
    id: 56,
    streetName: "А.Темур кўчаси Фонд форум қаршисида",
    lineCount: 2,
    location: "41.310521,69.28157",
  },
  {
    id: 57,
    streetName: "Я.Колос кўчаси 110-мактаб қаршисида",
    lineCount: 4,
    location: "41.303128,69.275685",
  },
  {
    id: 58,
    streetName: "Я.Колос кўчаси 110-мактаб қаршисида",
    lineCount: 4,
    location: "41.303128,69.275685",
  },
  {
    id: 59,
    streetName: "Чехова кўчаси 49-уй қаршисида",
    lineCount: 4,
    location: "41.294933,69.27297",
  },
  {
    id: 60,
    streetName: "Авлиё ота кўчаси Миробод дехқон бозори қаршисида",
    lineCount: 4,
    location: "41.291088,69.277274",
  },
  {
    id: 61,
    streetName: "Авлиё ота кўчаси Миробод дехқон бозори қаршисида",
    lineCount: 4,
    location: "41.290584,69.273962",
  },
  {
    id: 62,
    streetName: "Мробод кўчаси 6-уй қаршисида ",
    lineCount: 6,
    location: "41.300339,69.267167",
  },
  {
    id: 63,
    streetName: "Миробод кўчаси “Яккасарой ФХДЁ” қаршисида",
    lineCount: 6,
    location: "41.299565,69.267485",
  },
  {
    id: 64,
    streetName: "Миробод кўчаси 13-уй қаршисида",
    lineCount: 4,
    location: "41.300383,69.267521",
  },
  {
    id: 65,
    streetName: "8-март кўчаси 3-ДАКС қаршисида",
    lineCount: 4,
    location: "41.285637,69.301188",
  },
  {
    id: 66,
    streetName: "Эски Саракул-Бонакатий кўчалар кесишмаси",
    lineCount: 2,
    location: "41.281039,69.292044",
  },
  {
    id: 67,
    streetName: "8-март-Эски саракул кўчалар кесишмаси",
    lineCount: 4,
    location: "41.279992,69.293927",
  },
  {
    id: 68,
    streetName: "Боровский кўчаси “МИРАН” мехмохонаси қаршисида",
    lineCount: 2,
    location: "41.304598,69.283509",
  },
  {
    id: 69,
    streetName: "Шахрисаб-Баровский кўчалар кесишмаси қаршисида",
    lineCount: 2,
    location: "41.303203,69.284143",
  },
  {
    id: 70,
    streetName: "Истикбол кўчаси “Бохор” ресторани қаршисида",
    lineCount: 2,
    location: "41.307897,69.282816",
  },
  {
    id: "354.        ",
    streetName: "Истирохат кўчаси №9А-уй қаршиси",
    lineCount: 2,
    location: "41.326805,69.166195",
  },
  {
    id: "355.        ",
    streetName: "Истирохат-Мехмондўст 2-тор кўчаллари кесишмаси",
    lineCount: 2,
    location: "41.325948,69.164904",
  },
  {
    id: "356.        ",
    streetName: "Истирохат кўчаси №550-МТМқаршиси",
    lineCount: 2,
    location: "41.327753,69.169258",
  },
  {
    id: "357.        ",
    streetName: "Истирохат-Бекабод кўчалари кесишмаси",
    lineCount: 2,
    location: "41.327422,69.174676",
  },
  {
    id: "358.        ",
    streetName: "Тўқимачи кўчаси №1-уй қаршисид",
    lineCount: 4,
    location: "41.312461,69.159551",
  },
  {
    id: "359.        ",
    streetName: "Тўқимачи кўчаси №104-уй қаршиси",
    lineCount: 4,
    location: "41.304789,69.162845",
  },
  {
    id: "360.        ",
    streetName: "Тўқимачи-Тўқимачи 1-тор кўчаларикесишмаси",
    lineCount: 4,
    location: "41.302925,69.164104",
  },
  {
    id: "361.        ",
    streetName: "Тўқимачи кўчаси №33-уйқаршиси",
    lineCount: 4,
    location: "41.302407,69.164668",
  },
  {
    id: "362.        ",
    streetName: "Тўқимачи кўчаси №193-Мактаб қаршиси",
    lineCount: 4,
    location: "41.229190,69.168225",
  },
  {
    id: "363.        ",
    streetName: "КХАЙ-К.Дарвоза кўчалари кесишмаси",
    lineCount: 6,
    location: "41.308674,69.152155",
  },
  {
    id: "364.        ",
    streetName: "КХАЙ-Карвон кўчалари кесишмаси",
    lineCount: 6,
    location: "41.315145,69.193296",
  },
  {
    id: "365.        ",
    streetName: "КХАЙ-Автомибиллар ювиш шахобчаси қаршиси",
    lineCount: 6,
    location: "41.301162,69.193752",
  },
  {
    id: "366.        ",
    streetName: "КХАЙ-№115-уй қаршиси",
    lineCount: 6,
    location: "41.310152,69.193566",
  },
  {
    id: "367.        ",
    streetName: "Фарход кўчаси ПРЯЖА дўкони қаршиси",
    lineCount: 8,
    location: "41.294172,69.177358",
  },
  {
    id: "368.        ",
    streetName: "Журжоний кўчаси №34-уй қаршиси",
    lineCount: 6,
    location: "41.299650,69.173252",
  },
  {
    id: "369.        ",
    streetName: "Журжоний кўчаси №6-уйқаршси",
    lineCount: 6,
    location: "41.301048,69.172585",
  },
  {
    id: "370.        ",
    streetName: "Назарбек-Ғайрат кўчалари кесишмаси",
    lineCount: 2,
    location: "41.307440,69.168628",
  },
  {
    id: "371.        ",
    streetName: "Ю.Саккокий-Мехрибон кўчалари кесишмаси",
    lineCount: 2,
    location: "41.296695,69.169",
  },
  {
    id: "372.        ",
    streetName: "Ю.Саккокий-Мирхонд 4-тор  кўчалари кесишмаси",
    lineCount: 2,
    location: "41.296071,69.165282",
  },
  {
    id: "373.        ",
    streetName: "Ю.Саккокий-Мирхонд 7-торкўчалари кесишмаси",
    lineCount: 2,
    location: "41.29487169.163765",
  },
  {
    id: "374.        ",
    streetName: "Ю.Саккокий-Ю.Саккокий 5-тор  кўчалари кесишмаси",
    lineCount: 2,
    location: "41.292312,69.156627",
  },
  {
    id: "375.        ",
    streetName: "Ю.Саккокий-Муаззамхон кўчалари кесишмаси",
    lineCount: 2,
    location: "41.292339,69.154843",
  },
  {
    id: "376.        ",
    streetName: "Лутфийкўчаси Оила маркети қаршиси",
    lineCount: 6,
    location: "41.283733,69.18305",
  },
  {
    id: "377.        ",
    streetName: "Лутфий кўчаси №16-уй қаршиси",
    lineCount: 6,
    location: "41.277891,69.175453",
  },
  {
    id: "378.        ",
    streetName: "Лутфий кўчаси Абдуллох Ибин масжид қаршиси",
    lineCount: 6,
    location: "41.275488,69.169208",
  },
  {
    id: "379.        ",
    streetName: "К.Хирмонтепа кўчаси “Авто” мактаб қаршиси",
    lineCount: 4,
    location: "41.282828,69.170124",
  },
  {
    id: "380.        ",
    streetName: "К.Хирмонтепа кўчаси Учтепа супер марккети қаршиси",
    lineCount: 4,
    location: "41.281557,69.169995",
  },
  {
    id: "381.        ",
    streetName: "К.Хирмонтепа кўчаси №13-уй қаршиси",
    lineCount: 4,
    location: "41.287212,69.171722",
  },
  {
    id: "382.        ",
    streetName: "М.Шахзода кўчалари Осиё мед қаршиси",
    lineCount: 4,
    location: "41.279260,69.188112",
  },
  {
    id: "383.        ",
    streetName: "Фозилтепа кўчаси 15-мавзе №17-уй қаршиси",
    lineCount: 4,
    location: "41.275237,69.180959",
  },
  {
    id: "384.        ",
    streetName: "Фозилтепа кўчаси Авто сервис қаршиси",
    lineCount: 4,
    location: "41.297411,69.180736",
  },
  {
    id: "385.        ",
    streetName:
      "Фозилтепа кўчаси Соғлиқни сақлаш бош бошқармаси авто корхонаси қаршиси",
    lineCount: 4,
    location: "41.298400,69.188837",
  },
  {
    id: "386.        ",
    streetName: "К.Дарвоза кўчаси Хазрат умар масжид қаршиси",
    lineCount: 4,
    location: "41.312160,69.178544",
  },
  {
    id: "387.        ",
    streetName: "К.Дарвоза кўчаси Жаф Авто мастер",
    lineCount: 4,
    location: "41.314426,69.186206",
  },
  {
    id: "388.        ",
    streetName: "К.Дарвоза кўчаси Қамиш кафеси қаршиси",
    lineCount: 4,
    location: "41.320526,69.225076",
  },
  {
    id: "389.        ",
    streetName: "К.Дарвоза Муруской салон қаршиси",
    lineCount: 4,
    location: "41.322196,69.215962",
  },
  {
    id: "390.        ",
    streetName: "Бешқайроғоч кўчаси №116-мактаб қаршиси",
    lineCount: 4,
    location: "41.315564,69.163102",
  },
  {
    id: "391.        ",
    streetName: "Бешқайроғоч-Шарқ Гули махалласи кўчаллари  кесишмаси",
    lineCount: 6,
    location: "41.314617,69.156324",
  },
  {
    id: "392.        ",
    streetName: "Бешқайроғоч кўчалари-Боғичинор махалласи кўчалари кесишмаси",
    lineCount: 6,
    location: "41.322758,69.181863",
  },
  {
    id: "393.        ",
    streetName: "Бешқайроғоч-Дегрез кўчаолари кесишмаси",
    lineCount: 6,
    location: "41.322693,69.184671",
  },
  {
    id: "394.        ",
    streetName: "Қовунчи кўчаси Порекмахерская қаршисида",
    lineCount: 4,
    location: "41.257890,69.159277",
  },
  {
    id: "395.        ",
    streetName: "Қовунчи кўчаси Автомактаб қаршисида",
    lineCount: 4,
    location: "41.260841,69.15538",
  },
  {
    id: "396.        ",
    streetName: "Ковунчи-Қурилиш кўчалари кесишмаси",
    lineCount: 4,
    location: "41.270037,69.152126",
  },
  {
    id: "397.        ",
    streetName: "Қурилиш-Қурилиш 2-тор кўчалари кесишмаси",
    lineCount: 2,
    location: "41.272194,69.158522",
  },
  {
    id: "398.        ",
    streetName: "Изза кўчаси Оил сервисқаршиси",
    lineCount: 2,
    location: "41.275708,69.156760",
  },
  {
    id: "399.        ",
    streetName: "Изза кўчаси №1-уй қаршиси",
    lineCount: 2,
    location: "41.281723,69.155757",
  },
  {
    id: "400.        ",
    streetName: "Изза кўчаси Автомойка қаршиси",
    lineCount: 2,
    location: "41.282474,69.15927",
  },
  {
    id: "401.        ",
    streetName: "Фозилтепа кўчаси Учтепа Суд қаршисида",
    lineCount: 4,
    location: "41.2866593,69.165809",
  },
  {
    id: "402.        ",
    streetName: "Фозилтепа-Бирлик махалласикўчалари қаршиси",
    lineCount: 4,
    location: "41.295218,69.176248",
  },
  {
    id: "403.        ",
    streetName: "Найман кўчаси Учтепа туманимиграция ва ФВБқаршиси",
    lineCount: 2,
    location: "41.291178,69.168328",
  },
  {
    id: "404.        ",
    streetName: "Найман кўчаси  Учтепа тумани прократураси қаршиси",
    lineCount: 2,
    location: "41.290425,69.170053",
  },
  {
    id: "405.        ",
    streetName: "Ширин кўчаси Дизайин мактаби қаршисида",
    lineCount: 6,
    location: "41.278955,69.183311",
  },
  {
    id: "406.        ",
    streetName: "Ширин кўчаси Турк мактабиқаршиси",
    lineCount: 4,
    location: "41.284253,69.177925",
  },
  {
    id: "407.        ",
    streetName: "Ширин кўчаси Дилорам тўйхонаси қаршиси",
    lineCount: 4,
    location: "41.284290,69.177883",
  },
  {
    id: "408.        ",
    streetName: "Ширин кўчаси №5-уй қаршиси",
    lineCount: 4,
    location: "41.285148,69.168764",
  },
  {
    id: "409.        ",
    streetName: "К.Қани кўчаси №287-уй қаршиси ",
    lineCount: 2,
    location: "41.277218,69.165281",
  },
  {
    id: "410.        ",
    streetName: "Катта Қани айланма харакати қаршиси",
    lineCount: 4,
    location: "41.274593,69.166442",
  },
  {
    id: "411.        ",
    streetName: "Гулистон-Гулистон 3-тор кўчалари кесишмаси",
    lineCount: 2,
    location: "41.270039,69.166874",
  },
  {
    id: "412.        ",
    streetName: "Гулистон-Гулистон 5-тор кўчалари кесишмаси",
    lineCount: 2,
    location: "41.269408,69.165489",
  },
  {
    id: "413.        ",
    streetName: "Гулистон кўчаси №6А-уй қаршиси",
    lineCount: 4,
    location: "41.263746,69.156574",
  },
  {
    id: "414.        ",
    streetName: "Гулистон кўчаси Хўжаликлари моллари",
    lineCount: 4,
    location: "41.265487,69.160282",
  },
  {
    id: "415.        ",
    streetName: "Гулистон кўчаси Умар ота маркет қаршиси",
    lineCount: 4,
    location: "41.266751,69.162397",
  },
  {
    id: "416.        ",
    streetName: "ТХАЙ “Димах” савдо маркази",
    lineCount: 6,
    location: "41.315128,69.193463",
  },
  {
    id: "417.        ",
    streetName: "ТХАЙ-Шоир кўчаллари кесишмаси",
    lineCount: 6,
    location: "41.321219,69.157046",
  },
  {
    id: "418.        ",
    streetName: "ТХАЙ-Бешқайроғоч кўчалари кесишмаси",
    lineCount: 6,
    location: "41.315062,69.154518",
  },
  {
    id: "419.        ",
    streetName: "ТХАЙ-Ю.Саккокий кўчалари кесишмаи",
    lineCount: 6,
    location: "41.292530,69.150226",
  },
  {
    id: "420.        ",
    streetName: "Бунёдкор кўчаси 6-уй қаршиси",
    lineCount: 8,
    location: "41.308087,69.238396",
  },
  {
    id: "421.        ",
    streetName: "Бунёдкор кўчаси Совпластитал қаршиси",
    lineCount: 8,
    location: "41.259968,69.196322",
  },
  {
    id: "422.        ",
    streetName: "Бунёдкор Чорбоғ кесишмаси",
    lineCount: 8,
    location: "41.250331,69.183098",
  },
  {
    id: "423.        ",
    streetName: "Бунёдкор кўчаси 41А уй қаршиси",
    lineCount: 8,
    location: "41.246020,69.176693",
  },
  {
    id: "424.        ",
    streetName: "Бунёдкор Саида сервис қаршиси",
    lineCount: 8,
    location: "41.247953,69.179383",
  },
  {
    id: "425.        ",
    streetName: "КХАЙ кўчаси 7/1 уй қаршиси",
    lineCount: 8,
    location: "41.277482,69.200089",
  },
  {
    id: "426.        ",
    streetName: "КХАЙ кўчаси гул бозори  қаршиси",
    lineCount: 8,
    location: "41.276087,69.202275",
  },
  {
    id: "427.        ",
    streetName: "КХАЙ кўчаси 24/1 уй қаршиси",
    lineCount: 8,
    location: "41.282924,69.194667",
  },
  {
    id: "428.        ",
    streetName: "КХАЙ кўчаси 11-уй қаршиси",
    lineCount: 8,
    location: "41.294629,69.194119",
  },
  {
    id: "429.        ",
    streetName: "КХАЙ кўчаси 21А-уй қаршиси",
    lineCount: 8,
    location: "41.280241,69.195749",
  },
  {
    id: "430.        ",
    streetName: "Чўпонота кўчаси 7-уй қаршиси",
    lineCount: 6,
    location: "41.276722,69.221",
  },
  {
    id: "431.        ",
    streetName: "Чўпонота кўчаси 51-уй қаршиси",
    lineCount: 6,
    location: "41.284177,69.209277",
  },
  {
    id: "432.        ",
    streetName: "Чўпонота кўчаси ТИИБ ФМБ қаршиси ",
    lineCount: 6,
    location: "41.289056,69.201843",
  },
  {
    id: "433.        ",
    streetName: "Чўпонота кўчаси 4-уй қаршиси",
    lineCount: 6,
    location: "41.287556,69.203872",
  },
  {
    id: "434.        ",
    streetName: "Чилонзор кўчаси 53-уй қаршиси ",
    lineCount: 4,
    location: "41.280592,69.220088",
  },
  {
    id: "435.        ",
    streetName: "Чилонзор кўчаси 3-уй қаршиси",
    lineCount: 4,
    location: "41.282503,69.222768",
  },
  {
    id: "436.        ",
    streetName: "Қатортол кўчаси 2-уй қаршиси",
    lineCount: 4,
    location: "41.277227,69.196512",
  },
  {
    id: "437.        ",
    streetName: "Арнасой кўчаси 29-уй қаршиси ",
    lineCount: 4,
    location: "41.279448,69.22652",
  },
  {
    id: "438.        ",
    streetName: "Арнасой кўчаси ППХ Бригадаси қаршиси",
    lineCount: 4,
    location: "41.283764,69.231344",
  },
  {
    id: "439.        ",
    streetName: "Лабиховуз кўчаси 50-уй қаршисида",
    lineCount: 4,
    location: "41.285198,69.2278",
  },
  {
    id: "440.        ",
    streetName: "Қатортол кўчаси 26-уй қаршиси ",
    lineCount: 4,
    location: "41.289065,69.208442",
  },
  {
    id: "441.        ",
    streetName: "Қатортол кўчаси 60-уй қаршиси",
    lineCount: 4,
    location: "41.292344,69.21205",
  },
  {
    id: "442.        ",
    streetName: "Тухтагул кўчаси 5-уй қаршисида",
    lineCount: 4,
    location: "41.284493,69.200291",
  },
  {
    id: "443.        ",
    streetName: "Мехр кўчаси 19-уй қаршиси ",
    lineCount: 4,
    location: "41.281603,69.203136",
  },
  {
    id: "444.        ",
    streetName: "Мехр кўчаси 16-уй қаршиси",
    lineCount: 4,
    location: "41.280726,69.204752",
  },
  {
    id: "445.        ",
    streetName: "Мехр кўчаси 56-уй қаршиси ",
    lineCount: 4,
    location: "41.280071,69.205918",
  },
  {
    id: "446.        ",
    streetName: "Мехр кўчаси 45-уй қаршиси ",
    lineCount: 4,
    location: "41.278923,69.207994",
  },
  {
    id: "447.        ",
    streetName: "Дўмбираобод кўчаси 58-уй қаршиси ",
    lineCount: 4,
    location: "41.266545,69.208901",
  },
  {
    id: "448.        ",
    streetName: "Дўмбираобод 4-тор кўчаси 10-уй қаршиси",
    lineCount: 4,
    location: "41.267087,69.20303",
  },
  {
    id: "449.        ",
    streetName: "Дўмбираобод 4-тор кўчаси 4-уй қаршиси",
    lineCount: 4,
    location: "41.262907,69.208735",
  },
  {
    id: "450.        ",
    streetName: "Олмазор метро бекати қаршисида",
    lineCount: 6,
    location: "41.255.841,69.195652",
  },
  {
    id: "451.        ",
    streetName: "Чугалли Ота кўчаси 100-уй қаршисида",
    lineCount: 6,
    location: "41.260398,69.195982",
  },
  {
    id: "452.        ",
    streetName: "Сугалли ота кўчаси Шайх Мухаммад масжиди қаршиси",
    lineCount: 6,
    location: "41.259689,69.186483",
  },
  {
    id: "453.        ",
    streetName: "Сугалли ота кўчаси 46-уй қаршиси ",
    lineCount: 6,
    location: "41.260082,69.1788",
  },
  {
    id: "454.        ",
    streetName: "Сугалли ота кўчаси 25-уй қаршиси ",
    lineCount: 6,
    location: "41.263552,69.161879",
  },
  {
    id: "455.        ",
    streetName: "Сугалли ота кўчаси Дилбар кафеси қаршиси",
    lineCount: 6,
    location: "41.262936,69.162755",
  },
  {
    id: "456.        ",
    streetName: "Заргарлик кўчаси 4-уй қаршиси",
    lineCount: 4,
    location: "41.258921,69.160743",
  },
  {
    id: "457.        ",
    streetName: "Заргарлик кўчаси 50/1 кўчаси ",
    lineCount: 4,
    location: "41.260394,69.1623",
  },
  {
    id: "458.        ",
    streetName: "Заргарлик кўчасми 16-уй қаршиси",
    lineCount: 4,
    location: "41.274820,69.191576",
  },
  {
    id: "459.        ",
    streetName: "Заргарлик кўчаси 61-уй қаршисида ",
    lineCount: 4,
    location: "41.275523,69.193991",
  },
  {
    id: "460.        ",
    streetName: "Заргарлик кўчаси Алгаритм сити биноси қаршиси",
    lineCount: 6,
    location: "41.261085,69.163094",
  },
  {
    id: "461.        ",
    streetName: "Гулистон кўчаси 12-уй қаршисида ",
    lineCount: 4,
    location: "41.263736,69.156575",
  },
  {
    id: "462.        ",
    streetName: "Катта-Қаъни кўчаси Радиотехника колежи қаршиси",
    lineCount: 2,
    location: "41.262329,69.184538",
  },
  {
    id: "463.        ",
    streetName: "Катта-Қаъни кўчаси 217-мактаб қаршиси",
    lineCount: 2,
    location: "41.267698,69.173459",
  },
  {
    id: "464.        ",
    streetName: "Катта-Қаъни кўчаси Грил дўкони қаршиси",
    lineCount: 2,
    location: "41.265304,69.17715",
  },
  {
    id: "465.        ",
    streetName: "Ал-Хоразмий кўчаси 2-уй қаршиси",
    lineCount: 4,
    location: "41.268727,69.190943",
  },
  {
    id: "466.        ",
    streetName: "Ал-Хоразмий кўчаси Хавас дўкони қаршисида ",
    lineCount: 4,
    location: "41.272853,69.193252",
  },
  {
    id: "467.        ",
    streetName: "Ал-Хоразмий кўчаси 13-уй қаршиси",
    lineCount: 4,
    location: "41.263412,69.188099",
  },
  {
    id: "468.        ",
    streetName: "Ал-Хоразмий кўчаси 42-уй қаршиси",
    lineCount: 4,
    location: "41.272822,69.193279",
  },
  {
    id: "469.        ",
    streetName: "Ал-Хоразмий кўчаси 48-уй қаршиси",
    lineCount: 4,
    location: "41.274981,69.194689",
  },
  {
    id: "470.        ",
    streetName: "2-Қатортол кўчаси 4-уй қаршиси",
    lineCount: 4,
    location: "41.269067,69.197931",
  },
  {
    id: "471.        ",
    streetName: "2-Қатортол кўчаси 45-уй қаршиси",
    lineCount: 4,
    location: "41.269863,69.194763",
  },
  {
    id: "472.        ",
    streetName: "2-Қатортол кўчаси 413-сонли боғча қаршиси",
    lineCount: 4,
    location: "41.272584,69.188818",
  },
  {
    id: "473.        ",
    streetName: "Катта-Хирмонтепа кўчаси 55-уй қаршиси",
    lineCount: 4,
    location: "41.265710,69.185912",
  },
  {
    id: "474.        ",
    streetName: "1-Қатортол кўчаси 15-уй қаршиси",
    lineCount: 2,
    location: "41.295151,69.206471",
  },
  {
    id: "475.        ",
    streetName: "1-Қатортол кўчаси 1А-уй қаршиси",
    lineCount: 2,
    location: "41.292406,69.207925",
  },
  {
    id: "476.        ",
    streetName: "Сеул кўчаси Чилонзор суди қаршиси",
    lineCount: 4,
    location: "41.300539,69.227817",
  },
  {
    id: "477.        ",
    streetName: "Сеул кўчаси 128-мактаб қаршиси",
    lineCount: 4,
    location: "41.300082,69.229025",
  },
  {
    id: "478.        ",
    streetName: "Сеул кўчаси анхор кўприги қаршиси",
    lineCount: 4,
    location: "41.298273,69.238599",
  },
  {
    id: "479.        ",
    streetName: "Сеул кўчаси 21-уй қаршисида ",
    lineCount: 4,
    location: "41.301900,69.223411",
  },
  {
    id: "480.        ",
    streetName: "Фурқат кўчаси 15А-уй қаршиси",
    lineCount: 4,
    location: "41.309317,69.24273",
  },
  {
    id: "481.        ",
    streetName: "Бешёғоч кўчаси Бокс федерация қаршиси",
    lineCount: 4,
    location: "41.305091,69.248897",
  },
  {
    id: "482.        ",
    streetName: "ТХАЙ  Юлдуз мехмонхона қаршиси",
    lineCount: 8,
    location: "41.232899,69.170788",
  },
  {
    id: "483.        ",
    streetName: "ТХАЙ Олим полвон кафеси қаршиси ",
    lineCount: 8,
    location: "41.229168,69.17544",
  },
  {
    id: "484.        ",
    streetName: "Фаробий кўчаси 37-уй қаршисида",
    lineCount: 4,
    location: "41.348995,69.177002",
  },
  {
    id: "485.        ",
    streetName: "Фаробий кўчаси 304-уй қаршисида",
    lineCount: 4,
    location: "41.341108,69.214293",
  },
  {
    id: "486.        ",
    streetName: "Фаробий кўчаси “Акфа Медлайн” қаршисида",
    lineCount: 4,
    location: "41.343177,69.209748",
  },
  {
    id: "487.        ",
    streetName: "Фаробий-В.Жумаев кўчалари кесишмаси",
    lineCount: 4,
    location: "41.349212,69.19238",
  },
  {
    id: "488.        ",
    streetName: "Фаробий-Чиғатой Оқтепа кўчалари кесишмаси",
    lineCount: 4,
    location: "41.350015,69.188903",
  },
  {
    id: "489.        ",
    streetName: "Фаробий-Жарариқ кўчалари кесишмаси",
    lineCount: 4,
    location: "41.348844,69.176272",
  },
  {
    id: "490.        ",
    streetName: "Сағбон кўчаси “Хофизкохаки” масжиди қаршисида",
    lineCount: 4,
    location: "41.332327,69.234424",
  },
  {
    id: "491.        ",
    streetName: "Сағбон кўчаси “Исломобод” масжиди қаршисида",
    lineCount: 4,
    location: "41.347285,69.22813",
  },
  {
    id: "492.        ",
    streetName: "Сағбон-Ғалаба кўчалари кесишмаси ",
    lineCount: 4,
    location: "41.353037,69.228016",
  },
  {
    id: "493.        ",
    streetName: "Сағбон кўчаси “Ғалаба” боғи қаршисида",
    lineCount: 4,
    location: "41.351784,69.227019",
  },
  {
    id: "494.        ",
    streetName: "Сағбон-Дилсарой кўчалари кесишмаси ",
    lineCount: 4,
    location: "41.356681,69.226976",
  },
  {
    id: "495.        ",
    streetName: "Сағбон кўчаси 42А-уй қаршисида",
    lineCount: 4,
    location: "41.358060,69.225748",
  },
  {
    id: "496.        ",
    streetName: "Сағбон кўчаси “Дўстжонбой” масжиди қаршисида",
    lineCount: 4,
    location: "41.362644,69.225108",
  },
  {
    id: "497.        ",
    streetName: "Келесйўли кўчаси “Мевазор” маркет қаршсида",
    lineCount: 4,
    location: "41.366251,69.225033",
  },
  {
    id: "498.        ",
    streetName: "Келесйўли-Мирзо кўчалари кесишмасида",
    lineCount: 4,
    location: "41.367704,69.225046",
  },
  {
    id: "499.        ",
    streetName: "Келесйўли-Ипакчи кўчалари кесишмасида",
    lineCount: 4,
    location: "41.372154,69.224882",
  },
  {
    id: "500.        ",
    streetName: "Келесйўли-Мирзо кўчалари кесишмасида",
    lineCount: 4,
    location: "41.367704,69.225046",
  },
  {
    id: "501.        ",
    streetName: "Келесйўли кўчаси “АЁҚШ” қаршисида",
    lineCount: 4,
    location: "41.377195,69.222387",
  },
  {
    id: "502.        ",
    streetName: "Келесйўли-Мойариқ кўчалари кесишмасида",
    lineCount: 4,
    location: "41.379916,69.219347",
  },
  {
    id: "503.        ",
    streetName: "Қорасарой-Чувалачи кўчалари кесишмасида",
    lineCount: 4,
    location: "41.333878,69.242568",
  },
  {
    id: "504.        ",
    streetName: "Қорасарой-2-тор Минор кўчалари кесишмасида",
    lineCount: 4,
    location: "41.347343,69.24146",
  },
  {
    id: "505.        ",
    streetName: "Қорасарой кўчаси Масжид қаршисида",
    lineCount: 4,
    location: "41.355242,69.242276",
  },
  {
    id: "506.        ",
    streetName: "Қорасарой-Гулсарой кўчалари кесишмасида",
    lineCount: 4,
    location: "41.361741,69.242024",
  },
  {
    id: "507.        ",
    streetName: "Қорасарой кўчаси “Миллий таъомлар” қаршисида",
    lineCount: 4,
    location: "41.362986,69.241452",
  },
  {
    id: "508.        ",
    streetName: "Қорасарой-Норастепа кўчалари кесишмасида",
    lineCount: 4,
    location: "41.367553,69.240263",
  },
  {
    id: "509.        ",
    streetName: "Чуқурсой-Иброхим ота кўчалари кесишмасида",
    lineCount: 2,
    location: "41.368506,69.240534",
  },
  {
    id: "510.        ",
    streetName: "Чуқурсой-Чуқурсой тор кўчалари кесишмасида",
    lineCount: 2,
    location: "41.369451,69.240427",
  },
  {
    id: "511.        ",
    streetName: "Чуқурсой кўчаси 131-уй қаршисида",
    lineCount: 2,
    location: "41.376304,69.240611",
  },
  {
    id: "512.        ",
    streetName: "Нурафшон кўчаси 142-уй қаршисида",
    lineCount: 6,
    location: "41.336042,69.226315",
  },
  {
    id: "513.        ",
    streetName: "Нурафшон-Тешикқопқоқ кўчалари кесишмасида",
    lineCount: 6,
    location: "41.341142,69.246335",
  },
  {
    id: "514.        ",
    streetName: "Нурафшон кўчаси 20А-уй қаршисида",
    lineCount: 6,
    location: "41.341237,69.245319",
  },
  {
    id: "515.        ",
    streetName: "Нурафшон кўчаси 22-уй қаршисида",
    lineCount: 6,
    location: "41.341742,69.24037",
  },
  {
    id: "516.        ",
    streetName: "Нурафшон кўчаси 28А-уй қаршисида",
    lineCount: 6,
    location: "41.339569,69.227146",
  },
  {
    id: "517.        ",
    streetName: "Нурафшон кўчаси 23-уй қаршисида",
    lineCount: 6,
    location: "41.339523,69.227133",
  },
  {
    id: "518.        ",
    streetName: "КХАЙ-Уста Олим кўчалари кесишмаси ",
    lineCount: 8,
    location: "41.347347,69.257995",
  },
  {
    id: "519.        ",
    streetName: "КХАЙ-Умид кўчалари кесишмаси",
    lineCount: 8,
    location: "41.349865,69.22328",
  },
  {
    id: "520.        ",
    streetName: "КХАЙ кўчаси “ТУРИН” университети қаршисида",
    lineCount: 8,
    location: "41.349997,69.222153",
  },
  {
    id: "521.        ",
    streetName: "КХАЙ-Аллон кўчалари кесишмаси “Цезар” дўкон қаршисида",
    lineCount: 8,
    location: "41.346283,69.214564",
  },
  {
    id: "522.        ",
    streetName: "КХАЙ кўчаси “Аср” тўйхонаси қаршисида",
    lineCount: 8,
    location: "41340537,69.208696",
  },
  {
    id: "523.        ",
    streetName: "Чимбой кўчаси 9А-уй қаршисида",
    lineCount: 4,
    location: "41.340912,69.219559",
  },
  {
    id: "524.        ",
    streetName: "Чимбой кўчаси 77-уй қаршисида",
    lineCount: 4,
    location: "41.340887,69.219589",
  },
  {
    id: "525.        ",
    streetName: "Чимбой кўчаси 100-мактаб интернат қаршисида",
    lineCount: 4,
    location: "41.344989,69.218324",
  },
  {
    id: "526.        ",
    streetName: "Мирзо Ғолиб кўчаси болалар боғчаси қаршисида",
    lineCount: 4,
    location: "41.353191,69.198074",
  },
  {
    id: "527.        ",
    streetName: "Мирзо Ғолиб кўчаси 5-уй қаршисида",
    lineCount: 4,
    location: "41.349304,69.198088",
  },
  {
    id: "528.        ",
    streetName: "Шифокорлар-Нозимахоним кўчалари кесишмасида",
    lineCount: 4,
    location: "41.361350,69.186394",
  },
  {
    id: "529.        ",
    streetName: "Қамарнисо кўчаси 2-ШИБ қаршисида",
    lineCount: 4,
    location: "41.364728,69.18248",
  },
  {
    id: "530.        ",
    streetName: "Қорақамиш-4 кўчаси 22-уй қаршисида ",
    lineCount: 4,
    location: "41.369295,69.204906",
  },
  {
    id: "531.        ",
    streetName: "Қорақамиш-3 кўчаси 27-уй қаршисида ",
    lineCount: 4,
    location: "41.370521,69.207439",
  },
  {
    id: "532.        ",
    streetName: "Қорақамиш-3 кўчаси 219-мактаб қаршисида ",
    lineCount: 4,
    location: "41.370482,69.218675",
  },
  {
    id: "533.        ",
    streetName: "Қорақамиш-1 кўчаси 1-уй қаршисида ",
    lineCount: 6,
    location: "41.366379,69.214758",
  },
  {
    id: "534.        ",
    streetName: "Қорақамиш-1 кўчаси “Қушбозор” қаршисида ",
    lineCount: 6,
    location: "41.364342,69.214716",
  },
  {
    id: "535.        ",
    streetName: "Қорақамиш-2 кўчаси 15-оилавий поликлиника қаршисида ",
    lineCount: 6,
    location: "41.364068,69.212496",
  },
  {
    id: "536.        ",
    streetName: "Қорақамиш кўчаси “Футбол” мактаби қаршисида ",
    lineCount: 4,
    location: "41.358533,69.21114",
  },
  {
    id: "537.        ",
    streetName: "Қорақамиш 2/2-дахаси 191-мактаб қаршисида ",
    lineCount: 2,
    location: "41.359054,69.221722",
  },
  {
    id: "538.        ",
    streetName: "Қорақамиш 2/2-дахаси 196-мактаб қаршисида ",
    lineCount: 2,
    location: "41.359210,69.219104",
  },
  {
    id: "539.        ",
    streetName: "Қорақамиш кўчаси “Ғалаба” боғи қаршисида ",
    lineCount: 4,
    location: "41.355373,69.223296",
  },
  {
    id: "540.        ",
    streetName: "Дилсарой кўчаси “Риверпарк” қаршсида",
    lineCount: 4,
    location: "41.353613,69.234769",
  },
  {
    id: "541.        ",
    streetName: "Дилсарой кўчаси 395-болалар боғчаси қаршсида",
    lineCount: 4,
    location: "41.352559,69.235768",
  },
  {
    id: "542.        ",
    streetName: "Дилсарой кўчаси Олмазор туман Прокуратураси қаршсида",
    lineCount: 4,
    location: "41.350860,69.238545",
  },
  {
    id: "543.        ",
    streetName: "Дилсарой кўчаси “Қичқириқ” канали кўприк ёнида",
    lineCount: 4,
    location: "41.353620,69.234768",
  },
  {
    id: "544.        ",
    streetName: "Олтинсой кўчаси 133-мактаб қаршисида",
    lineCount: 2,
    location: "41.353894,69.238816",
  },
  {
    id: "545.        ",
    streetName: "Олтинсой кўчаси “Мусиқий” мактаб қаршисида",
    lineCount: 2,
    location: "41.358767,69.239172",
  },
  {
    id: "546.        ",
    streetName: "Гулсарой кўчаси “Чуқурсой” бозори қаршисида",
    lineCount: 6,
    location: "41.361849,69.238378",
  },
  {
    id: "547.        ",
    streetName: "Гулсарой кўчаси 22-уй қаршисида",
    lineCount: 6,
    location: "41.361534,69.234772",
  },
  {
    id: "548.        ",
    streetName: "Гулсарой кўчаси 8-уй қаршисида",
    lineCount: 6,
    location: "41.361463,69.230736",
  },
  {
    id: "549.        ",
    streetName: "Шимолий Олмазор кўчаси 119-мактаб қаршисида",
    lineCount: 4,
    location: "41.340360,69.250904",
  },
  {
    id: "550.        ",
    streetName: "Шимолий Олмазор-Мевазор кўчалари кесишмаси",
    lineCount: 4,
    location: "41.343830,69.251584",
  },
  {
    id: "551.        ",
    streetName: "Жомий айланма ҳаракати",
    lineCount: 3,
    location: "41.35452,69.253196",
  },
  {
    id: "552.        ",
    streetName: "Уста Ширин кўчаси “Азия люкс” мебел дўкон қаршисида",
    lineCount: 4,
    location: "41.355501,69.251058",
  },
  {
    id: "553.        ",
    streetName: "ТХАЙ-Шифокорлар кўчалари кесишмаси",
    lineCount: 6,
    location: "41.359135,69.171835",
  },
  {
    id: "554.        ",
    streetName: "ТХАЙ кўчаси “Газ қуйиш” шахобчаси қаршсида",
    lineCount: 6,
    location: "41.361110,69.174742",
  },
  {
    id: "555.        ",
    streetName: "ТХАЙ кўчаси “Янги Арбат” кафеси қаршсида",
    lineCount: 6,
    location: "41.387217,69.215967",
  },
  {
    id: "556.        ",
    streetName: "А.Дониш кўчаси “Биг фарм” шифохонаси қаршисда",
    lineCount: 6,
    location: "41.342863,69.257411",
  },
  {
    id: "557.        ",
    streetName: "Уста Олим кўчаси “Тахтапул” масжиди қаршисида",
    lineCount: 6,
    location: "41.345478,69.257761",
  },
  {
    id: "558.        ",
    streetName: "ТХАЙ кўчаси “Замона” тўйхонаси қаршисида",
    lineCount: 4,
    location: "41.381136,69.206391",
  },
  {
    id: "559.        ",
    streetName: "Қорақамиш-3 кўчаси “Сирож Солиқ” масжиди қаршиси",
    lineCount: 2,
    location: "41.367542,69.201478",
  },
  {
    id: "560.        ",
    streetName: "Қорақамиш-3 кўчаси Шон Шухрат махалла қаршиси",
    location: "41.370840,69.202164",
  },
  {
    id: "561.        ",
    streetName: "Бешқўрғон кўчаси 134-мактаб қаршисида",
    lineCount: 2,
    location: "41.369264,69.198867",
  },
  {
    id: "562.        ",
    streetName: "М.Ғолиб кўчаси 23-уй қаршиси",
    lineCount: 4,
    location: "41.351180,69.198091",
  },
  {
    id: "563.        ",
    streetName: "Мухбир кўчаси 106-мактаб",
    lineCount: 4,
    location: "41.362146,69.197082",
  },
  {
    id: "564.        ",
    streetName: "ТХАЙ “Ширибом” заводи қаршисида ",
    lineCount: 6,
    location: "41.398296,69.24046",
  },
  {
    id: "565.        ",
    streetName: "ТХАЙ “Агрохауз” дўкони қаршисида",
    lineCount: 6,
    location: "41.397965,69.253278",
  },
  {
    id: "566.        ",
    streetName: "ТХАЙ-Беназир 2тор кўчалари кесишмасида",
    lineCount: 6,
    location: "41.39598,69.263714",
  },
  {
    id: "567.        ",
    streetName: "ТХАЙга “Авлиёота” МФЙдан чиқиш жойи",
    lineCount: 6,
    location: "41.38717,69.277626",
  },
  {
    id: "568.        ",
    streetName: "ТХАЙ, Юнусобод 10-мавзе қаршисида",
    lineCount: 6,
    location: "41.383464,69.288121",
  },
  {
    id: "569.        ",
    streetName: "ТХАЙ “Туркистон” ресторани қаршисида",
    lineCount: 6,
    location: "41.384255,69.304842",
  },
  {
    id: "570.        ",
    streetName: "ТХАЙга “Сабзовот” МФЙдан чиқиш жойи",
    lineCount: 6,
    location: "41.381978,69.31904",
  },
  {
    id: "571.        ",
    streetName: "ТХАЙга “Тутзор” МФЙдан чиқиш жойи",
    lineCount: 6,
    location: "41.382100,69.318896",
  },
  {
    id: "572.        ",
    streetName: "ТХАЙ, “Иссиқлик комбинати” бекати қаршисида",
    lineCount: 6,
    location: "41.377502,69.321013",
  },
  {
    id: "573.        ",
    streetName: "ТХАЙ, “ПЕТРОЛ” АЁҚШ қаршисида",
    lineCount: 6,
    location: "41.372184,69.323206",
  },
  {
    id: "574.        ",
    streetName: "ТХАЙ, Тошкент педиатрия тиббиёт институти клиникаси қаршисида",
    lineCount: 6,
    location: "41.363557,69.332743",
  },
  {
    id: "575.        ",
    streetName: "Боғишамол кўчаси, 1-МТТ қаршисида",
    lineCount: 6,
    location: "41.357009,69.336278",
  },
  {
    id: "576.        ",
    streetName: "Боғишамол кўчаси, “Ботаника боғи” қаршисида",
    lineCount: 6,
    location: "41.348066,69.316213",
  },
  {
    id: "577.        ",
    streetName: "Боғишамол кўчаси, “Хайвонот боғи” қаршисида",
    lineCount: 6,
    location: "41.344331,69.308536",
  },
  {
    id: "578.        ",
    streetName: "Боғишамол кўчаси, “Пул зарбхонаси” қаршисида ",
    lineCount: 6,
    location: "41.342244,69.301968",
  },
  {
    id: "579.        ",
    streetName: "Боғишамол кўчаси, “ТАТУ” академик лицей қаршисида",
    lineCount: 6,
    location: "41.354608,69.333971",
  },
  {
    id: "580.        ",
    streetName: "Боғишамол кўчаси, 2-уй (Ю.Обод нотариуси) қаршисида",
    lineCount: 6,
    location: "41.34034,69.294499",
  },
  {
    id: "581.        ",
    streetName: "Боғишамол кўчаси, “М.Юсуф масжиди” қаршисида",
    lineCount: 6,
    location: "41.340116,69.292179",
  },
  {
    id: "582.        ",
    streetName: "Боғишамол кўчасига “Шайх Шиблий” МФЙдан чиқиш ",
    lineCount: 6,
    location: "41.339610,69.28926",
  },
  {
    id: "583.        ",
    streetName: "А.Темур кўчаси “Телеминора” қаршиси ",
    lineCount: 8,
    location: "41.344408,69.286572",
  },
  {
    id: "584.        ",
    streetName: "А.Темур кўчаси “Самсунг”дўкони қаршиси ",
    lineCount: 8,
    location: "41.348400,69.287305",
  },
  {
    id: "585.        ",
    streetName: "А.Темур ва Шивли кўчаси кесишмасида",
    lineCount: 4,
    location: "41.333224,69.284971",
  },
  {
    id: "586.        ",
    streetName: "А.Темур-КХАЙ",
    lineCount: 10,
    location: "41.332891,69.28435",
  },
  {
    id: "587.        ",
    streetName: "А.Темур 70-уй қаршисида",
    lineCount: 10,
    location: "41.329421,69.283616",
  },
  {
    id: "588.        ",
    streetName: "А.Темур кўчаси “А.Темур” музейи қаршисида",
    lineCount: 8,
    location: "41.312946,69.280117",
  },
  {
    id: "589.        ",
    streetName: "А.Темур Хиёбони А.Темур музейи қаршисидаги",
    lineCount: 7,
    location: "41.312946,69.280117",
  },
  {
    id: "590.        ",
    streetName: "А.Темур Хиёбони “Уголок” кафеси қаршисида",
    lineCount: 4,
    location: "41.312870,69.279758",
  },
  {
    id: "591.        ",
    streetName: "А.Темур Хиёбони “Ўзбекистон” мехмонхона қаршисида",
    lineCount: 7,
    location: "41.311851,69.281651",
  },
  {
    id: "592.        ",
    streetName: "Шахрисабз кўчаси “Ипотека” банк қаршисида",
    lineCount: 10,
    location: "41.315759,69.284125",
  },
  {
    id: "593.        ",
    streetName: "Навоий кўчаси “Анхор” кўприги ёнида",
    lineCount: 8,
    location: "41.319942,69.267026",
  },
  {
    id: "594.        ",
    streetName: "Шахристон кўчаси, 63-мактабга кириш жойи",
    location: "41.351277,69.285432",
  },
  {
    id: "595.        ",
    streetName:
      "Буюк Тўрон кўчаси, Мустақиллик метро қаршисидаги 1-тартибга солинмаган пиёдалар ўтиш жойи",
    lineCount: 4,
    location: "41.314935,69.271615",
  },
  {
    id: "596.        ",
    streetName:
      "Буюк Тўрон кўчаси, Мустақиллик метро қаршисидаги 2-тартибга солинмаган пиёдалар ўтиш жойи",
    lineCount: 4,
    location: "41.314395,69.271417",
  },
  {
    id: "597.        ",
    streetName: "Буюк Тўрон кўчаси, Ўзбекистон Арт галерея биноси қаршисидаги ",
    lineCount: 4,
    location: "41.315720,69.272044",
  },
  {
    id: "598.        ",
    streetName: "Ш.Рашидов кўчаси, Минор қабристони қаршисида",
    lineCount: 6,
    location: "41.332623,69.2776",
  },
  {
    id: "599.        ",
    streetName: "А.Дониш ва Хиёбон кўчалари кесишмаси",
    lineCount: 6,
    location: "41.381525,69.297628",
  },
  {
    id: "600.        ",
    streetName: "А.Дониш кўчаси, 13-мавзе, 5-уй қаршисида",
    lineCount: 12,
    location: "41.373010,69.294769",
  },
  {
    id: "601.        ",
    streetName: "А.Дониш кўчаси, А.Дониш буюм бозори қаршисида",
    lineCount: 12,
    location: "41.370117,69.293963",
  },
  {
    id: "602.        ",
    streetName: "А.Дониш кўчаси, 2 ва 4 мавзелар оралиғида",
    lineCount: 10,
    location: "41.362385,69.283355",
  },
  {
    id: "603.        ",
    streetName: "А.Дониш кўчаси, 7-ШКШ қаршисида",
    lineCount: 10,
    location: "41.357503,69.27274",
  },
  {
    id: "604.        ",
    streetName: "А.Дониш ва Ғайратий кўчалари кесишмаси",
    lineCount: 10,
    location: "41.357503,69.27274",
  },
  {
    id: "605.        ",
    streetName: "Барака кўчаси, “Қаллиқ ота” қабристони қаршисида",
    lineCount: 4,
    location: "41.382583,69.266666",
  },
  {
    id: "606.        ",
    streetName: "Мойқўрғон кўчаси, 7-мавзе, 48-уй қаршисида",
    lineCount: 4,
    location: "41.370266,69.265871",
  },
  {
    id: "607.        ",
    streetName: "Мойқўрғон кўчаси, 86-мактаб қаршисида",
    lineCount: 4,
    location: "41.368777,69.268252",
  },
  {
    id: "608.        ",
    streetName: "Уста ширин ва Янги ариқ кўчалари кесишмасида",
    lineCount: 4,
    location: "41.345311,69.26955",
  },
  {
    id: "609.        ",
    streetName: "КХАЙ “Оқ масжид”қаршисида",
    lineCount: 6,
    location: "41.336138,69.277287",
  },
  {
    id: "610.        ",
    streetName: "КХАЙ, 39-уй қаршисида",
    lineCount: 6,
    location: "41.332441,69.295675",
  },
  {
    id: "611.        ",
    streetName: "Ч.Айтматов кўчаси 26-уй қаршисида",
    lineCount: 6,
    location: "41.328683,69.291542",
  },
  {
    id: "612.        ",
    streetName: "Ч.Айтматов ва Юлдузкўрарлар кўчалари кесишмасида",
    lineCount: 6,
    location: "41.330202,69.291798",
  },
  {
    id: "613.        ",
    streetName: "Ч.Айтматов ва Халқобод 4-тор кўчалари кесишмасида",
    lineCount: 6,
    location: "41.331191,69.291649",
  },
  {
    id: "614.        ",
    streetName: "Ч.Айтматов ва Қазитарнов кўчалари кесишмасида",
    lineCount: 6,
    location: "41.338768,69.29318",
  },
  {
    id: "615.        ",
    streetName: "Осиё ва Халқобод кўчалари кесишмасида",
    lineCount: 6,
    location: "41.327345,69.286431",
  },
  {
    id: "616.        ",
    streetName: "Чинобод кўчаси, “ЗАР” супермаркети қаршисида",
    lineCount: 4,
    location: "41.351053,69.290455",
  },
  {
    id: "617.        ",
    streetName: "Чинобод ва К.Зарипов кўчалари кесишмасида",
    lineCount: 4,
    location: "41.353624,69.298923",
  },
  {
    id: "618.        ",
    streetName: "Чинобод ва К.Зарипов 2-берк кўчалари кесишмасида",
    lineCount: 4,
    location: "41.353931,69.300313",
  },
  {
    id: "619.        ",
    streetName: "Чинобод кўчаси, “Чинобод” санаторияси қаршисида",
    lineCount: 4,
    location: "41.354940,69.304649",
  },
  {
    id: "620.        ",
    streetName: "Чинобод кўчаси, “Манзара” турар-жой мажмуаси қаршисида",
    lineCount: 2,
    location: "41.357233,69.314673",
  },
  {
    id: "621.        ",
    streetName: "Чинобод кўчаси “Оқтепа” масжиди қаршисида",
    lineCount: 2,
    location: "41.363960,69.318569",
  },
  {
    id: "622.        ",
    streetName: "Чинобод ва Оқтепа 3-тор кўчалари кесишмасида",
    lineCount: 2,
    location: "41.365495,69.320958",
  },
  {
    id: "623.        ",
    streetName: "Янги шаҳар кўчаси 118-уй қаршисида",
    lineCount: 8,
    location: "41.354264,69.312719",
  },
  {
    id: "624.        ",
    streetName: "Янги шаҳар кўчаси 8-автобус паркидан чиқиш жойи",
    lineCount: 8,
    location: "41.359797,69.303549",
  },
  {
    id: "625.        ",
    streetName:
      "Янги шаҳар кўчаси Мудофаа вазирлигининг “Қўриқлаш қўшинлари” биноси қаршисида",
    lineCount: 8,
    location: "41.362199,69.29963",
  },
  {
    id: "626.        ",
    streetName: "Шахристон кўчаси 366-МТТ қаршисида",
    lineCount: 4,
    location: "41.352828,69.276156",
  },
  {
    id: "627.        ",
    streetName: "Янги Юнусобод “Анжир” кафеси қаршисида",
    lineCount: 6,
    location: "41.366405,69.301607",
  },
  {
    id: "628.        ",
    streetName: "Янги Юнусобод “Самара” тўйхонаси қаршисида",
    lineCount: 6,
    location: "41.367974,69.304821",
  },
  {
    id: "629.        ",
    streetName: "Янги Юнусобод “М.Мед” клиникаси қаршисида",
    lineCount: 6,
    location: "41.368782,69.307797",
  },
  {
    id: "630.        ",
    streetName: "Янги Юнусобод “Крокус”савдо уйи қаршисида",
    lineCount: 6,
    location: "41.372271,69.318394",
  },
  {
    id: "631.        ",
    streetName: "Юнусота кўчаси “Атлас” маркет қаршисида (светофор)",
    lineCount: 4,
    location: "41.371443,69.310542",
  },
  {
    id: "632.        ",
    streetName: "И.Каримов кўчаси А.Навоий театри қаршисида",
    lineCount: 6,
    location: "41.308761,69.270298",
  },
  {
    id: "633.        ",
    streetName: "Бинкет кўчаси Оилавий поликлиника қаршисида",
    lineCount: 4,
    location: "41.370701,69.27288",
  },
  {
    id: "634.        ",
    streetName: "Учқахрамон кўчаси “Сетор” ресторани қаршисида",
    lineCount: 6,
    location: "41.375798,69.280553",
  },
  {
    id: "635.        ",
    streetName: "Учқахрамон кўчаси 8 ва 9-мавзелар оралиғи",
    lineCount: 6,
    location: "41.373089,69.276649",
  },
  {
    id: "636.        ",
    streetName: "Рихсилий кўчаси “Биллур” ресторани қаршисида ",
    lineCount: 6,
    location: "41.369297,69.280409",
  },
  {
    id: "637.        ",
    streetName: "Рихсилий кўчаси, 8-мавзе, 3-уй қаршисида",
    lineCount: 6,
    location: "41.367955,69.283243",
  },
  {
    id: "638.        ",
    streetName: "Рихсилий кўчаси “Харбий қисм” қаршисида",
    lineCount: 6,
    location: "41.370240,69.278362",
  },
  {
    id: "639.        ",
    streetName: "Рихсилий кўчаси “Корзинка” қаршисида",
    lineCount: 6,
    location: "41.373597,69.273495",
  },
  {
    id: "640.        ",
    streetName: "Дехқонобод кўчаси, 12-уй қаршисида",
    lineCount: 4,
    location: "41.377240,69.313001",
  },
  {
    id: "641.        ",
    streetName: "Дехқонобод кўчаси “Қариялар пансионати” қаршисида",
    lineCount: 4,
    location: "41.379666,69.316008",
  },
  {
    id: "642.        ",
    streetName: "Ибрагимов кўчаси Автобус шох бекати қаршисида",
    lineCount: 4,
    location: "41.378198,69.305679",
  },
  {
    id: "643.        ",
    streetName: "Зарафшон-Истиқлол кўчалари кесишмасида",
    lineCount: 4,
    location: "41.316126,69.275912",
  },
  {
    id: "644.        ",
    streetName: "Ифтихор кўчаси “Теннис корти” қаршисида",
    lineCount: 4,
    location: "41.347805,69.284294",
  },
  {
    id: "645.        ",
    streetName: "Юнусобод 11-мавзе, 240-мактаб қаршисида",
    lineCount: 2,
    location: "41.370172,69.289007",
  },
  {
    id: "646.        ",
    streetName: "Юнусобод Марказ-4 5-мактаб қаршисида",
    lineCount: 2,
    location: "41.319440,69.276784",
  },
  {
    id: "647.        ",
    streetName: "Юнусобод тумани 19-мавзе 257 ва 258 мактаблар қаршисида",
    lineCount: 2,
    location: "41.375162,69.315686",
  },
  {
    id: "648.        ",
    streetName: "Дехқанобод кўчаси Увайси махалла қаршиси",
    lineCount: 4,
    location: "41.378813,69.314656",
  },
  {
    id: "649.        ",
    streetName: "Дехқанобод –Бободехқон кесишмаси",
    lineCount: 4,
    location: "41.374659,69.31023",
  },
  {
    id: "650.        ",
    streetName: "Нурафшон-Мажлисий кўчалари кесишмасида",
    lineCount: 6,
    location: "41.307662,69.206924",
  },
  {
    id: "651.        ",
    streetName: "Нурафшон кўчаси “Шедевр гарден” ресторани қаршисида",
    lineCount: 6,
    location: "41.311556,69.207964",
  },
  {
    id: "652.        ",
    streetName: "Нурафшон кўчаси “Тиббиёт колледж” қаршисида",
    lineCount: 6,
    location: "41.307556,69.206991",
  },
  {
    id: "653.        ",
    streetName: "Нурафшон-Зулфияхоним кўчалари кесишмасида ",
    lineCount: 6,
    location: "41.331771,69.269533",
  },
  {
    id: "654.        ",
    streetName: "Беруний кўчаси “Версал” тўйхонаси қаршисида",
    lineCount: 6,
    location: "41.328742,69.225379",
  },
  {
    id: "655.        ",
    streetName: "Беруний кўчаси “Райхон” миллий таъомлар қаршисида",
    lineCount: 6,
    location: "41.334390,69.215396",
  },
  {
    id: "656.        ",
    streetName: "Беруний кўчаси 243-мактаб қаршисида",
    lineCount: 6,
    location: "41.343289,69.206144",
  },
  {
    id: "657.        ",
    streetName: "Беруний кўчаси 369-уй қаршисида ",
    lineCount: 6,
    location: "41.343289,69.206144",
  },
  {
    id: "658.        ",
    streetName:
      "Себзор кўчаси “Узкимёсаноъат” биноси қаршисида (бир томонлама ҳаракат)",
    lineCount: 3,
    location: "41.322621,69.243219",
  },
  {
    id: "659.        ",
    streetName: "Себзор кўчаси “Цирк” қаршисида (бир томонлама ҳаракат)",
    lineCount: 3,
    location: "41.324669,69.24388",
  },
  {
    id: "660.        ",
    streetName:
      "Себзор кўчаси “Хадра” миллий таъомлар қаршисида (бир томонлама ҳаракат)",
    lineCount: 3,
    location: "41.324471,69.24441",
  },
  {
    id: "661.        ",
    streetName: "А.Навоий кўчаси “Ўрда” Анхор бўйи кўприк ",
    lineCount: 6,
    location: "41.319966,69.267025",
  },
  {
    id: "662.        ",
    streetName: "С.Давроза кўчаси 1 Б-уй қаршисида ",
    lineCount: 6,
    location: "41.320096,69.233862",
  },
  {
    id: "663.        ",
    streetName: "С.Давроза кўчаси “Оқтепа лаваш”  қаршисида",
    lineCount: 6,
    location: "41.319638,69.233269",
  },
  {
    id: "664.        ",
    streetName: "С.Давроза кўчаси 135-уй қаршисида",
    lineCount: 6,
    location: "41.312415,69.224182",
  },
  {
    id: "665.        ",
    streetName: "С.Давроза кўчаси 2-уй қаршисида",
    lineCount: 6,
    location: "41.308367,69.224182",
  },
  {
    id: "666.        ",
    streetName:
      "С.Давроза кўчаси “Мехмонхона хўжалиги ва сервис” колледж қаршисида",
    lineCount: 6,
    location: "41.304913,69.215214",
  },
  {
    id: "667.        ",
    streetName: "С.Давроза кўчаси 27-уй қаршисида",
    lineCount: 6,
    location: "41.304169,69.214281",
  },
  {
    id: "668.        ",
    streetName: "С.Давроза кўчаси 5-уй қаршисида",
    lineCount: 6,
    location: "41.301912,69.21144",
  },
  {
    id: "669.        ",
    streetName: "А.Қодирий кўчаси “Лахисан” дорихона қаршисида",
    lineCount: 8,
    location: "41.326541,69.251087",
  },
  {
    id: "670.        ",
    streetName: "А.Қодирий кўчаси “Ислом” академик лицей қаршисида",
    lineCount: 6,
    location: "41.324202,69.25723",
  },
  {
    id: "671.        ",
    streetName: "А.Қодирий кўчаси Шайхонтоҳур тумани хокимияти қаршисида",
    lineCount: 6,
    location: "41.323999,69.259676",
  },
  {
    id: "672.        ",
    streetName: "А.Қодирий кўчаси “Осиё” мехмонхонаси қаршисида",
    lineCount: 6,
    location: "41.324319,69.264504",
  },
  {
    id: "673.        ",
    streetName: "Абай кўчаси 15 А-уй қаршисида",
    lineCount: 6,
    location: "41.327943,69.255439",
  },
  {
    id: "674.        ",
    streetName: "Зулфияхоним кўчаси “Медион” клиникаси қаршисида",
    lineCount: 6,
    location: "41.329740,69.258644",
  },
  {
    id: "675.        ",
    streetName: "Зулфияхоним кўчаси “Райхон” миллий таъомлар қаршисида",
    lineCount: 6,
    location: "41.329827,69.258612",
  },
  {
    id: "676.        ",
    streetName: "Зулфияхоним кўчаси 1-уй қаршисида",
    lineCount: 6,
    location: "41.330261,69.266116",
  },
  {
    id: "677.        ",
    streetName: "Зулфияхоним кўчаси “Узпромстройбанк” биноси қаршисида",
    lineCount: 6,
    location: "41.330826,69.268263",
  },
  {
    id: "678.        ",
    streetName: "Лабзак кўчаси “Узсувлойиха” биноси қаршисида ",
    lineCount: 6,
    location: "41.323354,69.265082",
  },
  {
    id: "679.        ",
    streetName: "Лабзак кўчаси “Осиё Гранд” тўйхонаси қаршисида",
    lineCount: 6,
    location: "41.324579,69.264821",
  },
  {
    id: "680.        ",
    streetName: "Лабзак кўчаси “Анхор” парк қаршисида",
    lineCount: 6,
    location: "41.327755,69.263938",
  },
  {
    id: "681.        ",
    streetName: "Лабзак кўчаси “Болаховуз” кафеси қаршисида",
    lineCount: 6,
    location: "41.336926,69.26665",
  },
  {
    id: "682.        ",
    streetName: "Лабзак-Фаол кўчалари кесишмасида",
    lineCount: 6,
    location: "41.332893,69.263628",
  },
  {
    id: "683.        ",
    streetName: "Қоратош кўчаси 298-уй қаршисида",
    lineCount: 8,
    location: "41.313690,69.236957",
  },
  {
    id: "684.        ",
    streetName: "Кўкча Дарвоза кўчаси “Кўкча” масжиди қаршисда",
    lineCount: 8,
    location: "41.322283,69.204654",
  },
  {
    id: "685.        ",
    streetName: "Кўкча Дарвоза кўчаси 429-уй қаршисида",
    lineCount: 8,
    location: "41.321512,69.200623",
  },
  {
    id: "686.        ",
    streetName: "Зарқайнар кўчаси 42-мактаб қаршисида",
    lineCount: 6,
    location: "41.325519,69.241429",
  },
  {
    id: "687.        ",
    streetName: "КХАЙ кўчаси “VISOL” тўйхонаси қаршисида",
    lineCount: 8,
    location: "41.335283,69.203031",
  },
  {
    id: "688.        ",
    streetName: "Чуқуркўприк кўчаси 43-оилавий поликлиника қаршисида",
    lineCount: 4,
    location: "41.303737,69.200751",
  },
  {
    id: "689.        ",
    streetName: "Чуқуркўприк-Мирхунд кўчалари кесишмаси",
    lineCount: 4,
    location: "41.308611,69.196702",
  },
  {
    id: "690.        ",
    streetName: "Сақичмон кўчаси “Чорсу” бозор қаршисида",
    lineCount: 6,
    location: "41.327222,69.233914",
  },
  {
    id: "691.        ",
    streetName: "Лайитўғон кўчаси “Райхон” миллий таъомлар қаршисида",
    lineCount: 4,
    location: "41.322144,69.228686",
  },
  {
    id: "692.        ",
    streetName: "Лайитўғон-Гулабод кўчалари кесишмаси",
    lineCount: 4,
    location: "41.323791,69.230309",
  },
  {
    id: "693.        ",
    streetName: "Ипакчи кўчаси 41-оилавий поликлиника қаршисида",
    lineCount: 2,
    location: "41.329216,69.190731",
  },
  {
    id: "694.        ",
    streetName: "Ипакчи-Қўрғонча кўчалари кесишмаси",
    lineCount: 4,
    location: "41.328690,69.196373",
  },
  {
    id: "695.        ",
    streetName: "Ипакчи-Ипакчи 3-тор кўчалари кесишмасида",
    lineCount: 2,
    location: "41.329419,69.189385",
  },
  {
    id: "696.        ",
    streetName: "Зангиота кўчаси 40-оилавий поликлиника қаршисида",
    lineCount: 4,
    location: "41.336011,69.168576",
  },
  {
    id: "697.        ",
    streetName: "Жарариқ-Белтепа кўчалари кесишмаси",
    lineCount: 6,
    location: "41.341074,69.176583",
  },
  {
    id: "698.        ",
    streetName: "Жарариқ кўчаси 31Б-уй қаршисида",
    lineCount: 6,
    location: "41.342511,69.177414",
  },
  {
    id: "699.        ",
    streetName: "Жарариқ кўчаси 12-уй қаршисида",
    lineCount: 6,
    location: "41.337381,69.175095",
  },
  {
    id: "700.        ",
    streetName: "ТХАЙ-Белтепа кўчалари кесишмаси",
    lineCount: 6,
    location: "41.339782,69.164525",
  },
  {
    id: "701.        ",
    streetName: "ТХАЙ кўчаси “Фурқат” дўкони қаршисида",
    lineCount: 6,
    location: "41.333672,69.163194",
  },
  {
    id: "702.        ",
    streetName: "Қўрғонча кўчаси 111-МТМ қаршисида",
    lineCount: 2,
    location: "41.326984,69.199631",
  },
  {
    id: "703.        ",
    streetName: "Қўрғонча кўчаси 192-мактаб қаршисида",
    lineCount: 2,
    location: "41.327045,69.199442",
  },
  {
    id: "704.        ",
    streetName: "Оқсой кўчаси 40-мактаб қаршисида",
    lineCount: 2,
    location: "41.323368,69.20619",
  },
];

export default crosswalkLocations;
