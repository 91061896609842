import React, { useEffect, useState } from "react";
import { t, getHandbookName } from "../../utils";
import { Input, Button, Row, Col, Form, message, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../utils/axios";
import { updateUser } from "../../store/auth/actions";
import Swal from "sweetalert2";
import colors from "../../styles/colors";
import { useHistory } from "react-router-dom";

export default function PersonalInfo({ handlePrivateInfo }) {
  const [loading, setLoading] = useState(false);
  const myAccount = useSelector((state) => state.auth.user || {});
  const [user, setUser] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setUser((user) => ({ ...user, username: myAccount.username }));
  }, [myAccount]);

  const handleSave = async (values) => {
    const { id } = myAccount;
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        try {
          let request = {};
          for (let x in values) {
            if (values[x] && values[x] !== myAccount[x]) {
              request = { ...request, [x]: values[x] };
            }
          }

          if (Object.keys(request).length) {
            const { data } = await axios.patch(`/auth/users/${id}/`, request);
            dispatch(updateUser(data));
            message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
            history.push("/cards");
          } else {
            message.warning(t("Yangilanadigan ma'lumot yo'q."));
          }
        } catch (err) {
          const { data = {} } = err.response;
          let messages = [];
          for (let x in data) {
            setErrors((state) => ({ ...state, [x]: data[x] }));
            messages.push(
              <li key={x}>
                {getHandbookName(x) ?? x}: {data[x].join(", ")}
              </li>
            );
          }
          return message.error(messages, 7);
        }
      }
    });
  };

  const errorsList = Object.values(errors);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(user);
  }, [user]);

  return (
    <div>
      {errorsList.length ? (
        <Alert
          showIcon
          type="error"
          className="my-3"
          message={
            <ul style={{ padding: "7px 20px 0px" }}>
              {errorsList.map((i) => (
                <li key={i}>{i}</li>
              ))}
            </ul>
          }
        />
      ) : null}

      <h2>{t("Xavfsizlik ma'lumotlar")}</h2>
      <Form
        form={form}
        name="control-ref"
        onFinish={handleSave}
        className="my-4"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        initialValues={user}
      >
        <Row gutter={[20, 0]}>
          <input type="email" hidden />
          <input type="email" hidden />
          <Col span={24}>
            <Form.Item
              name="username"
              label={t("Login")}
              validateStatus={errors.username ? "error" : ""}
              rules={[
                {
                  required: true,
                  message: t("Login majburiy"),
                },
                {
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: t("Faqat lotin harflarini kiriting!"),
                },
              ]}
            >
              <Input
                placeholder={t("Login")}
                autoComplete="off"
                name="username"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <input type="password" hidden />
          <Col span={24} md={12}>
            <Form.Item
              name="password"
              label={t("Parol")}
              validateStatus={errors.password ? "error" : ""}
              rules={[
                {
                  required: true,
                  message: t("Parol majburiy"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("Parol")}
                autoComplete="new-password"
                name="password"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="confirm"
              label={t("Parolni tasdiqlash")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("Iltimos Parolni tasdiqlang"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Siz kiritgan ikkita parol mos emas!"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password className="input-controller focusable mt-2" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ width: "100%" }}
          htmlType="submit"
          size="large"
          loading={loading}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </div>
  );
}
