import styled from 'styled-components';
import colors from './colors';

const S = {};

S.Container = styled.div`

`;

S.Header = styled.div`
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .profile__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid ${colors.border};
    padding: 3px;
    margin-right: 25px;
    object-fit: contain;
  }

  .profile__name {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

`;

S.Form = styled.div`
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 56px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item label {
    margin-bottom: -10px;
    padding-bottom: 0;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 54px;
  }
  .swal2-confirm {
    flex: 0;
  }
  .swal2-cancel {
    display: inline-block,
  }
  
`;

export default S;