import axios from "../utils/axios";
import store from "../store";
import { getDiff } from "../utils";
const cards = {};

cards.create = async (request) => {
  try {
    const { data } = await axios.post("/cards/", request);
    return data;
  } catch (err) {
    return { error: err.response };
  }
};

cards.fetch = async (id) => {
  try {
    const { data } = await axios.get(`/cards/${id}/`);
    return data;
  } catch (err) {
    return { error: err.response };
  }
};

cards.update = async ({ data, id }) => {
  // update if there is valid value to be send
  const { initialDate, isEdited, isInitialLoad, ...rest } = data;
  try {
    const { data: respondedData } = await axios.patch(`/cards/${id}/`, rest);
    return respondedData;
  } catch (err) {
    console.error("----", err.response);
    return { error: err?.response?.data };
  }
};

cards.updateForLog = async ({ data, id }) => {
  const persistedValue = store.getState().persistedCard;
  const { initialDate, isEdited, isInitialLoad, ...rest } = data;
  const {
    vehicles = [],
    participants = [],
    ...changedValues
  } = getDiff(rest, persistedValue);

  const isVehicleChanged = !vehicles?.every((item) => {
    const { id, type, participants, ...rest } = item;
    const persistedVehicle = persistedValue.vehicles.find(
      (i) => i.id === item.id
    );

    // Compare if new vehicle has less or more participants than persistedVehicles
    if (persistedVehicle?.participants.length !== item.participants?.length) {
      return false;
    } else if (!Object.keys(rest).length) {
      return item.participants.every((item) => item.isEdited === false);
    }
  });

  const isParticipantChanged = !participants?.every(
    (item) => item.isEdited === false
  );

  let request = changedValues;
  if (
    (vehicles.length === 0 && persistedValue.vehicles.length) ||
    persistedValue.vehicles.length !== vehicles.length ||
    isVehicleChanged
  ) {
    request.vehicles = vehicles;
  }
  if (
    (participants.length === 0 && persistedValue.participants.length) ||
    persistedValue.participants.length !== participants.length ||
    isParticipantChanged
  ) {
    request.participants = participants;
  }

  if (
    !("vehicles" in request) &&
    !("participants" in request) &&
    request.isEdited === false
  ) {
    return { isUpdated: false };
  } else if (
    "vehicles" in request ||
    "participants" in request ||
    request.isEdited !== false
  ) {
    try {
      const finalRequest = {
        vehicles: [],
        participants: [],
        ...getDiff(rest, persistedValue),
      };
      const { data: respondedData } = await axios.patch(
        `/cards/${id}/`,
        finalRequest
      );
      return respondedData;
    } catch (err) {
      console.error(err);
      return { error: err?.response?.data };
    }
  }
};

cards.addParticipants = async ({ data, id }) => {
  try {
    const { data: respondedData } = await axios.post(
      `/cards/${id}/participants/`,
      [data]
    );
    return respondedData[0];
  } catch (err) {
    return { error: err.response };
  }
};

cards.updateParticipants = async ({ data, id }) => {
  try {
    const { data: respondedData } = await axios.patch(
      `/participants/${id}/`,
      data
    );
    return respondedData;
  } catch (err) {
    return { error: err.response };
  }
};

cards.removeParticipants = async (id) => {
  try {
    const { data: respondedData } = await axios.delete(`/participants/${id}/`);
    return respondedData;
  } catch (err) {
    return { error: err.response };
  }
};

cards.deleteCard = async (id) => {
  try {
    await axios.patch(`/cards/${id}/`, { is_deleted: true });
    return true;
  } catch (err) {
    return { error: err.response };
  }
};

cards.uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const { data: respondedData } = await axios.post(`/files/`, formData);
    return respondedData;
  } catch (err) {
    return { error: err.response };
  }
};

cards.getHistory = async (id, page, pageSize) => {
  try {
    const { data: respondedData } = await axios.get(
      `/cards/history/?card__id=${id}&page=${page}&page_size=${pageSize}`
    );
    return respondedData;
  } catch (err) {
    return { error: err.response };
  }
};
export default cards;
