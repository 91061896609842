import * as types from '../actionTypes';

const initialState = {
  accident_causal: [],
  accident_day: null,
  accident_month: null,
  accident_number: null,
  accident_time: null,
  accident_type: null,
  accident_year: null,
  card_number: null,
  date_accident: null,
  description: null,
  distance_from: 0,
  district: null,
  id: null,
  illumination: null,
  isEdited: false,
  location: null,
  part_of_day: null,
  region: null,
  road_condition: null,
  road_part: null,
  road_surface: null,
  status: 'filling',
  street_name: null,
  street_significance: null,
  residence_type: null,
  weather_condition: null,
  vehicles: [],
  participants: [],
}

const cardEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CARD_EDIT_STEP_ONE: {
      return { ...state, ...action.data }
    }
    case types.CARD_EDIT_STEP_TWO_ADD_PARTICIPANT: {
      const data = action.participantType === 'driver' ?
        { vehicles: [...state.vehicles, action.data] }
        :
        { participants: [...state.participants, action.data] };
      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_STEP_TWO_EDIT_PARTICIPANT: {
      const data = action.participantType === 'driver' ?
        {
          vehicles: state.vehicles.map(item => {
            if (item.key === action.key) {
              return { ...item, ...action.data }
            }
            return item;
          })
        }
        :
        {
          participants: state.participants.map(item => {
            if (item.key === action.key) {
              return { ...item, ...action.data }
            }
            return item;
          })
        };

      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_STEP_TWO_ADD_PASSENGER: {
      const data = {
        vehicles: state.vehicles.map(item => {
          if (item.key === action.parentKey) {

            return { ...item, participants: [...item.participants, action.data] }
          }
          return item;
        })
      }

      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_STEP_TWO_EDIT_PASSENGER: {
      const data = {
        vehicles: state.vehicles.map(item => {
          if (item.key === action.parentKey) {
            return { ...item, participants: action.passengers }
          }
          return item;
        })
      }

      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_STEP_TWO_DELETE_PARTICIPANT: {
      const data = action.data.type === 'driver' ?
        { vehicles: state.vehicles.filter(item => item.key !== action.data.key) }
        :
        { participants: state.participants.filter(item => item.key !== action.data.key) }

      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_STEP_TWO_DELETE_PASSENGER: {
      const data = {
        vehicles: state.vehicles.map(item => {
          if (item.key === action.parentKey) {
            return { ...item, participants: action.passengers }
          }
          return item;
        })
      }

      return {
        ...state,
        ...data,
        isEdited: true,
      }
    }
    case types.CARD_EDIT_COMPLETED:
    case types.CARD_EDIT_DELETE:
    case types.AUTH_SIGN_OUT: {
      return initialState
    }
    default: {
      return state;
    }
  }
}


export default cardEditReducer;