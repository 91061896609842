import store from "../store";
import fieldNames from "../constants/helpTextDescriptions";
import { indexById } from ".";
import { message } from "antd";
import { errorMessages, requiredFields } from "../constants/cardOptions";

function getChangedAttachments({ input_data = [], old_data = [] }) {
  let result = { added: [], deleted: [] };
  // Get files and attachments
  if (input_data.length || old_data.length) {
    const oldDataIds = old_data.map((item) => item.id);
    const addedDataIds = input_data.map((item) => item.id);
    const addedItems = input_data.filter((item) => {
      return !oldDataIds.includes(item.id);
    });
    const deletedItems = old_data.filter((item) => {
      return !addedDataIds.includes(item.id);
    });
    result = {
      added: addedItems,
      deleted: deletedItems,
    };
  }
  return result;
}

function getParsedName(value) {
  const lang = store.getState().auth.user.lang || "uz";
  if (
    typeof value === "object" &&
    value !== null &&
    (value !== undefined) & !Array.isArray(value)
  ) {
    return value[`name_${lang}`];
  } else if (Array.isArray(value)) {
    return value.map((item) => item[`name_${lang}`] + ", ");
  } else {
    return value;
  }
}

function getTranslationOfFieldName(str) {
  const lang = store.getState().auth.user.lang || "uz";
  const foundField = Object.keys(fieldNames).find((item) => item.includes(str));
  const value = fieldNames[foundField] ? fieldNames[foundField][lang] : str;

  return str;
}

function formatChangedValues({ id, type, input_data, old_data }) {
  let card = { id, type, added: [], deleted: [], changed: [] };
  for (let x in input_data) {
    if (x !== "id" && x !== "type") {
      if (!(x in old_data)) {
        card.added.push({ name: x, old: old_data[x], current: input_data[x] });
      } else if (old_data[x] !== input_data[x]) {
        if (
          input_data[x] === null ||
          input_data[x] === "" ||
          input_data[x] === []
        ) {
          card.deleted.push({
            name: x,
            old: old_data[x],
            current: input_data[x],
          });
        } else {
          if (
            old_data[x] === null ||
            old_data[x] === "" ||
            old_data[x] === []
          ) {
            card.added.push({ name: x, current: input_data[x] });
          } else {
            card.changed.push({
              name: x,
              old: old_data[x],
              current: input_data[x],
            });
          }
        }
      }
    } else {
      card = { ...card, [x]: input_data[x], type: old_data.type };
    }
  }

  return card;
}

function getChangedParticipants({ input_data = [], old_data = [] }) {
  const participantsById = indexById(old_data);
  const oldDataIds = old_data.map((item) => item.id);
  const added = input_data.filter((item) => !item.id);

  const changed = input_data.filter((item) => {
    const { id: cardId, ...cardRest } = item;
    return (
      cardId &&
      Object.keys(cardRest || {}).length &&
      oldDataIds.includes(item.id)
    );
  });

  const deleted = old_data.filter((item) => {
    const foundParticipant = input_data.find((i) => i.id === item.id);
    if (Object.keys(foundParticipant || {}).length === 1) {
      return false;
    } else if (!foundParticipant) {
      return true;
    }
    return false;
  });

  return {
    added: added.map((item) => {
      let card = { id: item.id, type: item.type, data: [] };
      for (let x in item) {
        if (x !== "type" && x !== "id") {
          card.data.push({ name: x, old: old_data[x], current: item[x] });
        }
      }
      return card;
    }),
    deleted,
    changed: changed.map((item) =>
      formatChangedValues({
        input_data: item,
        old_data: participantsById[item.id],
      })
    ),
  };
}

function stepTwoFieldsChecker({ arr = [], fields, errorMsg, step = "f6" }) {
  return arr.every((item) => {
    const res = fields?.every((field) => Boolean(item?.[field]));
    if (!res) {
      message.error(errorMsg || errorMessages?.[step]?.[item?.type], 5);
    }
    return res;
  });
}

function falsyFieldsChecker({ arr = [], fields, errorMsg }) {
  const res = arr.every((item) => Boolean(fields[item]));
  if (!res) {
    message.error(errorMsg, 5);
  }
  return res;
}

function fieldscheckOnStepsChange({ fields, errorMsg, redirecHandler }) {
  const vehicles = fields.vehicles.map((item) => ({
    ...item,
    type: "vehicle",
  }));
  console.log(vehicles, fields);
  const pedestrians = fields.participants;
  var res = Object.keys(requiredFields).every((key) => {
    const field = requiredFields[key];
    if (field?.drivers) {
      var stepTwo = false;
      if (Boolean(pedestrians.length) && Boolean(vehicles.length)) {
        const pedestrianRes = stepTwoFieldsChecker({
          arr: pedestrians,
          fields: requiredFields.f6.pedestrians,
        });
        const vehicleRes = stepTwoFieldsChecker({
          arr: vehicles,
          fields: requiredFields.f6.vehicles,
        });
        const driverRes = vehicles.every((item) => {
          return stepTwoFieldsChecker({
            arr: item?.participants,
            fields: requiredFields.f6.drivers,
          });
        });
        if (vehicleRes && driverRes && pedestrianRes) {
          stepTwo = true;
        } else {
          redirecHandler(key);
        }
      } else if (Boolean(pedestrians.length)) {
        const pedestrianRes = stepTwoFieldsChecker({
          arr: pedestrians,
          fields: requiredFields.f6.pedestrians,
        });
        if (pedestrianRes) {
          stepTwo = true;
        } else {
          redirecHandler(key);
        }
      } else if (Boolean(vehicles.length)) {
        const vehicleRes = stepTwoFieldsChecker({
          arr: vehicles,
          fields: requiredFields.f6.vehicles,
        });
        const driverRes = vehicles.every((item) => {
          return stepTwoFieldsChecker({
            arr: item?.participants,
            fields: requiredFields.f6.drivers,
          });
        });
        if (vehicleRes && driverRes) {
          stepTwo = true;
        } else {
          redirecHandler(key);
        }
      }
      return stepTwo;
    } else if (field?.driver) {
      var stepThree = false;
      if (Boolean(pedestrians.length) && Boolean(vehicles.length)) {
        const pedestrianRes = stepTwoFieldsChecker({
          arr: pedestrians,
          fields: requiredFields.f7.pedestrian,
          step: "f7",
        });
        const driverRes = vehicles.every((item) => {
          return stepTwoFieldsChecker({
            arr: item?.participants,
            fields: requiredFields.f7.driver,
            step: "f7",
          });
        });
        if (driverRes && pedestrianRes) {
          stepThree = true;
        } else {
          redirecHandler(key);
        }
      } else if (Boolean(pedestrians.length)) {
        const pedestrianRes = stepTwoFieldsChecker({
          arr: pedestrians,
          fields: requiredFields.f7.pedestrian,
          step: "f7",
        });
        if (pedestrianRes) {
          stepThree = true;
        } else {
          redirecHandler(key);
        }
      } else if (Boolean(vehicles.length)) {
        const driverRes = vehicles.every((item) => {
          return stepTwoFieldsChecker({
            arr: item?.participants,
            fields: requiredFields.f7.driver,
            step: "f7",
          });
        });
        if (driverRes) {
          stepThree = true;
        } else {
          redirecHandler(key);
        }
      }
      return stepThree;
    } else {
      const stepOne = falsyFieldsChecker({
        arr: requiredFields.f5,
        fields,
        errorMsg: errorMsg || errorMessages[key],
      });
      if (stepOne) {
        return true;
      } else {
        redirecHandler(key);
        return false;
      }
    }
  });

  return res;
}

const getCategoryIds = (categories, driverCategories) => {
  const licence_categories = driverCategories.map((i) => {
    const category = i.pCategory?.toLowerCase();
    const currentCategory = categories.find(
      ({ name, name_oz, name_ru, name_uz }) =>
        name.toLowerCase() === category ||
        name_oz.toLowerCase() === category ||
        name_ru.toLowerCase() === category ||
        name_uz.toLowerCase() === category
    );
    if (currentCategory) {
      return currentCategory;
    }
    return categories[0]; //default first category in database
  });
  return licence_categories;
};

export {
  getChangedAttachments,
  getParsedName,
  getTranslationOfFieldName,
  getChangedParticipants,
  falsyFieldsChecker,
  fieldscheckOnStepsChange,
  stepTwoFieldsChecker,
  // getCategoryIds,
};

export default getCategoryIds;
