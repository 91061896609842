import styled from "styled-components";
import { Select } from "antd";
import colors from "./colors";

const S = {};

S.Select = styled(Select)`
  width: 100%;
  height: ${(props) => props.height + "px" || "60px"} !important;
  border-radius: 5px;
  outline: none;
  background-color: #fbfbfb;

  & > .ant-select-selector {
    padding: 10px !important;
    border: 1px solid ${(props) => colors[props.borderColor] || colors.success} !important;
  }

  & > .ant-select-selector,
  .ant-select-selection-search-input {
    height: ${(props) => props.height + "px" || "60px"} !important;
    background-color: #fbfbfb !important;
    overflow: hidden;

    &:active,
    &:focus {
      background-color: #fff !important;
      transition: background-color 0.4s;
    }
  }
  &.ant-select-focused {
    box-shadow: 0 0 0 2px rgba(66, 194, 147, 0.2);
    background-color: #fff !important;
    & > .ant-select-selector,
    & > .ant-select-selection-search-input {
      background-color: #fff !important;
      transition: background-color 0.4s;
    }
  }
  .ant-select-selection-placeholder {
    /* padding-left: 11px !important; */
    z-index: 2;
  }
  /* &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    left: 11px;
  } */

  &.ant-select-multiple .ant-select-selection-item {
    height: 42px;
    background-color: ${colors.success};
    border: 1px solid transparent;
    border-radius: 6px;
    color: #fff;
    line-height: 42px;
    margin-top: 4px;
    margin-bottom: 4px;

    .ant-select-selection-item-remove {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      background-color: rgba(255, 255, 255, 0.55);
      border-radius: 6px;

      .anticon-close {
        line-height: auto;
        stroke-width: 2px;
      }
    }
    .ant-select-item {
      min-height: 42px;
    }
  }
  &.custom-select-content {
    .checkbox-wrapper {
      display: none;
    }
  }
`;

export default S;
